import { BundleEntry, CarePlan, Goal, Reference } from "fhir/r4";
import ShortUniqueId from 'short-unique-id';
import { api } from "src/environments/environment";

const uid = new ShortUniqueId({ length: 12 });

export const MapUpdatedGoalsToBundleEntryGoals = (formValue: any, presetGoals: Goal[], carePlan: CarePlan): BundleEntry<Goal>[] => {
  // A patient enrolled to program will have atleast 1 goal with patient reference

  const patientId = carePlan.subject.reference?.split("/")[1];

  const carePlanUpdates: CarePlan = carePlan;
  let hasNewGoalsAdded = false;

  const updatedGoals = formValue.tableForm.map((metric: any, index: number) => {
    const mappedGoal: any = presetGoals.find((m: any) => m.id === metric.goalId);
    if (!mappedGoal || !mappedGoal?.id) {
      throw new Error('Goal not found');
    }
    mappedGoal.target[0].id = uid();
    mappedGoal.target[0]['detailRange'].low.value = metric?.low || '';
    mappedGoal.target[0]['detailRange'].high.value = metric?.high || '';
    const subjectType: string = String(mappedGoal.subject.type).toLowerCase();

    if (subjectType === "organization") {
      mappedGoal.lifecycleStatus = "active";
      mappedGoal.subject.reference = `Patient/${patientId}`;
      mappedGoal.subject.type = "Patient";

      carePlanUpdates.goal?.push({
        "type": "Goal",
        "reference": `urn:uuid:goal${index + 1}`
      })

      return {
        fullUrl: `urn:uuid:goal${index + 1}`,
        resource: mappedGoal,
        request: {
          method: "POST",
          url: `Goal`
        }
      };
    } else {
      // #R2-458 - bug fix - careplan goal should be add if new goal add to the metric list
      if (!carePlanUpdates.goal?.find(g => g.reference?.includes(metric.goalId))) {
        carePlanUpdates.goal?.push({
          "type": "Goal",
          "reference": `Goal/${metric.goalId}`
        });
      }
    }

    return {
      resource: mappedGoal,
      request: {
        method: "PUT",
        url: `Goal/${mappedGoal.id}`
      }
    };
  });

  //#R2-458 - bug fix - applying filter for removed goals and client org admin user can be remove all presetGoals in a program and add new different metrics
  carePlanUpdates.goal = carePlanUpdates.goal?.filter(goal => /^urn:/.test(goal.reference || "") ? true : presetGoals.find((m: any) => goal.reference?.includes(m.id)))

  return [
    {
      resource: {
        ...carePlanUpdates,
      },
      request: {
        method: "PUT",
        url: `CarePlan/${carePlan.id}`
      }
    },
    ...updatedGoals
  ]

  return updatedGoals
}