<div class="patients-example-container mat-elevation-z8" 
  [ngClass]="isAlertListingTable ? 'alert-listing-table' : null">
  
  <section class="custom-table-container">
    <mat-table #table [dataSource]="tableDataSource" matSort matSortDisableClear multiTemplateDataRows
      [ngClass]="{'custom-table-min-height':!showSpinner && tableDataSource.data.length}">
    
      <ng-container *ngFor="let item of displayedColumns; let i = index;">
    
        <ng-container matColumnDef="{{item.column}}">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ item.title }}
          </mat-header-cell>
    
          <mat-cell *matCellDef="let element" [matTooltip]="element.description">
    
            <ng-container *ngIf="item.column ==='patientName'; else otherCols">
              <ng-container *ngIf="isExpandableRow">
                <mat-icon class="program-expanded">keyboard_arrow_up</mat-icon>
                <mat-icon class="program-shrinked">keyboard_arrow_down</mat-icon>
              </ng-container>
              <a href="javascript:void(0)" (click)="redirectToProfile($event, element)" class="patients-patient-name">
                <span>
                  {{ element[item.column] | titlecase }}
                </span>
              </a>
            </ng-container>
    
            <ng-template #otherCols>
              <ng-container [ngSwitch]="item.column">
                <!-- slice diagnosis content -->
                <ng-container *ngSwitchCase="'diagnosis'">
                  <!-- <div>{{ element[item.column] | slice:0:40 }}</div> -->
                  <div>{{ element[item.column] }}</div>
                </ng-container>
                <!-- reported value in patient alert history table -->
                <ng-container *ngSwitchCase="'reportedValue'">
                  <div class="reported-value">
                    <div>{{ element[item.column] }} {{ element['alertUnit'] }}</div>
                    <img [src]="element['alertIcon']" alt="">
                  </div>
                </ng-container>
                <!-- alert status in patient alert history table -->
                <ng-container *ngSwitchCase="'alertStatus'" [ngTemplateOutlet]="alertStatusTemplate"
                  [ngTemplateOutletContext]="{ item: element }">
                </ng-container>
                <!-- action template -->
                <ng-container *ngSwitchCase="'action'" [ngTemplateOutlet]="actionTemplate"
                  [ngTemplateOutletContext]="{ item: element }"></ng-container>
                <!-- default column value -->
                <ng-container *ngSwitchDefault>
                  <div>{{ element[item.column] }}</div>
                </ng-container>
              </ng-container>
            </ng-template>
    
          </mat-cell>
        </ng-container>
    
      </ng-container>
    
      <ng-template #tpl let-element>
        <div class="mat-row patients-detail-row" [@detailExpand] style="overflow: hidden">
          <h3 class="patients-description-heading">
            Diagnosis Information
          </h3>
          <p class="patients-description-text">
            {{element.diagnosis}}
          </p>
        </div>
      </ng-template>
    
      <mat-header-row *matHeaderRowDef="columnIds"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnIds;" matRipple class="patients-element-row"
        [patientAppCdkDetailRow]="isExpandableRow ? row : null" [patientAppCdkDetailRowTpl]="tpl"></mat-row>
    </mat-table>

    <section *ngIf="!showSpinner && !tableDataSource.data.length" class="patients-no-mat-table-data">
      No data found!
    </section>
    
    <section *ngIf="showSpinner" class="patients-no-mat-table-data">
      <mat-spinner class="patients-rpm-spinner" [strokeWidth]="defaultSpinnerProperties.strokeWidth"
        [diameter]="defaultSpinnerProperties.diameter"></mat-spinner>
    </section>
  </section>

  

  <!-- Pagination -->
  <mat-paginator [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize" (page)="onChangeItemPerPage()"
    [ngClass]="{'retina':retina, 'retina-xdr':retinaXdr,'retina-4k':retina4k}"></mat-paginator>
</div>