import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { PatientService } from "../services/patient.service";
import { Observable, catchError, combineLatest, filter, of, switchMap, tap } from "rxjs";
import { ShowLoaderService } from "../services/show-loader.service";
import { NotificationService } from "../services/notification.service";

@Injectable({ providedIn: 'root' })
export class ActiveIHealthValidator implements AsyncValidator {
    constructor(
        private patientService: PatientService,
        private showLoaderService: ShowLoaderService,
        private notificationService: NotificationService
    ) { }

    validate(
        control: AbstractControl
    ): Observable<ValidationErrors | null> {
        /** Todo: Refactor the code to return a new Observable instead of http call 
         * and handle all the http calls, dialog subscriptions inside it.
         */
        return this.patientService.hasActiveIHealthPatient(control.value)
            .pipe(
                switchMap(hasActive => {
                    if (!hasActive) {
                        return of(null);
                    }
                    return of({
                        iHealthExist: hasActive,
                        iHealthEmail: control.value
                    })
                }),
                catchError((error: any) => {
                    this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                    /** When adding new user/ patient the email id should not 
                     * have patient details and is ok, so not handling it as an error
                    */
                    if (error.message === 'No patient details found.') {
                        return of(null);
                    }
                    console.error(error)
                    this.notificationService.showNotification('Checking email id failed.', 'error');
                    return of({ invalid: true })
                })
            )
    }
}