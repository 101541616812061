<section class="patients-search-wrapper">
  <div class="patients-flex">
    <!-- dropdown -->
    <section>
      <mat-form-field floatLabel="never">
        <span *ngIf="!selectedSearchCatergory?.value" class="patients-search-by-placeholder">Search By</span>
        <mat-select [(ngModel)]="selectedSearchCatergory" [disabled]="disabled">
          <mat-option *ngFor="let value of searchCategories" [value]="value">{{value.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <!-- enter value -->
    <section>
      <mat-form-field class="patients-enter-value" [ngClass]="{tablet:isTablet}" [floatLabel]="'never'">
        <input placeholder="Enter Value" #searchInput matInput autocomplete="off"
          [disabled]="disabled || !selectedSearchCatergory.value" (input)="handleSearch($event)">
        <button *ngIf="searchInput.value.length > minQueryLengthToFireSearch" title="Close Search" mat-button
          class="patients-search-close" (click)="onClearSearch()">
          <mat-icon title="Close Search">close</mat-icon>
        </button>
      </mat-form-field>
    </section>
  </div>
</section>