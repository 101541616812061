import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { CarePlan, PlanDefinition } from 'fhir/r4';
import { MapPatientResourceToViewModel, SetPatientTableData } from 'src/app/mappers/patient.mapper';
import { ActivePatientListing, ExtendedPatient } from 'src/app/models/patient.model';
import { SearchCategory } from 'src/app/models/shared.model';
import { CarePlanService } from 'src/app/services/careplan.service';
import { PatientService } from 'src/app/services/patient.service';
import { SearchPatients } from 'src/app/utils/shared.util';
import { EMPTY, Subject, takeUntil, tap } from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { activePatientTableColumns } from 'src/app/config/app.config';

@Component({
  selector: 'patient-app-active-patients',
  templateUrl: './active-patients.component.html',
  styleUrls: ['./active-patients.component.scss']
})
export class ActivePatientsComponent implements OnInit, OnDestroy {
  // mat table data source
  patients: ActivePatientListing[] = [];
  patientsCopy: ExtendedPatient[] = [];
  displayedColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'referredFrom', title: 'Referred From' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'diagnosis', title: 'Diagnosis' },
    { column: 'programName', title: 'Program' },
    { column: 'action', title: 'Action' },
  ];
  carePlans!: Array<CarePlan>;
  programs!: Array<PlanDefinition>;
  unsubscribe = new Subject<void>();
  showSpinner: boolean = true; // used to show loader in table
  systemColumns = activePatientTableColumns;
  systemColumnsForm!: FormGroup;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private patientService: PatientService,
    private carePlanService: CarePlanService,
    private breakpointService: BreakpointService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getPatients();

    this.patientService.refreshActivePatientListing$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.getPatients());

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
    
    /** Create Form group for showing check boxes in system column filter
     *  form group with controls with name same as column name from `systemFilters`
     */
    this.systemColumnsForm = this.formBuilder.group(
      activePatientTableColumns.reduce((obj: any, item) => {
        obj[item.column] = true;
        return obj;
      }, {})
    );

    /**
     * For column filter changes
     */
    this.systemColumnsForm.valueChanges
      .pipe(
        tap((values: any) => {
          const activeColumns = Object.keys(values)
            .filter((item: any) => values[item]);
          const updatedColumns = activePatientTableColumns
            .filter((item: any) => activeColumns.find((key: any) => key === item.column));
          this.displayedColumns = [...updatedColumns];
          return EMPTY;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  addPatientButtonClick(): void {
    this.patientService.initiateAddPatient$.next();
  }

  onEditCarePlan(event: Event, patient: ExtendedPatient): void {
    event.stopPropagation();
    this.carePlanService.editCarePlanForActivePatient$.next(patient);
  }

  /**
   * Search for query in retrieved extended patients & update patients property (mat table dataSource)
   */
  emitSearchQuery(searchParams: { category: SearchCategory, query: string }): void {
    this.patients = SearchPatients({
      ...searchParams,
      patientListing: this.patientsCopy
        .map(MapPatientResourceToViewModel) as ActivePatientListing[],
    });
  }

  /**
   * Update patients property (mat table dataSource) using retrieved extended patients
   */
  clearSearch(): void {
    this.patients = this.patientsCopy
      .map(MapPatientResourceToViewModel) as ActivePatientListing[];
  }

  private getPatients(): void {
    // reset active patients & show the loader
    this.patients = [];
    this.patientsCopy = [];
    this.showSpinner = true;

    this.patientService.getActivePatientListing()
      .subscribe((patients: ExtendedPatient[]) => {
        // to stop showing spinner if there are no patients
        if (patients !== null) {
          this.showSpinner = false;
        }

        this.patientsCopy = patients ?? [];
        SetPatientTableData(this, patients ?? []);
      });
  }
}
