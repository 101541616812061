import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map, tap } from 'rxjs';
import { BaseService } from './base-api.service';
import { Bundle, BundleEntry, CarePlan, Goal, Patient } from 'fhir/r4';
import { ExtendedPatient } from '../models/patient.model';
import { MapUpdatedGoalsToBundleEntryGoals } from '../mappers/goal.mapper';
import { MapBundleToResourceArray } from '../mappers/shared.mapper';
import { DefaultResourceQueryCount } from '../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class CarePlanService extends BaseService {
  /**
   * Patient listing actions in child routes subscribed in parent AppComponent
   * to use AppComponent's retrieved programs, goals as initialization data for modals
   */
  editCarePlanForActivePatient$ = new Subject<ExtendedPatient>();
  editCarePlanForPendingPatient$ = new Subject<ExtendedPatient>();
  editCarePlanForInactivePatient$ = new Subject<Patient>();

  profileSelectedCarePlan$ = new Subject<CarePlan>();

  /**
   * R2-1094: set start date for custom date picker in patient charts based on program enrollment date
   * for inactive patients, earliest start date from all the careplans is taken
   */
  patientCarePlans$ = new BehaviorSubject<CarePlan[]>([]);

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  /**
   * Editing carePlan/goals of active patients - Retrieves Bundle containing carePlans & goals
   * @param patientId
   * @returns Bundle containing carePlans & goals
   */
  getPatientCarePlanGoals(patientId: string, status = 'active'): Observable<Bundle<CarePlan | Goal>> {
    if (status == 'completed') {
      return this.http.get<Bundle<CarePlan | Goal>>(`${this.FHIR_BASE}/CarePlan?patient:Patient._id=${patientId}&status=${status}&_include=CarePlan:goal&_sort=-_lastUpdated&_count=1`);
    }
    return this.http.get<Bundle<CarePlan | Goal>>(`${this.FHIR_BASE}/CarePlan?patient:Patient._id=${patientId}&status=${status}&_include=CarePlan:goal`);
  }

  /**
   * Used to check the patient has active careplan or not
   */
  hasActiveCarePlan(patientId: string): Observable<boolean> {
    return this.http.get<Bundle<CarePlan>>(`${this.FHIR_BASE}/CarePlan?patient:Patient._id=${patientId}&status=active&_total=accurate`).pipe(map((response: any) => response.total > 0));
  }

  /**
   * Customizing goals of active patient
   */
  updateActivePatientCarePlan({ formValue, presetGoals, carePlan }: { formValue: any, presetGoals: Goal[], carePlan: CarePlan }): Observable<any> {
    const mappedGoals: BundleEntry<Goal>[] = MapUpdatedGoalsToBundleEntryGoals(formValue, presetGoals, carePlan);
    const mappedPayload = {
      resourceType: "Bundle",
      type: "transaction",
      entry: mappedGoals
    };
    return this.http.post(`${this.FHIR_BASE}`, mappedPayload);
  }

  patchCarePlan(carePlanId: string, patchPayload: Array<{ op: string; path: string; value: string }>): Observable<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json-patch+json');
    return this.http.patch<CarePlan>(`${this.FHIR_BASE}/CarePlan/${carePlanId}`, patchPayload, { headers });
  }

  getPatientCarePlan(patientId: string): Observable<Bundle<CarePlan>> {
    return this.http.get<Bundle<CarePlan>>(`${this.FHIR_BASE}/CarePlan?patient=${patientId}&_count=${DefaultResourceQueryCount}`)
      .pipe(
        tap((bundle: Bundle<CarePlan>) => { 
          this.patientCarePlans$.next(MapBundleToResourceArray<CarePlan>(bundle));
        })
      );
  }

  getPlanDefinitionById(planDefinitionId: string): Observable<any> {
    return this.http.get<any>(`${this.FHIR_BASE}/PlanDefinition/${planDefinitionId}`);
  }
}
