import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivePatientListing, ExtendedPatient, InActivePatientListing } from "../models/patient.model";
import { SearchCategory } from "../models/shared.model"
import { IIdTokenClaims, IIdTokenClaimsParsed } from "../shared/interfaces/id-token-claims";
import { BloodPressureTexts } from '../config/app.config';
import { Goal } from 'fhir/r4';

/**
 * For phase 1 patient search to be done on current pool of patients retrieved in front end
 * @returns 
 */
export const SearchPatients = <T extends ActivePatientListing | InActivePatientListing>(
  {
    category,
    query,
    patientListing,
  }: {
    category: SearchCategory,
    query: string
    patientListing: T[]
  }
): T[] => {
  return patientListing.filter((patient: T) => {
    switch (category) {
      case 'name':
        return patient?.patientName?.toLowerCase().includes(query.toLowerCase());
      case 'telecom':
        return patient?.email?.toLowerCase().includes(query.toLowerCase());
      default:
        return false;
    }
  });
}

export function parseIdTokenClaims(idTokenClaims: IIdTokenClaims): IIdTokenClaimsParsed {
  return {
    ...idTokenClaims,
    // #R2-452 - bug fix
    appClaims: JSON.parse(idTokenClaims.appClaims || '[]'),
    appRole: JSON.parse(idTokenClaims.appRole || '[]')
  }
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { 'invalidEmail': true };
    }
    return null;
  };
}


/**
 * Rearrange goals array with blood pressure systolic and blood pressure diastolic goals at the end of the array
 * @param goal goal array with blood pressure goals
 * @returns sorted array with blood pressure systolic and blood pressure diastolic at the end of the array
 */
export const rearrangeBloodPressureGoals = (goal: Goal) => {
  const goalTitle = goal?.description?.text?.toLowerCase();
  switch (goalTitle) {
    case BloodPressureTexts.systolic:
      return 1;
    case BloodPressureTexts.diastolic:
      return 2;
    default:
      return 0;
  }
};