import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, filter, fromEvent, map, Subject, Subscription, takeUntil, tap, merge } from 'rxjs';
import { MinQueryLengthToFireSearch, PrimaryThemeColor, SearchDebounceTime } from 'src/app/config/app.config';
import { SearchCategory } from 'src/app/models/shared.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';

@Component({
  selector: 'patient-app-search-patients',
  templateUrl: './search-patients.component.html',
  styleUrls: ['./search-patients.component.scss']
})
export class SearchPatientsComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  @Output() emitSearchQuery = new EventEmitter<{ category: SearchCategory, query: string }>();
  @Output() clearSearch = new EventEmitter<void>();
  @Input() disabled: boolean = false;

  subscription!: Subscription;
  // todo: setup program search once program is available in patients payload
  searchCategories = [
    { text: 'Patient Name', value: 'name' },
    { text: 'Email', value: 'telecom' },
    // { text: 'Program', value: '' },
  ];
  selectedSearchCatergory: { text: string, value: SearchCategory } = {
    text: '',
    value: ''
  };
  minQueryLengthToFireSearch = MinQueryLengthToFireSearch;
  primaryThemeColor = PrimaryThemeColor;

  unsubscribe$: Subject<void> = new Subject();

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private breakpointService: BreakpointService
  ) { }

  ngOnInit(): void {
    /*
     * search result is not working when pasting value into the search box,
     * added input event for pasting the value in the search box.
    */
    merge(...['keyup', 'input'].map(eventName => fromEvent(this.searchInput.nativeElement, eventName)))
      .pipe(
        map((event: any) => (event.target).value),
        filter((query: string) => this.isSearchQueryValid(this.selectedSearchCatergory.value, query)),
        debounceTime(SearchDebounceTime),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((query: string) => {
        this.emitSearchQuery.emit({
          category: this.selectedSearchCatergory.value,
          query,
        });
      });

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  private isSearchQueryValid(category: 'name' | 'telecom' | '', query: string): boolean {
    if (!category) {
      return false;
    }
    switch (category) {
      case 'name':
      case 'telecom':
        return query.length > this.minQueryLengthToFireSearch;
      default:
        throw new Error('Search category not valid');
    }
  }

  private isEmailValid(email: string): boolean {
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
    return emailRegex.test(email);
  }

  /**
   * Clear and reset search when clear search input with backspace!
   */
  public handleSearch(event: Event) {
    const value = (event.target as HTMLInputElement).value
    if (value === "") {
      this.onClearSearch();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClearSearch(): void {
    this.searchInput.nativeElement.value = '';
    this.clearSearch.emit();
  }
}
