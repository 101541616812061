import { api } from "src/environments/environment";

export class BaseService {
  protected FHIR_BASE = api.fhirBase;
  protected SIDECAR_BASE = api.sideCarAPI;
  protected VALIDIC_BASE = api.validicAPI;
  protected POWERBI_API = api.powerbiAPI;
  protected NEW_POWERBI_API = api.newPowerbiAPI;
  protected VIRTUAL_EVENTS = api.virtualEvents;
  
  constructor() { }
}