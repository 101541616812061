export class DeviceConnectResponse {
    constructor(
        public type: string,
        public connected: boolean,
        public logo_url: string,
        public display_name: string,
        public tagline: string,
        public connect_url?: string,
        public disconnect_url?: string
    ) { }

    getUrlBasedOnStatus() {
        return this.connected === true ? this.disconnect_url : this.connect_url
    }

    public static makeModel(obj: any): DeviceConnectResponse {
        if (obj === null || !Object.keys(obj).length) {
            throw new Error('DeviceConnectResponse: Empty Response')
        }
        return new DeviceConnectResponse(
            obj.type,
            obj.connected,
            obj.logo_url,
            obj.display_name,
            obj.tagline,
            obj.connected === true ? obj.disconnect_url : obj.connect_url
        )
    }
}