import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { IIdTokenClaims, IIdTokenClaimsParsed } from '../interfaces/id-token-claims';
import { parseIdTokenClaims } from 'src/app/utils/shared.util';
import { onOrganizationChange$ } from '@bayshoreHealthCare/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  activeAccount: AuthenticationResult | null = null;
  unsubscribe = new Subject<void>();
  token: string = '';
  idTokenClaims!: IIdTokenClaimsParsed;
  rpmWebHeaders = {
    appType: 'Provider',
    requestor: 'Vantage'
  };
  
  /** Active organization selected for sidebar / Saved active organization  */
  activeOrganization: Array<string> = [];

  constructor(
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (value: EventMessage) => {
        const authResult = value.payload as AuthenticationResult;
        this.token = authResult.idToken;
        this.idTokenClaims = parseIdTokenClaims(authResult.idTokenClaims as IIdTokenClaims);
      }
    });

    /**Listen for organization switch from sidebar */
    onOrganizationChange$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (organization: Array<string>) => {
          this.activeOrganization = organization;
        }
      });
  }

  generateHeaders = () => {
    /** set activeProfile Headers based on active organization
    * selected from sidebar / read from localstorage
    */
    if (this.activeOrganization.length) {
      return {
        ...this.rpmWebHeaders,
        Authorization: `Bearer ${this.token}`,
        activeProfile: `${this.activeOrganization[0]}`
      }
    }
    return {
      ...this.rpmWebHeaders,
      Authorization: `Bearer ${this.token}`,
      activeProfile: `${this.idTokenClaims.appRole[0][0]}`
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.token) {
      const authRequest = request.clone({
        setHeaders: this.generateHeaders()
      });
      return next.handle(authRequest);
    } else {
      return this.msalBroadcastService.msalSubject$.pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        switchMap((result: EventMessage) => {
          const authRequest = request.clone({
            setHeaders: this.generateHeaders()
          });
          return next.handle(authRequest);
        }),
        takeUntil(this.unsubscribe)
      )
    }
  }
}
