import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { DeviceConnectResponse } from '../shared/models/validic/device_connect/response/device-connect-response';
import { ValidicUserResponse, CreateValidicUser } from '../shared/models/validic/user/validic-user-model';
import { BaseService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ValidicService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  createUser(validicUser: any): Observable<ValidicUserResponse | null> {
    const payload = CreateValidicUser.makeModel(validicUser);
    return this.http.post(`${this.VALIDIC_BASE}`, payload).pipe(
      map((item: any) => {
        if (item) {
          return ValidicUserResponse.makeModel(item);
        }
        return null;
      }),
    );
  }

  getValidicUserDetails(patientFhirId: string): Observable<ValidicUserResponse | null> {
    return this.http.get(`${this.VALIDIC_BASE}/${patientFhirId}`).pipe(
      map((item: any) => {
        if (item) {
          return ValidicUserResponse.makeModel(item);
        }
        return null;
      }),
      catchError(err => throwError(() => { new Error(err) }))
    );
  }

  getValidicDeviceConnectInfo(validicUser: ValidicUserResponse): Observable<any> {
    const headers = {
      'Accept': "application/json"
    }
    return this.http.get(`${validicUser.marketplace.url}`, {
      headers: headers
    }).pipe(
      map((response: any) => response.map((item: any) => DeviceConnectResponse.makeModel(item)))
    )
  }

  deleteValidicUser(patientFhirId: string): Observable<any> {
    const headers = {
      'Content-Type': "application/json"
    }
    return this.http.delete(`${this.VALIDIC_BASE}/${patientFhirId}`, {
      headers: headers
    })
  }
}
