import { DeviceConnectResponse } from '../../../shared/models/validic/device_connect/response/device-connect-response';
import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, switchMap, of, catchError, combineLatest, tap, EMPTY } from 'rxjs';
import { PatientService } from 'src/app/services/patient.service';
import { ValidicService } from 'src/app/services/validic.service';
import { FhirPatientResponse } from 'src/app/shared/models/fhir/patient/response/fhir-patient-response';
import { MatDialog } from '@angular/material/dialog';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { DefaultDialogProperties, DialogResponsiveWidth } from 'src/app/config/app.config';
import { AddPatientModalFormValue } from 'src/app/models/patient.model';
import { NotificationService } from 'src/app/services/notification.service';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { Diagnosis } from 'src/app/models/diagnosis.model';
import { ConfirmDialogData, EditProfileDialogData } from 'src/app/models/matDialogData.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { mapFhirConditionsToDefaultDiagnosis } from 'src/app/mappers/diagnosis.mapper';
import { spinnerProperties } from 'src/app/config/app.config';
import { CarePlanService } from 'src/app/services/careplan.service';
import { Bundle, CarePlan } from 'fhir/r4';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { ShowLoaderService } from 'src/app/services/show-loader.service';
import { ValidicUserResponse } from 'src/app/shared/models/validic/user/validic-user-model';
import { MapBundleToResourceArray } from 'src/app/mappers/shared.mapper';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'patient-app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  patientId!: string;
  patientDetails!: FhirPatientResponse;

  // validic user details, contains validic url specific to that user !
  // validic url from this user is used to show devices list in manage devices section !
  validicUser!: ValidicUserResponse | null;

  connectedDevices: Array<DeviceConnectResponse> = []; // list of connected devices
  diagnosis: Diagnosis[] = [];
  conditionId: string | null = null;
  spinner: boolean = true;
  isSavingEditProfile: boolean = false;
  isActiveCarePlan: boolean = false;

  // set default values like diameter and stroke width of spinner
  spinnerProps = spinnerProperties;
  /**
   * To track whether the user is redirected to a new tab
   * true, if user is redirected to another tab.
   */
  redirected: boolean = false;
  activeOrCompletedCarePlan: CarePlan | null = null;

  // todo: Refactor symbol 'isTablet' to 'isLaptop' 
  isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private validicService: ValidicService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private diagnosisService: DiagnosisService,
    private carePlanService: CarePlanService,
    private breakpointService: BreakpointService,
    private loaderService: ShowLoaderService,
    private taskService: TaskService
  ) { }

  ngOnInit(): void {
    // get patient id from route and fetch patiend details 
    this.route.params.pipe(
      switchMap((params: any) => {
        this.patientId = params.id;
        if (!this.patientId) {
          throw new Error('Invalid Patient Id');
        }
        return combineLatest([
          this.patientService.getPatientById(this.patientId),
          this.patientService.getPatientConditions(this.patientId),
          this.validicService.getValidicUserDetails(this.patientId),
          this.diagnosisService.getDefaultDiagnosis(),
          this.carePlanService.getPatientCarePlan(this.patientId),
          this.diagnosisService.getPatientDiganosis(this.patientId)
        ]);
      }),
      switchMap(([patientDetails, patientConditions, validicUserDetails, defaultDiagnosis, patientCarePlan, patientDiagnosis]) => {
        // #R2-559 - sometimes this.activeCarePlan is not synced, so here using patientCarePlan to get careplan's statue anyway
        const carePlans = MapBundleToResourceArray<CarePlan>(patientCarePlan);

        // #R2-849 - checking active and completed careplans for binding on frontend
        // active careplan rearranged if the completed careplan appears at beginning of array
        const activeOrCompletedCarePlan = carePlans.sort((a, b) => a.status === "active" ? -1 : 1).find((carePlan: CarePlan) => ['active', 'completed'].includes(carePlan.status));
        if (!activeOrCompletedCarePlan) {
          throw new Error('No active careplan for selected patient');
        }
        if (!this.activeOrCompletedCarePlan) this.activeOrCompletedCarePlan = activeOrCompletedCarePlan;

        this.isActiveCarePlan = carePlans.findIndex(({status}) => status === 'active') > -1;
        this.diagnosis = defaultDiagnosis ?? [];
        this.diagnosis = [...this.diagnosis, ...patientDiagnosis]
        const diagnosis = mapFhirConditionsToDefaultDiagnosis(patientConditions, this.diagnosis);
        this.patientDetails = { ...patientDetails, diagnosis };
        this.conditionId = patientConditions.entry?.[0].resource.id;
        this.validicUser = validicUserDetails;
        if (!this.validicUser) {
          return of(null)
        }
        return this.validicService.getValidicDeviceConnectInfo(this.validicUser);
      }),
      tap(
        (value) => { this.filterConnectedDevicesFromValidic(value) }
      ),
      catchError((error: any) => {
        console.log("Profile Error: ", error)
        return of(null);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => { this.spinner = false });

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.isTablet = value.Tablet;
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });

    /**
     * On successfull data import, update the devices list
     */
    this.patientService.importDataSuccess$
      .pipe(
        switchMap((success: boolean) => {
          if (success && this.patientId && this.validicUser) {
            return this.validicService.getValidicDeviceConnectInfo(this.validicUser);
          } else {
            return of(null)
          }
        }),
        tap((value) => { this.filterConnectedDevicesFromValidic(value) }),
        catchError((error) => {
          console.error("Refresh Device list failed ", error);
          // After discharge of patient the token will fail 
          // with status 404 because the token for the user does not exists
          // so need to clear the device list and show as empty.
          if (error?.status === 404 || error?.error?.code === 404) {
            this.connectedDevices = []
          }
          return EMPTY
        })
      )
      .subscribe();

    // in order to discharge the same careplan we see in the profile header 
    this.carePlanService.profileSelectedCarePlan$
      .pipe(
        tap((careplan: CarePlan) => {
          this.activeOrCompletedCarePlan = careplan;
          // #R2-559 - bug fix
          this.isActiveCarePlan = this.activeOrCompletedCarePlan?.status === 'active';

        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
  }

  filterConnectedDevicesFromValidic(response: Array<DeviceConnectResponse> | null) {
    if (response && response?.length) {
      this.connectedDevices = response.filter(item => item.connected === true);
    }
  }

  promptDisconnect(device: DeviceConnectResponse) {
    this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>
      (ConfirmDialogComponent, {
        data: {
          confirmationHeader: `Disconnect ${device.display_name} Account`,
          confirmationQuestion: `Do you want to disconnect ${device.display_name} device?`
        },
        ...DefaultDialogProperties,
      }).afterClosed().pipe(
        takeUntil(this.unsubscribe$),
        catchError((error: any) => {
          console.error("Error in disconnect dialog: ", error)
          return of(null);
        })
      ).subscribe((result: any) => {
        if (result) {
          // visiting the link will disconnect the device.
          // for disconnecting, as per the payload from validic device list, 
          // the property `connect_url` should be used, not `disconnect_url` 
          window.open(device.connect_url);
          this.redirected = true;
        }
      })
  }

  /**
 * This listener is used to handle and fetch the updated data
 * from validic after connecting/ disconnecting devices. 
 * Note : This function should be passed as a parameter 
 * to removeEventListener to properly remove the listener !
 * @param event any
 */
  @HostListener('window:focus', ['$event'])
  refreshOnCloseNewTab(event: FocusEvent) {
    if (this.redirected && this.validicUser) {
      this.validicService
        .getValidicDeviceConnectInfo(this.validicUser)
        .pipe(
          tap((value) => { this.filterConnectedDevicesFromValidic(value) }),
          catchError((error) => {
            console.error('refreshing device list on disconnect error: ', error);
            return EMPTY;
          })
        )
        .subscribe();
      this.redirected = false;
    }
  }

  showEditPatientModel() {
    let dialogWidth = DialogResponsiveWidth.large;

    if (this.isTablet) dialogWidth = DialogResponsiveWidth.tablet;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;

    this.dialog.open<EditProfileComponent, EditProfileDialogData>
      (EditProfileComponent, {
        data: {
          patient: this.patientDetails,
          diagnosis: this.diagnosis
        },
        ...DefaultDialogProperties,
        width: dialogWidth,
      }).afterClosed()
      .pipe(
        switchMap((editedPatient: AddPatientModalFormValue) => {

          if (!editedPatient) {
            return of(false);
          }
          // check which values were changed and sent to service to generate patch payload !
          // updatedPatient will have the changed properties/values only, which can be
          // used for comparison to generate json patch in patient service.
          const updatedPatient = editedPatient;
          this.isSavingEditProfile = true

          // call updatePatient in patient service to provide the patch 
          return this.patientService.updatePatient(
            this.patientDetails.id,
            this.patientDetails as FhirPatientResponse,
            updatedPatient,
            this.conditionId ?? ""
          )
        }),
        switchMap((response) => {
          if (!response) {
            return of(false);
          }
          this.patientService.updatePatientInformation.next();
          return combineLatest([
            this.patientService.getPatientById(this.patientId),
            this.patientService.getPatientConditions(this.patientId),
            this.diagnosisService.getPatientDiganosis(this.patientId)
          ]);
        }),
        switchMap((response: any) => {
          if (!response) {
            return of(false);
          }
          const [patientDetails, patientConditions, patientDiagnosis] = response;
          this.diagnosis = [...this.diagnosis, ...patientDiagnosis];
          const diagnosis = mapFhirConditionsToDefaultDiagnosis(patientConditions, this.diagnosis);
          this.patientDetails = { ...patientDetails, diagnosis };
          return of(true);
        }),
        catchError((err) => {
          this.isSavingEditProfile = false;
          this.notificationService.showNotification('Error occured when saving profile.', 'error', 'Ok');
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (response: any) => {
          this.isSavingEditProfile = false;
          if (response) {
            this.notificationService.showNotification('Profile saved successfully.', 'success', 'Ok');
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dischargePatient(): void {
    // #R2-582 - Shouldn't discharge if the patient has not acknowledged alerts
    this.loaderService.isShowLoadingSectionVisible$.next(true);
    this.taskService.hasPatientNotAcknowledgedTask(this.patientId, this.activeOrCompletedCarePlan || undefined).subscribe(hasNotAcknowledgedTask => {
      this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        ...DefaultDialogProperties,
        data: {
          confirmationHeader: 'Discharge Patient',
          confirmationQuestion: hasNotAcknowledgedTask ? 'Acknowledge all alert to discharge this patient' : 'Are you sure you want to discharge this patient?',
          patientId: this.patientId,
          okButton: hasNotAcknowledgedTask
        }
      })
        .afterClosed()
        .pipe(
          switchMap((patientId: string) => {
            this.loaderService.isShowLoadingSectionVisible$.next(true);
            return patientId ? this.carePlanService.getPatientCarePlan(patientId) : EMPTY;
          }),
          switchMap((bundle: Bundle<CarePlan>) => {
            const carePlanId = this.activeOrCompletedCarePlan?.id;
            if (!carePlanId) {
              throw new Error('Care plan id not available');
            }
            const payload = [{
              op: 'replace',
              path: '/status',
              value: 'completed'
            }];
            this.isActiveCarePlan = false;
            return this.carePlanService.patchCarePlan(carePlanId, payload);
          }),
          switchMap((carePlanResponse: any) => {
            // todo: add careplan response error handling
            // only return validic service if careplan and fhir operations are successfull
            return this.validicService.deleteValidicUser(this.patientId)
          }),
          switchMap(() => {
            this.notificationService.showNotification('Patient discharged successfully', 'success', 'ok');
            // #R2-1147 - After discharge patient, user has to stay on same screen without any action buttons
            this.isActiveCarePlan = false;
            this.carePlanService.getPatientCarePlan(this.patientId);
            this.router.navigate([`../${this.patientId}/profile/inactive`], { replaceUrl: false });
            return EMPTY;
          }),
          catchError(() => {
            this.isActiveCarePlan = true;
            this.loaderService.isShowLoadingSectionVisible$.next(false);
            this.notificationService.showNotification('Patient discharge failed', 'error', 'ok');
            return EMPTY;
          })
        )
        .subscribe({
          complete: () => {
            this.patientService.importDataSuccess$.next(true);
            this.loaderService.isShowLoadingSectionVisible$.next(false)
          },
          next: () => {
            this.loaderService.isShowLoadingSectionVisible$.next(false)
          },
          error: () => {
            this.loaderService.isShowLoadingSectionVisible$.next(false)
          }
        });
    })
  }

  showImportDataModal() {
    this.patientService.importDataInitiated$.next({
      fhirPatientResponse: this.patientDetails
    })
  }
}
