import { Bundle, CarePlan, Goal, PlanDefinition } from "fhir/r4";

/**
 * Aggregate carePlan x1 with goals to return a single program
 * @param carePlan Maximum of 1 carePlan per patient for phase 1
 * @param goals Goals fetched for the patient along with patient carePlans
 * @returns PlanDefinition[] since MonitoringProgramDialogComponent accepts programs 
 * as an array for initial modal data; should return a single PlanDefinition in array for phase 1
 */
export const MapCarePlanToPlanDefinition = (carePlan: CarePlan, goal: Goal[]): PlanDefinition[] => {
  if (!carePlan || !carePlan?.id) {
    throw new Error('No care plans available for this patient');
  }
  return [
    {
      resourceType: 'PlanDefinition',
      id: carePlan.id,
      status: carePlan?.status,
      title: carePlan?.title,
      description: carePlan?.description,
      goal,
    } as PlanDefinition
  ];
};
