import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { AddCommentModalTimeFormat } from 'src/app/config/app.config';
import { AddCommentDialogData } from 'src/app/models/matDialogData.model';
import { Note } from 'src/app/models/task.model';
import { AcknowledgeDialogComponent } from '../acknowledge-dialog/acknowledge-dialog.component';

@Component({
  selector: 'patient-app-add-comments-dialog',
  templateUrl: './add-comments-dialog.component.html',
  styleUrls: ['./add-comments-dialog.component.scss']
})
export class AddCommentsDialogComponent implements OnInit {
  formGroup!: FormGroup;
  oldComments!: any[];
  addCommentModalTimeFormat = AddCommentModalTimeFormat;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: AddCommentDialogData,
    private dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
    private formBuilder: FormBuilder,
  ) {
    this.formGroup = this.formBuilder.group({
      'commentControl': ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.oldComments = this.dialogData.oldNote ?? [];
  }

  closeDialog(): void {
    this.dialogRef.close({
      newComment: this.formGroup.value.commentControl,
    });
  }
}
