import moment from 'moment';
import 'moment-timezone';

import { Pipe, PipeTransform } from '@angular/core';
import { EstTimezone, PatientSummaryDateFormat } from 'src/app/config/app.config';

/**
 * Converts UTC time to EST timezone time with proper format
 * Time formats listed in app.config file needs to be passed as an argument from template
 * Ex: {{ UTCTime | localTime:patientSummaryDateFormat }}
 */
@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(time: string, format: string): unknown {
    return moment(time).tz(EstTimezone).format(format);
  }

}
