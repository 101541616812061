import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, map, of, retry, switchMap, tap } from 'rxjs';
import { BaseService } from './base-api.service';
import { Diagnosis } from '../models/diagnosis.model';
import { Bundle, Condition } from 'fhir/r4';
import { ExtractDiagnosisCodesFromConditonAsString } from '../mappers/diagnosis.mapper';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService extends BaseService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  // currently retrieving any 5 diagnosis
  getDefaultDiagnosis(): Observable<Diagnosis[]> {
    return this.http.get<Diagnosis[]>(`${this.SIDECAR_BASE}/icd-code-10`).pipe(
      retry(3)
    );
  }

  // Jira #R2-390
  getNonDefaultDiagnosisByCodes(codes: string[]): Observable<Diagnosis[]> {
    return this.http.get<Diagnosis[]>(`${this.SIDECAR_BASE}/icd-code-10?codes=${codes.join(",")}`)
      .pipe(
        retry(3)
      );
  }

  searchDiagnosis(query: string): Observable<Diagnosis[]> {
    // #R2-520 - need to handle white space character in the query params
    const queryParams = new URLSearchParams({ param: query }).toString();
    return this.http.get<Diagnosis[]>(`${this.SIDECAR_BASE}/icd-code-10?${queryParams}`)
      .pipe(
        retry(3)
      );
  }

  getPatientDiganosis(patientFhirId: string): Observable<Diagnosis[]> {
    return this.http.get<Bundle<Condition>>(`${this.FHIR_BASE}/Condition?patient=${patientFhirId}&_sort=_lastUpdated`)
      .pipe(
        map((response: Bundle<Condition>): string[] => {
          if (response?.entry?.length) {
            return ExtractDiagnosisCodesFromConditonAsString(
              response?.entry?.map((item: any) => item.resource)
              ?.[0]
            )
          }
          return [];
        }),
        switchMap((codes: string[]): Observable<any> => {
          if (!codes.length) {
            return of([]);
          }
          return this.getNonDefaultDiagnosisByCodes(codes)
        }),
        retry(3)
      )
  }
}
