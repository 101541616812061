import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneNumberValidator, ZipCodeValidator, MaskedPhoneNumberValidator } from '../config/app.config';
import { FhirPatientResponse } from '../shared/models/fhir/patient/response/fhir-patient-response';
import { emailValidator } from '../utils/shared.util';
import { PatientAlreadyExistsValidator } from '../validators/patient-already-exists.validator';
import { ActiveIHealthValidator } from '../validators/active-ihealth.validator';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private formBuilder: FormBuilder,
    private patientAlreadyExistsValidator: PatientAlreadyExistsValidator,
    private activeIHealthValidator: ActiveIHealthValidator
  ) { }

  generatePatientProfileForm(patient?: FhirPatientResponse): FormGroup {
    if (patient) {
      const {
        email,
        firstName,
        lastName,
        gender,
        phone,
        dateOfBirth,
        address,
        iHealthEmail,
        mrn,
        communicationMethod,
        diagnosis,
      } = patient;
      return this.formBuilder.group({
        email: [
          email,
          [Validators.required, emailValidator()]
        ],
        firstName: [firstName, Validators.required],
        lastName: [lastName, Validators.required],
        gender: [gender, Validators.required],
        phone: [phone, [
          Validators.required,
          Validators.pattern(MaskedPhoneNumberValidator),
        ]],
        dateOfBirth: [dateOfBirth, Validators.required],
        address1: [address?.[0].addressLine1 ?? "", Validators.required],
        address2: [address?.[0].addressLine2 ?? ""],
        apt: [address?.[0].apt ?? ""],
        zip: [address?.[0].postalCode, [
          Validators.required,
          Validators.pattern(PhoneNumberValidator),
        ]],
        city: [address?.[0].city ?? '', Validators.required],
        province: [address?.[0].state ?? '', Validators.required],
        iHealthEmail: [
          iHealthEmail,
          [Validators.required, emailValidator()]
        ],
        mrn: [mrn],
        communicationMethod: [communicationMethod],
        diagnosis: [diagnosis?.filter(d => d != undefined)?.map(d => d.code)],// Jira #R2-389
        diagnosisFilterControl: [''],
      });
    }
    return this.formBuilder.group({
      email: new FormControl('', {
        validators: [Validators.required, emailValidator()],
        asyncValidators: [this.patientAlreadyExistsValidator.validate.bind(this.patientAlreadyExistsValidator)],
        updateOn: 'blur'
      }),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(MaskedPhoneNumberValidator),
      ]],
      dateOfBirth: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      apt: [''],
      zip: ['', [
        Validators.required,
        Validators.pattern(PhoneNumberValidator),
      ]],
      //#R2-929 - Added validation for active ihealth patient exist
      iHealthEmail: new FormControl('', {
        validators: [Validators.required, emailValidator()],
        asyncValidators: [this.activeIHealthValidator.validate.bind(this.activeIHealthValidator)],
        updateOn: 'blur'
      }),
      mrn: [''],
      communicationMethod: [''],
      diagnosis: [[]],// Jira #R2-389
      diagnosisFilterControl: [''],
      city: ['', Validators.required],
      province: ['', Validators.required],
    });
  }
}
