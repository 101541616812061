import { Component, HostBinding, OnInit } from '@angular/core';
import { Patient } from 'fhir/r4';
import { Subject, takeUntil } from 'rxjs';
import { ExtendedPatient } from 'src/app/models/patient.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { PatientService } from 'src/app/services/patient.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';

@Component({
  selector: 'patient-app-patient-navigation-header',
  templateUrl: './patient-navigation-header.component.html',
  styleUrls: ['./patient-navigation-header.component.scss']
})
export class PatientNavigationHeaderComponent implements OnInit {
  unsubscribe = new Subject<void>();
  activePatientCount = 0;
  pendingPatientCount = 0;
  inactivePatientCount = 0;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    public patientService: PatientService,
    private breakpointService: BreakpointService
  ) { }

  ngOnInit(): void {
    this.patientService.activePatientListing$
      .subscribe((patients: ExtendedPatient[] | null) => {
        this.activePatientCount = patients?.length ?? 0;
      });

    this.patientService.pendingPatientListing$
      .subscribe((response: ExtendedPatient[] | null) => {
        if (response) {
          this.pendingPatientCount = response?.length ?? 0;
        }
      });
    this.patientService.inactivePatientListing$
      .subscribe((patients: Patient[] | null) => {
        this.inactivePatientCount = patients?.length ?? 0;
      });

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

}
