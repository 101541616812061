<div class="patients-patient-detail-row">
    <!-- Patient Photo -->
    <div class="patients-photo-section" [ngClass]="{'tablet':isTablet}">
        <mat-icon class="patients-patient-icon" [ngClass]="{'tablet':isTablet}">account_circle</mat-icon>
    </div>

    <!-- Patient Details -->
    <div class="patients-details-section" [ngClass]="{'tablet':isTablet}">

        <!-- Personal Information and patient name section -->
        <div class="patients-spinner-container" *ngIf="(spinner && !patientDetails) || isSavingEditProfile">
            <mat-spinner class="patients-rpm-spinner" [strokeWidth]="spinnerProps.strokeWidth"
                [diameter]="spinnerProps.diameter" mode="indeterminate"></mat-spinner>
        </div>
        <div class="patients-details-header" [ngClass]="{'tablet':isTablet}"
            *ngIf="patientDetails && !isSavingEditProfile">
            <div class="patients-custom-title-row">
                <div class="patients-custom-title">Personal Information</div>
                <div class="patients-patient-name">
                    {{patientDetails.firstName | titlecase }} {{patientDetails.lastName | titlecase }}
                </div>
            </div>
            <div class="patients-actions-row">
                <button mat-raised-button color="primary" class="patients-action-button"
                    *ngIf="isActiveCarePlan" (click)="dischargePatient()">Discharge Patient</button>
                <button *ngIf="isActiveCarePlan" mat-raised-button color="primary" class="patients-action-button"
                    (click)="showEditPatientModel()">Edit Profile</button>
            </div>
        </div>

        <!-- patient Details like address etc -->
        <div class="patients-details-content" *ngIf="patientDetails && !isSavingEditProfile">
            <div class="patients-details-row">
                <span class="patients-detail-text">Patient Address: </span>
                <span class="patients-detail-values">
                    {{patientDetails.address?.[0]?.addressLine1}},
                    {{patientDetails.address?.[0]?.addressLine2 ? patientDetails.address?.[0]?.addressLine2 + ',' :''}}
                    {{patientDetails.address?.[0]?.postalCode}}
                </span>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">MRN:</span>
                <span class="patients-detail-values">{{patientDetails.mrn}}</span>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">Email: </span>
                <span class="patients-detail-values">{{patientDetails.email}}</span>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">iHealthEmail:</span>
                <span class="patients-detail-values">{{patientDetails.iHealthEmail}}</span>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">Phone: </span>
                <span class="patients-detail-values">{{patientDetails.phone}}</span>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">Date of Birth:</span>
                <span class="patients-detail-values">{{patientDetails.dateOfBirth}}</span>
            </div>
            <div class="patients-details-row">
                <ng-container *ngIf="patientDetails.diagnosis">
                    <span class="patients-detail-text">Diagnosis:</span>
                    <div class="patients-detail-values diagnosis-col">
                        <span *ngFor="let diagnosis of patientDetails.diagnosis">{{diagnosis?.code + " - " +
                            diagnosis?.longDisplay}}</span>
                    </div>
                </ng-container>
            </div>
            <div class="patients-details-row">
                <span class="patients-detail-text">Preferred Communication Method:</span>
                <span class="patients-detail-values">{{patientDetails.communicationMethod == 'email'?'Email':'Text Message'}}</span>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isActiveCarePlan">
    <div class="patients-manage-device" >Manage Devices</div>

    <div class="patients-devices-row">
        <div class="patients-spinner-container" *ngIf="spinner && !connectedDevices.length ">
            <mat-spinner class="patients-rpm-spinner" [strokeWidth]="spinnerProps.strokeWidth"
                [diameter]="spinnerProps.diameter" mode="indeterminate"></mat-spinner>
        </div>
        <ng-container *ngIf="connectedDevices?.length">
            <div class="patients-device-cell" *ngFor="let device of connectedDevices">
                <div class="patients-device-logo-container mat-elevation-z12">
                    <img [src]="device.logo_url" alt="Device Logo" class="patients-device-logo" />
                </div>
                <button mat-raised-button (click)="promptDisconnect(device)">Disconnect</button>
            </div>
        </ng-container>
        <div class="patients-device-cell" (click)="showImportDataModal()" *ngIf="!spinner">
            <div class="patients-add-device mat-elevation-z12">
                <mat-icon class="patients-add-device-icon">add_circle</mat-icon>
            </div>
        </div>
    </div>
</ng-container>