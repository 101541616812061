import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material/material.module';
import { CustomMatTableComponent } from './components/custom-mat-table/custom-mat-table.component';
import { CdkDetailRowDirective } from '../directives/cdk-detail-row.directive';
import { MaskPhoneNumberDirective } from './directives/mask-phone-number.directive';
import { LocalTimePipe } from './pipes/local-time.pipe';
import { ZipCodeFormatterDirective } from './directives/zip-code-formatter.directive';
import { AssetUrlPipe } from './pipes/asset-url.pipe';


@NgModule({
  declarations: [
    CustomMatTableComponent,
    CdkDetailRowDirective,
    MaskPhoneNumberDirective,
    LocalTimePipe,
    ZipCodeFormatterDirective,
    AssetUrlPipe,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    CustomMatTableComponent,
    CdkDetailRowDirective,
    MaskPhoneNumberDirective,
    ZipCodeFormatterDirective,
    LocalTimePipe,
    AssetUrlPipe,
  ],
})
export class SharedModule { }
