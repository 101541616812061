import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[patientAppZipCodeFormatter]'
})
export class ZipCodeFormatterDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const formattedValue = this.formatValue(value);
    inputElement.value = formattedValue;
  }

  private formatValue(value: string): string {
    // remove existing spaces
    const trimmedValue = value.replace(/\s+/g, ''); 
    // remove special characters
    const sanitizedValue = trimmedValue.replace(/[^A-Za-z0-9]/g, ''); 
    let formattedValue = '';
    for (let i = 0; i < sanitizedValue.length; i++) {
      if (i === 3) {
        formattedValue += ' ';
      }
      formattedValue += sanitizedValue.charAt(i);
    }
    return formattedValue;
  }
}
