import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle, CarePlan, Observation } from 'fhir/r4';
import { Observable } from 'rxjs';
import { BaseService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ObservationService extends BaseService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  getGoalObservations(patientId: string, goalCode: string, careplan?: CarePlan): Observable<Bundle<Bundle<Observation>>> {
    //#R2-698 - last update sorting will be accurate for last observations
    let queries = `?patient:Patient._id=${patientId}&_sort=-_lastUpdated&code=${goalCode}`;
    if (careplan?.period?.start) {
      queries = `${queries}&date=ge${careplan?.period?.start}`;  // https://dxpbhc.atlassian.net/browse/R2-568
    }
    return this.http.get<Bundle<Bundle<Observation>>>(`${this.FHIR_BASE}/Observation${queries}`);
  }
}
