import '@zonejs-patch';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ActivePatientsComponent } from './components/active-patients/active-patients.component';
import { PendingPatientsComponent } from './components/pending-patients/pending-patients.component';
import { InactivePatientsComponent } from './components/inactive-patients/inactive-patients.component';
import { PatientInformationDialogComponent } from './components/patient-information-dialog/patient-information-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RPMDateFormat } from './config/app.config';
import { MonitoringProgramDialogComponent } from './components/monitoring-program-dialog/monitoring-program-dialog.component';

import { ImportDataDialogComponent } from './components/import-data-dialog/import-data-dialog.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SearchPatientsComponent } from './components/search-patients/search-patients.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { PatientNavigationHeaderComponent } from './components/patient-navigation-header/patient-navigation-header.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { PatientChartsComponent } from './patient-overview/components/patient-charts/patient-charts.component'; // do not remove, required for proper rendering and rxjs subscriptions
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { SharedModule } from './shared/shared.module';
import { PatientOverviewModule } from './patient-overview/patient-overview.module';
import { getMsalInstance } from '@bayshoreHealthCare/store';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
import { SelectCalcomTeamMemberComponent } from './components/select-calcom-team-member/select-calcom-team-member.component';
import { DashboardButtonComponent } from './components/dashboard-button/dashboard-button.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return getMsalInstance();
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  protectedResourceMap.set('https://devvantage-api.bayshoredigital.ca/api/fhir-service', []);

  https: return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {},
    loginFailedRoute: '/login-failed',
  };
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: RPMDateFormat,
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    ActivePatientsComponent,
    PendingPatientsComponent,
    InactivePatientsComponent,
    PatientInformationDialogComponent,
    MonitoringProgramDialogComponent,
    ImportDataDialogComponent,
    SearchPatientsComponent,
    ConfirmDialogComponent,
    NotificationComponent,
    SafePipe,
    PatientNavigationHeaderComponent,
    PatientChartsComponent,
    SelectCalcomTeamMemberComponent,
    DashboardButtonComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    PowerBIEmbedModule,
    // todo: lazy load PatientOverviewModule
    PatientOverviewModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
