import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle, PlanDefinition } from 'fhir/r4';
import { Observable, Subject } from 'rxjs';
import { DefaultResourceQueryCount } from '../config/app.config';
import { BaseService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends BaseService {
  getProgramsFromParent$ = new Subject<PlanDefinition[]>();
  
  constructor(
    private http: HttpClient,
  ) { 
    super();
  }
  
  getPrograms(): Observable<Bundle<PlanDefinition>> {
    return this.http.get<Bundle<PlanDefinition>>(`${this.FHIR_BASE}/PlanDefinition?_total=accurate&_count=${DefaultResourceQueryCount}`);
  }

  /**
   * 
   * @param presetMetrics 
   * @returns 
   */
  updateProgram(
    { chosenProgram, formValue, newMetrics }: { chosenProgram: PlanDefinition, newMetrics: any, formValue: any }
  ): Observable<any> {
    const { id, goal } = chosenProgram;
    const mappedGoals = formValue.tableForm.map((metric: any) => {
      const mappedGoal = goal?.find((goal: any) => goal.id === metric.goalId)
        ?? newMetrics.find((goal: any) => goal.id === metric.goalId);
      const mappedTarget = mappedGoal.target.find((target: any) => target.id === metric.targetId);
      mappedTarget.detailRange.low.value = metric?.low || '';
      mappedTarget.detailRange.high.value = metric?.high || '';
      return {
        ...mappedGoal,
        target: mappedTarget
      }
    });
    const mappedPayload = {
      ...chosenProgram,
      description: formValue.programDescription,
      goal: mappedGoals
    };
    return this.http.put(`${this.FHIR_BASE}/PlanDefinition/${id}`, mappedPayload);
  }
}
