import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from 'src/app/models/matDialogData.model';
import { ShowLoaderService } from 'src/app/services/show-loader.service';

@Component({
  selector: 'patient-app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  confirmationHeader!: string;
  confirmationQuestion!: string;
  patientId!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData,
    private loaderService: ShowLoaderService
  ) {
    this.loaderService.isShowLoadingSectionVisible$.next(false);
  }

  ngOnInit(): void {
    this.confirmationHeader = this.dialogData?.confirmationHeader;
    this.confirmationQuestion = this.dialogData?.confirmationQuestion;
    this.patientId = this.dialogData?.patientId ?? '';
  }

}
