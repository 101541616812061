import { Subject, takeUntil, forkJoin, switchMap, of, timer, catchError, throwError, delay, retry, combineLatest } from 'rxjs';
import { Component, OnInit, OnDestroy, HostListener, Inject, HostBinding } from '@angular/core';
import { PatientService } from 'src/app/services/patient.service';
import { FhirPatientResponse } from 'src/app/shared/models/fhir/patient/response/fhir-patient-response';
import { ValidicService } from 'src/app/services/validic.service';
import { DeviceConnectResponse } from 'src/app/shared/models/validic/device_connect/response/device-connect-response';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from 'fhir/r4';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportDataDialogData } from 'src/app/models/matDialogData.model';
import { CarePlanService } from 'src/app/services/careplan.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { spinnerProperties } from 'src/app/config/app.config';
import { NotificationService } from 'src/app/services/notification.service';
import { ShowLoaderService } from 'src/app/services/show-loader.service';
import { ValidicUserResponse } from 'src/app/shared/models/validic/user/validic-user-model';

@Component({
  selector: 'patient-app-import-data-dialog',
  templateUrl: './import-data-dialog.component.html',
  styleUrls: ['./import-data-dialog.component.scss']
})
export class ImportDataDialogComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();
  validicData!: ValidicUserResponse;
  deviceConnectInfo: Array<DeviceConnectResponse> | null = null;
  patient!: FhirPatientResponse;
  patientProgramEnrollmentTask!: Task;

  // spinner Properties like diameter and stroke width
  defaultSpinnerProperties = spinnerProperties;

  apiError: string = '';
  /**
   * Used to track if the user clicked on connect button and is currently 
   * redirected to another tab. true if user is taken to new tab.
   */
  redirected: boolean = false;
  timer: any;
  isImportDataButtonDisabled = true;
  isPatientActive = false;
  newWindow!: any;

  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ImportDataDialogData,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private validicService: ValidicService,
    private dialogRef: MatDialogRef<ImportDataDialogComponent>,
    private carePlanService: CarePlanService,
    private breakpointService: BreakpointService,
    private notificationService: NotificationService,
    private loaderService: ShowLoaderService,
  ) { }

  ngOnInit(): void {
    // for pending patients
    if (!!this.dialogData?.fhirPatientResponse) {
      // calling patient service before subscribing to addedPatient$ to avoid 
      // duplicate api calls and subscriptions made inside this component.
      this.patientService.addedPatient$.next(this.dialogData.fhirPatientResponse);
    }

    // todo: refactor addedPatient$, patientEnrollmentTask$ - use mat dialogData to initialize this.patient, this.patientEnrollmentTask
    this.patientService.addedPatient$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: (patient: FhirPatientResponse | null) => {
          if (patient) {
            this.patient = patient;
            // #R2-998 - checking active care plan to handle isPatientActive
            this.carePlanService.hasActiveCarePlan(patient.id).subscribe(isActive => {
              this.isPatientActive = isActive;
            });
            this.addValidicSubscription(patient.id);
          }
        },
        error: (error) => {
          throw new Error(error);
        }
      });

    this.patientService.patientEnrollmentTask$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: (enrollTask: Task | null) => {
          if (enrollTask) {
            this.patientProgramEnrollmentTask = enrollTask;
          }
        }, error: (error: any) => {
          throw new Error(error);
        }
      });
    if (!!this.dialogData?.patientProgramEnrollmentTask) {
      this.patientService.patientEnrollmentTask$.next(this.dialogData.patientProgramEnrollmentTask);
    }

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });

  }

  addValidicSubscription(patientFhirId: string) {
    this.validicService.getValidicUserDetails(patientFhirId)
      .pipe(
        switchMap((validicResponse: ValidicUserResponse | null) => {
          if (!validicResponse) {
            return this.validicService.createUser({
              uid: this.patient.id,
              email: this.patient.email,
              firstName: this.patient.firstName,
              lastName: this.patient.lastName,
              iHealthEmail: this.patient.iHealthEmail
            })
          }
          this.validicData = validicResponse;
          return of(validicResponse)
        }),
        switchMap((value: ValidicUserResponse | null) => {
          if (value) {
            return this.validicService.getValidicDeviceConnectInfo(value)
          }
          return of(false)
        }),
        retry({
          count: 3,
          delay: 1500
        }),
      )
      .subscribe({
        next: (response: Array<DeviceConnectResponse> | false) => {
          if (response) {
            this.deviceConnectInfo = response;
            this.validateImportDataButton();
            this.resetApiErrors()
          } else {
            this.apiError = 'Getting device list failed';
            this.deviceConnectInfo = [];
          }
        },
        error: (error) => {
          console.error("Error in DeviceConnectInfo: ", error)
          //instead of showing error notification and closing show the message inside the modal itself
          this.apiError = 'Getting device list failed';
          this.deviceConnectInfo = [];
        }
      })
  }

  resetApiErrors() {
    this.apiError = '';
  }


  showDeviceConnectWebpage(url: string) {
    this.redirected = true;
    this.newWindow = window.open(url);
  }

  getDeviceConnectDetails() {
    if (this.validicData?.id) {
      this.validicService
        .getValidicDeviceConnectInfo(this.validicData)
        .subscribe({
          next: (response: Array<DeviceConnectResponse>) => {
            this.deviceConnectInfo = response;
            this.validateImportDataButton()
            this.resetApiErrors()
          },
          error: (error) => {
            console.error("Error in DeviceConnectInfo: ", error)
            //instead of showing error notification and closing show the message inside the modal itself
            this.apiError = 'Getting device list failed';
            this.deviceConnectInfo = [];
          }
        })
    }
  }

  /**
   * For ngFor Tracking
   */
  deviceTrackBy(index: any, item: DeviceConnectResponse) {
    return item.display_name
  }

  /**
   * This listener is used to handle and fetch the updated data
   * from validic after connecting/ disconnecting devices. 
   * Note : This function should be passed as a parameter 
   * to removeEventListener to properly remove the listener !
   * @param event any
   */
  @HostListener('window:focus', ['$event'])
  focusListener(event: FocusEvent) {
    if (this.redirected) {
      this.deviceConnectInfo = null;
      this.addValidicSubscription(this.patient.id);
      this.redirected = false
    }
  }

  handleConnectButton(device: DeviceConnectResponse): void {
    if (!device.connect_url) {
      throw new Error('No device connection url');
    }
    this.timer = setInterval(() => {
      if (this.patient?.id) {
        this.addValidicSubscription(this.patient.id);
      }
    }, 5000)
    this.showDeviceConnectWebpage(device.connect_url);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    clearInterval(this.timer);
    // #R2-958 - deleting validic user to avoid unused validic accounts
    if (this.isImportDataButtonDisabled) {
      this.validicService.deleteValidicUser(this.patient.id)
      .subscribe(() => {
        console.log("deleted validic account");
      });
    } else {
      // #R2-958 - Handled when the user clicking th cancel button after connected with ihealth account
      if (!this.isPatientActive) {
        this.validicService.deleteValidicUser(this.patient.id)
        .subscribe(() => {
          console.log("deleted validic account");
        });
      }
    }
  }

  closeDialog() {
    clearInterval(this.timer);
    this.loaderService.isShowLoadingSectionVisible$.next(true);

    if (this.patientProgramEnrollmentTask?.id && this.dialogData.carePlan?.id) {

      const carePlanId = this.dialogData.carePlan.id;
      if (!this.isPatientActive) {
        // #R2-1190 - Need to call create sidecar user api after the careplan activated
        this.patientService.createSidecarUser(this.patient.id, this.patient, this.dialogData.activeOrganizationId)
        .subscribe({
          next: () => { 
            this.patchCarePlan(carePlanId);
          },
          error: (error) => { 
            console.error('Create sidecar user error: ', error);
            this.loaderService.isShowLoadingSectionVisible$.next(false);
            this.patientService.importDataSuccess$.next(false);
            this.notificationService.showNotification('Error occured in sidecar, Try again later', 'error');
          }
        })
      } else {
        this.patchCarePlan(carePlanId);
      }

    } else {
      // TODO: For pending patients get the patientProgramEnrollmentTask and use it to update
      // TODO: If patient is active, then just close the modal (Because enrollment was already completed and careplan is also active)
      this.loaderService.isShowLoadingSectionVisible$.next(false);
      this.dialogRef.close(true);
      this.patientService.importDataSuccess$.next(true);
      this.notificationService.showNotification('Successfully imported data', 'success');
    }
  }

  /**
   * Return true there is no device connected ( based on the device 
   * connection api response from validic api.) else returns false.
   * This is used to disable and enable import data button
   */
  validateImportDataButton() {
    if (this.deviceConnectInfo && this.deviceConnectInfo.length) {
      const connectedDevice = this.deviceConnectInfo.find((detail: DeviceConnectResponse) => detail.connected === true);
      if (connectedDevice) {
        this.isImportDataButtonDisabled = false;
        this.newWindow.close();
        return;
      }
    }
    this.isImportDataButtonDisabled = true;
  }

  patchCarePlan(carePlanId: string) {
    combineLatest([
        // update patient program enrollment task status to 'completed' on successful device sync
        // #R2-527 - need to pass task resrc to keep input source & destination of patient
        this.patientService.updatePatientProgramEnrollmentTask(this.patient.id, this.patientProgramEnrollmentTask),
        // update careplan status to active
        this.carePlanService.patchCarePlan(carePlanId, [{
          op: 'replace',
          path: '/status',
          value: 'active'
        }])
      ])
      .subscribe({
        next: () => {
          this.isPatientActive = true;
          this.loaderService.isShowLoadingSectionVisible$.next(false);
          // once task is updated close the modal !
          this.dialogRef.close(true);
          this.patientService.importDataSuccess$.next(true);
          this.notificationService.showNotification('Successfully imported data', 'success');
          // redirect to patient profile 
          this.router.navigate([`../${this.patient.id}/profile`], { relativeTo: this.route, replaceUrl: false });
        },
        error: (error: any) => {
          console.error('Save Import Data Error: ', error);
          this.loaderService.isShowLoadingSectionVisible$.next(false);
          this.patientService.importDataSuccess$.next(false);
          this.notificationService.showNotification('Error occured during importing data, Try again later', 'error');
        }
      });
  }
}
