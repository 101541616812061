/**
 * Common config items
 */
export const RPMDateFormat = 'YYYY-MM-DD';
export const PatientSummaryDateFormat = 'DD MMM YYYY : hh.mm a';
export const AlertTimeFormat = 'h.mmA';
export const AuthoredOnFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const AddCommentModalTimeFormat = 'dddd, MMMM Do YYYY, h:mm a';

export const DialogResponsiveWidth = {
  medium: '750px',
  tablet: '750px',
  large: '750px',
  xlarge: '750px',
  retina: '900px',
  retinaXdr: '1181.25px',
  retina4k: '1600px',
}
export const DefaultDialogProperties = {
  width: DialogResponsiveWidth.large, //default to '750px',
  disableClose: true,
  panelClass: 'custom-dialog-container',
};

export const notificationDuration = 3000;
export const SearchDebounceTime = 500;
export const MinQueryLengthToFireSearch = 2;
export const MatTableDefaultPageSizes = [10, 20, 30, 40];

export const PrimaryThemeColor = '#0b435b';

export const CommunicationMethods = [
  { text: 'Email', value: 'email' },
  { text: 'Text Message', value: 'sms' },
];

export const spinnerProperties = {
  diameter: 50,
  strokeWidth: 2.5
}

export const ProvincesInCanada = [
  { "text": "Alberta" },
  { "text": "British Columbia" },
  { "text": "Manitoba" },
  { "text": "New Brunswick" },
  { "text": "Newfoundland and Labrador" },
  { "text": "Nova Scotia" },
  { "text": "Ontario" },
  { "text": "Prince Edward Island" },
  { "text": "Quebec" },
  { "text": "Saskatchewan" }
];

export const ZipCodeValidator = '^\\d{3} \\d{3} \\d{4}$';
export const PhoneNumberValidator = '^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$';
export const MaskedPhoneNumberValidator = '^\\(\\d{3}\\) \\d{3}-\\d{4}$';

export const customBreakpoints = {
  XSmall: '(max-width: 599px)',
  Small: '(min-width: 600px) and (max-width: 959px)',
  Medium: '(min-width: 960px) and (max-width: 1279px)',
  Tablet: '(min-width: 600px) and (max-width: 1279px)',
  Large: '(min-width: 1280px) and (max-width: 1919px)',
  XLarge: '(min-width: 1920px) and (max-width:2303px)',
  Retina: '(min-width: 2304px) and (max-width:3023px)',
  RetinaXDR: '(min-width: 3024px) and (max-width:4095px)',
  Retina4k: '(min-width: 4096px) and (max-width:5119px)',
  Retina5k: '(min-width: 5120px)',
}

export const AlertTypeIcons: any = {
  '29463-7': {
    icon: 'weight',
  },
  '15074-8': {
    icon: 'bloodglucose',
  },
  '59408-5': {
    icon: 'spo2',
  },
  '8867-4': {
    icon: 'heartrate',
  },
  '85354-9': {
    icon: 'bloodpressure',
  },
  '8310-5': {
    icon: 'temperature',
  },
}

export const GoalsMinMaxValues: any = {
  // weight
  '29463-7': {
    low: 50,
    high: 500,
  },
  // blood glucose
  '15074-8': {
    low: 0,
    high: 30,
  },
  // spo2
  '59408-5': {
    low: 30,
    high: 100,
  },
  // heartrate
  '8867-4': {
    low: 20,
    high: 300,
  },
  // blood pressure systolic/blood pressure diastolic
  '85354-9': {
    low: 20,
    high: 500,
  },
  // temperature
  '8310-5': {
    low: 20,
    high: 60,
  },
}

export const GoalCodes = {
  WEIGHT: '29463-7',
  BLOOD_GLUCOSE: '15074-8',
  SPO2: '59408-5',
  HEART_RATE: '8867-4',
  BLOOD_PRESSURE: '85354-9',
  TEMPERATURE: '8310-5'
}

export const GoalTitles = {
  WEIGHT: 'Weight',
  BLOOD_GLUCOSE: 'Blood Glucose',
  SPO2: 'SPO2',
  HEART_RATE: 'Heart Rate',
  BLOOD_PRESSURE: 'Blood Pressure',
  TEMPERATURE: 'Temperature',
}

export const DefaultResourceQueryCount = 1000;

export enum FhirResourceType {
  Patient = 'Patient',
  CarePlan = 'CarePlan',
  Task = 'Task',
  Goal = 'Goal',
  Condition = 'Condition',
  ServiceRequest = 'ServiceRequest',
}

export const API_POLLING_DURATION = 2 * 60000; // 2 minutes

export enum BloodPressureTexts {
  systolic = 'blood pressure systolic',
  diastolic = 'blood pressure diastolic',
}

export const INACTIVE_DURATION = 10 * 60 * 1000; // 10 minutes;

export const EstTimezone = 'America/Toronto';

// R2-1093
export const GoalDecimalInput: { [key: string]: boolean } = {
  // weight
  '29463-7': true,
  // temperature
  '8310-5': true,
  // blood glucose
  '15074-8': true,
  // spo2
  '59408-5': false,
  // blood pressure
  '85354-9': false,
  // heart rate
  '8867-4': false,
}

export interface ApiConfig {
  fhirBase: string;
  sideCarAPI: string; 
  validicAPI: string; 
  powerbiAPI: string; 
  newPowerbiAPI: string; 
  virtualEvents: string;
}

export const activePatientTableColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'referredFrom', title: 'Referred From' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'diagnosis', title: 'Diagnosis' },
    { column: 'programName', title: 'Program' },
    { column: 'action', title: 'Action' },
];

export const pendingPatientTableColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'referredFrom', title: 'Referred From' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'diagnosis', title: 'Diagnosis' },
    { column: 'programName', title: 'Program' },
    { column: 'status', title: 'Status' },
    { column: 'action', title: 'Action' },
];
  
export const inActivePatientTableColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'dischargeDate', title: 'Discharge Date' },
    { column: 'status', title: 'Status' },
    // need to show program name after discharge a patient
    { column: 'programName', title: 'Program' },
    { column: 'action', title: 'Action' },
  ];