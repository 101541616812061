<div class="patients-title-row">
  <h2 mat-dialog-title class="patients-custom-dialog-title">Select Calcom Team Member</h2>
  <mat-icon class="patients-pointer" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content>
  <section class="members-container">
    <div *ngFor="let member of members">
      <button 
        mat-raised-button 
        [mat-dialog-close]="false" 
        [attr.data-cal-link]="member.embeddedView">
          <mat-icon>account_circle</mat-icon>
          {{member.name}}
      </button>
    </div>
  </section>

  <mat-dialog-actions [align]="'end'">
    <button mat-button [mat-dialog-close]="false" class="patients-basic-button empty">Cancel</button>
  </mat-dialog-actions>
</mat-dialog-content>

