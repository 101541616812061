import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Subject, takeUntil, tap } from 'rxjs';
import { DefaultDialogProperties, pendingPatientTableColumns } from 'src/app/config/app.config';
import { MapPatientResourceToViewModel, SetPatientTableData } from 'src/app/mappers/patient.mapper';
import { ConfirmDialogData } from 'src/app/models/matDialogData.model';
import { ExtendedPatient, PendingPatientListing } from 'src/app/models/patient.model';
import { SearchCategory } from 'src/app/models/shared.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CarePlanService } from 'src/app/services/careplan.service';
import { PatientService } from 'src/app/services/patient.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { SearchPatients } from 'src/app/utils/shared.util';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'patient-app-pending-patients',
  templateUrl: './pending-patients.component.html',
  styleUrls: ['./pending-patients.component.scss']
})
export class PendingPatientsComponent implements OnInit, OnDestroy {
  patients: PendingPatientListing[] = [];
  patientsCopy: ExtendedPatient[] = [];
  displayedColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'referredFrom', title: 'Referred From' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'diagnosis', title: 'Diagnosis' },
    { column: 'programName', title: 'Program' },
    { column: 'status', title: 'Status' },
    { column: 'action', title: 'Action' },
  ];
  unsubscribe = new Subject<void>();
  showSpinner: boolean = true;
  systemColumns = pendingPatientTableColumns;
  systemColumnsForm!: FormGroup;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private patientService: PatientService,
    private dialog: MatDialog,
    private carePlanService: CarePlanService,
    private breakpointService: BreakpointService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getPatients();
    this.patientService.refreshPendingPatientListing$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.getPatients());
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
    
    /** Create Form group for showing check boxes in system column filter
     *  form group with controls with name same as column name from `systemFilters`
     */
    this.systemColumnsForm = this.formBuilder.group(
      pendingPatientTableColumns.reduce((obj: any, item) => {
        obj[item.column] = true;
        return obj;
      }, {})
    );

    /**
     * For column filter changes
     */
    this.systemColumnsForm.valueChanges
      .pipe(
        tap((values: any) => {
          const activeColumns = Object.keys(values)
            .filter((item: any) => values[item]);
          const updatedColumns = pendingPatientTableColumns
            .filter((item: any) => activeColumns.find((key: any) => key === item.column));
          this.displayedColumns = [...updatedColumns];
          return EMPTY;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  addPatientButtonClick(): void {
    this.patientService.initiateAddPatient$.next();
  }

  onConfirmEmailClick(event: Event, element: any): void {
    event.stopPropagation();
    this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
      ConfirmDialogComponent, {
      ...DefaultDialogProperties,
      data: {
        confirmationHeader: 'Resend Confirmation Email',
        confirmationQuestion: 'Do you want to resend the consent email?'
      }
    })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          return;
        }
      });
  }

  onEditCarePlan(event: Event, patient: any) {
    this.carePlanService.editCarePlanForPendingPatient$.next(patient);
    event.stopPropagation();
  }

  /**
   * Search for query in retrieved extended patients & update patients property (mat table dataSource)
   */
  emitSearchQuery(searchParams: { category: SearchCategory, query: string }): void {
    this.patients = SearchPatients({
      ...searchParams,
      patientListing: this.patientsCopy
        .map(MapPatientResourceToViewModel) as PendingPatientListing[],
    });
  }

  clearSearch(): void {
    this.getPatients();
  }

  private getPatients(): void {
    // reset pending patients & show the loader
    this.patients = [];
    this.patientsCopy = [];
    this.showSpinner = true;

    this.patientService.getPendingPatientListing()
      .subscribe((patients: ExtendedPatient[]) => {
        // to stop showing spinner if there are no patients
        if (patients !== null) {
          this.showSpinner = false;
        }
        this.patientsCopy = patients ?? [];
        SetPatientTableData(this, patients ?? []);
      });
  }
}
