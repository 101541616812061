<div class="patients-title-row">
  <h2 mat-dialog-title class="patients-custom-dialog-title">Editing {{selectedProgram.title}}</h2>
  <mat-icon class="patients-pointer" [mat-dialog-close]="false" (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content style="overflow-x: hidden;">
  <ng-container *ngIf="isRenderForm">
    <form [formGroup]="formGroup" autocomplete="off">
      <!-- program name -->
      <span class="mat-h4 patients-input-label">Program Name *</span>
      <ng-container *ngIf="!!dialogData.disableProgramTitleDescription">
        <mat-form-field>
          <input formControlName="programName" matInput [readonly]="!!dialogData.disableProgramTitleDescription"
            [class.disabled]="!!dialogData.disableProgramTitleDescription">
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!dialogData.disableProgramTitleDescription">
        <mat-form-field class="patients-select-form-field">
          <mat-select formControlName="programName" (selectionChange)="onProgramChanged($event)">
            <mat-option *ngFor="let program of programs" [value]="program.id">{{program.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!-- program description -->
      <span class="mat-h4 patients-input-label">Program Description *</span>
      <mat-form-field>
        <textarea matInput formControlName="programDescription" [readonly]="!!dialogData.disableProgramTitleDescription"
          [class.disabled]="!!dialogData.disableProgramTitleDescription"></textarea>
        <mat-error
          *ngIf="formGroup.get('programDescription')?.touched && formGroup.get('programDescription')?.hasError('required')">
          Program description is required.
        </mat-error>
      </mat-form-field>
      <!-- add metrics -->
      <span class="mat-h4 patients-input-label">Add Metrics *</span>
      <ng-container *ngIf="dialogData?.disableAddedGoals">
        <mat-form-field>
          <input matInput readonly="true" [class.disabled]="dialogData.disableAddedGoals">
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!dialogData?.disableAddedGoals">
        <mat-form-field class="patients-select-form-field">
          <mat-select #addedGoalsRef formControlName="addMetrics" multiple (closed)="onGoalsAdded()"
            [(value)]="selectedGoals">
            <ng-container *ngFor="let goal of addedGoals">
              <mat-option [value]="goal" (click)="handleGoalClick(goal)">{{goal.description.text | titlecase}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- monitored metrics table -->
      <h4 mat-dialog-title class="patients-present-threshold">Preset Threshold</h4>
      <section class="table-container">
        <ng-container formArrayName="tableForm">
          <table mat-table [dataSource]="dataSource">
            <!-- metrics name -->
            <ng-container matColumnDef="metricName">
              <th mat-header-cell *matHeaderCellDef>Metrics</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                <mat-form-field appearance="standard" [ngClass]="'no-border'" class="patients-full-width">
                  <input matInput type="text" formControlName="metricName" [readonly]="true">
                </mat-form-field>
                <!-- goal id -->
                <mat-form-field style="display: none;">
                  <input matInput type="text" formControlName="goalId" [hidden]="true">
                </mat-form-field>
              </td>
            </ng-container>
            <!-- low value -->
            <ng-container matColumnDef="lowValue">
              <th mat-header-cell *matHeaderCellDef style="text-align: left;">Low Value</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                <div class="patients-form-field-wrapper">
                  <mat-form-field style="text-align: center;"
                    [appearance]="formGroup.get('tableForm')?.value[i].isEditable ? 'standard' : 'legacy'"
                    [ngClass]="{'no-border': !formGroup.get('tableForm')?.value[i].isEditable}">
                    <div class="patients-flex-code">
                      <span><</span><!-- #R2-562 left arrow should in same size as right arrow -->
                          <input matInput class="metric-value" type="text" formControlName="low"
                            [ngClass]="formGroup.get('tableForm')?.value[i].isEditable?'patients-show-border':'patients-hide-border' "
                            [readonly]="!formGroup.get('tableForm')?.value[i].isEditable">
                          <input class="patients-code" type="text" formControlName="code" [readonly]="true">
                    </div>
                  </mat-form-field>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('low')?.errors?.['required']">
                    Low value is required
                  </mat-error>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('low')?.errors?.['pattern']">
                    Low value should be an integer
                  </mat-error>
                </div>
              </td>
            </ng-container>
            <!-- high value -->
            <ng-container matColumnDef="highValue">
              <th mat-header-cell *matHeaderCellDef style="text-align: left;">High Value</th>
              <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                <div class="patients-form-field-wrapper">
                  <mat-form-field style="text-align: center;"
                    [appearance]="formGroup.get('tableForm')?.value[i].isEditable ? 'standard' : 'legacy'"
                    [ngClass]="{'no-border': !formGroup.get('tableForm')?.value[i].isEditable}">
                    <div class="patients-flex-code">
                      <span>></span>
                      <input matInput class="metric-value" type="text" formControlName="high"
                        [ngClass]="formGroup.get('tableForm')?.value[i].isEditable?'patients-show-border':'patients-hide-border' "
                        [readonly]="!formGroup.get('tableForm')?.value[i].isEditable">
                      <input class="patients-code" type="text" formControlName="code" [readonly]="true">
                    </div>
                  </mat-form-field>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('high')?.errors?.['required']">
                    High value is required
                  </mat-error>
                  <mat-error class="table-form-error" *ngIf="getTableForm().controls[i]?.get('high')?.errors?.['pattern']">
                    High value should be an integer
                  </mat-error>
                </div>
              </td>
            </ng-container>
            <!-- action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="text-align: right;">Action</th>
              <td mat-cell *matCellDef="let element; let i = index;" align="right" [formGroup]="element">
                <!-- edit -->
                <button class="patients-action-button patients-row-action-button" mat-button
                  *ngIf="!formGroup.get('tableForm')?.value[i].isEditable" (click)="onEditRow(formGroup, i)">
                  <mat-icon class="patients-icon-display">edit</mat-icon>
                </button>
                <div class="patients-row-action-cell">
                  <!-- save -->
                  <button class="patients-action-button patients-row-action-button" mat-button
                    *ngIf="formGroup.get('tableForm')?.value[i].isEditable" (click)="onSaveRow(formGroup, i)">
                    <mat-icon class="patients-icon-display patients-save-button">done</mat-icon>
                  </button>
                  <!-- cancel -->
                  <button class="patients-action-button patients-row-action-button" mat-button
                    *ngIf="formGroup.get('tableForm')?.value[i].isEditable" (click)="onCancelRow(formGroup, i)">
                    <mat-icon class="patients-icon-display patients-cancel-button">close</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-container>
      </section>
    </form>
  </ng-container>

  <mat-dialog-actions [align]="'end'">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button [mat-dialog-close]="false" color="primary"
      class="patients-basic-button empty patients-auto-width" (click)="closeDialog()">Cancel</button>
    <!-- for saving careplan of newly created patient -->
    <button *ngIf="!dialogData.editProgram" mat-raised-button color="primary"
      class="patients-basic-button patients-filled patients-auto-width"
      [disabled]="formGroup.invalid || !presetGoals.length" (click)="closeDialogWithSave()">Next</button>
    <!-- for customizing careplans of patient -->
    <button *ngIf="dialogData.editProgram" mat-raised-button color="primary"
      class="patients-basic-button patients-filled patients-auto-width"
      [disabled]="formGroup.invalid || !presetGoals.length" (click)="saveEditedGoals()">Finish</button>
  </mat-dialog-actions>
</mat-dialog-content>