import { Address, Patient } from "fhir/r4";
import { Diagnosis } from "src/app/models/diagnosis.model";

interface AddressModel {
    city: string;
    state: string;
    postalCode: string;
    country: string;
    addressLine1: string;
    apt: string;
    addressLine2?: string;
}

export class FhirPatientResponse {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public dateOfBirth: string,
        public gender: string,
        public mrn?: string,
        public email?: string,
        public phone?: string,
        public address?: Array<AddressModel>,
        public iHealthEmail?: string,
        public communicationMethod?: string,
        public diagnosis?: Array<Diagnosis>
    ) { }

    public static makeModel(patient: Patient): FhirPatientResponse {
        if (!patient.id) {
            throw new Error("Fhir Patient Response: Patient do not have id");
        }

        if (!patient.name?.[0]?.given?.[0]) {
            throw new Error('Fhir Patient Response: Patient do not have firstName');
        }

        if (!patient.name?.[0]?.family) {
            throw new Error('Fhir Patient Response: Patient do not have last name');
        }

        const emailList = patient.telecom?.filter((item: any) => item.system === "email");
        // #R2-908 Mapping mobile number as phone number & handled if mobile is not available from ocean payload
        let phoneList = patient.telecom?.filter((item: any) => {
            return (item.system === "sms" || item.system === "phone") && item.use === "mobile"
        });
        if (phoneList && phoneList.length === 0) {
            phoneList = patient.telecom?.filter((item: any) => item.system === "sms" || item.system === "phone");
        }

        if (!emailList?.length) {
            throw new Error('Fhir Patient Response: Patient do not have emails');
        }

        if (!phoneList?.length) {
            throw new Error('Fhir Patient Response: Patient do not have mobile numbers');
        }

        const email = emailList?.at(0)?.value;
        console.log("Patient Email:"+email);
        const iHealthEmail = emailList?.at(-1)?.value;
        const phone = phoneList?.[0]?.value;

        let addresses: Array<any> = [];
        if (patient.address?.length) {
            addresses = patient.address.map((add: Address): AddressModel => {
                return {
                    addressLine1: add?.line?.[0] ?? "",
                    addressLine2: add?.line?.[1] ?? "",
                    apt: add?.line?.[2] ?? "",
                    city: add.city ?? "",
                    country: add.country ?? "",
                    postalCode: add?.postalCode ?? "",
                    state: add?.state ?? '',
                }
            });
        }


        return new FhirPatientResponse(
            patient.id,
            patient.name?.[0]?.given?.[0],
            patient.name?.[0]?.family,
            patient.birthDate ?? "",
            patient.gender ?? "",
            patient.identifier?.filter((item: any) => item.type?.coding[0]?.code === "MR" && item.value)?.[0]?.value,
            email,
            phone,
            addresses,
            iHealthEmail,
            phone && phoneList?.[0]?.rank && phoneList?.[0]?.rank == 1 ? 'sms' : 'email'
        );
    }
}