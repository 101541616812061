import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { PatientService } from "../services/patient.service";
import { Observable, catchError, combineLatest, filter, of, switchMap, tap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogData } from "../models/matDialogData.model";
import { DefaultDialogProperties } from "../config/app.config";
import { CarePlanService } from "../services/careplan.service";
import { ShowLoaderService } from "../services/show-loader.service";
import { NotificationService } from "../services/notification.service";
import { CarePlan } from "fhir/r4";

@Injectable({ providedIn: 'root' })
export class PatientAlreadyExistsValidator implements AsyncValidator {
    constructor(
        private patientService: PatientService,
        private dialog: MatDialog,
        private carePlanService: CarePlanService,
        private showLoaderService: ShowLoaderService,
        private notificationService: NotificationService
    ) { }

    validate(
        control: AbstractControl
    ): Observable<ValidationErrors | null> {
        /** Todo: Refactor the code to return a new Observable instead of http call 
         * and handle all the http calls, dialog subscriptions inside it.
         */
        return this.patientService.getPatientByEmail(control.value)
            .pipe(
                switchMap((value: any) => {
                    if (value.entry?.length) {
                        return combineLatest([
                            of(value.entry[0]),
                            this.patientService.getPatientStatuses(value.entry[0].resource.id),
                            this.patientService.getSidecarPatient(control.value)
                        ]);
                    }
                    return combineLatest([
                        of(null),
                        of(null),
                        this.patientService.getSidecarPatient(control.value)
                    ]);
                    // throw new Error('No patient details found.');
                }),
                switchMap(([patient, details, sidecarPatient]) => {
                    // there is no fhir patient resource but sidecar patient does exists 
                    if (!patient && sidecarPatient.length) {
                        return of({
                            patientAlreadyExists: true,
                            email: control.value
                        })
                    }

                    // both fhir patient and sidecar patient does not exists
                    if (!patient) {
                        return of(null);
                    }

                    if (!details) {
                        throw new Error('Program or task for patient not found');
                    }
                    
                    let patientState = '';
                    const { careplans, tasks } = details;

                    const isActive = careplans?.find((careplan: CarePlan) => careplan.status === "active") ? true : false;
                    const isInActive = careplans?.find((careplan: CarePlan) => careplan.status === "completed") ? true : false;
                    const isPending = tasks?.length ? true : false;
                    if (isActive) {
                        patientState = 'active';
                    }

                    if (!isActive && !isPending && isInActive) {
                        patientState = 'inactive';
                    }

                    if (isPending) {
                        patientState = 'pending';
                    }

                    this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                        data: {
                            confirmationHeader: `Account already in use`,
                            confirmationQuestion: `Do you want to continue from ${patientState} state ?`
                        },
                        ...DefaultDialogProperties,
                    })
                        .afterClosed()
                        .pipe(
                            filter((value: boolean) => value),
                            tap(
                                () => {
                                    this.showLoaderService.isShowLoadingSectionVisible$.next(true);
                                    if (isActive) {
                                        /** active patient workflow */
                                        this.carePlanService.editCarePlanForActivePatient$.next(patient.resource);
                                    }
                                    if (!isActive && !isPending && isInActive) {
                                        /** Inactive patient workflow */
                                        this.carePlanService.editCarePlanForInactivePatient$.next(patient.resource);
                                    }
                                    if (isPending) {
                                        /** for pending patient workflow */
                                        /** Check if the response is of type Bundle and has the task resource */
                                        this.carePlanService.editCarePlanForPendingPatient$.next(patient.resource);
                                    }

                                    /** Close add patient dialog */
                                    this.dialog.getDialogById('patient-information-dialog')?.close(false);
                                }
                            ),
                            catchError((error: any) => {
                                console.error(error)
                                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                                this.notificationService.showNotification('Checking email id failed.', 'error');
                                return of({ invalid: true })
                            })
                        )
                        .subscribe();
                    return of({
                        patientAlreadyExists: true,
                        email: control.value
                    })
                }),
                catchError((error: any) => {
                    this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                    /** When adding new user/ patient the email id should not 
                     * have patient details and is ok, so not handling it as an error
                    */
                    if (error.message === 'No patient details found.') {
                        return of(null);
                    }
                    console.error(error)
                    this.notificationService.showNotification('Checking email id failed.', 'error');
                    return of({ invalid: true })
                })
            )
    }
}