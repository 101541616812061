import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[patientAppMaskPhoneNumber]'
})
export class MaskPhoneNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const input = event.target as HTMLInputElement;
    const initialValue = input.value;
    const maskedValue = this.applyMask(initialValue);

    if (maskedValue !== initialValue) {
      input.value = maskedValue;
      event.stopPropagation();
    }
  }

  private applyMask(value: string): string {
    const digitsOnly = value.replace(/\D/g, '');
    const maskedValue = [];

    let count = 0;
    for (let i = 0; i < digitsOnly.length; i++) {
      if (count === 0) {
        maskedValue.push('(');
        maskedValue.push(digitsOnly.charAt(i));
      } else if (count === 2) {
        maskedValue.push(digitsOnly.charAt(i));
        maskedValue.push(') ');
      } else if (count === 5) {
        maskedValue.push(digitsOnly.charAt(i));
        maskedValue.push('-');
      } else {
        maskedValue.push(digitsOnly.charAt(i));
      }
      count++;
    }

    return maskedValue.join('').trim();
  }

}
