import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService extends BaseService {
  constructor(
    private http: HttpClient
  ) {
    super();
  }

  // todo: change implementation for embedding powerbi reports
  getPowerbiReportEmbedToken(): Observable<any> {
    // return this.http.get<any>(`${this.POWERBI_API}?reportId=${reportId}`);
    return this.http.get<any>(`${this.NEW_POWERBI_API}`);
  }
}