import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationComponent } from '../components/notification/notification.component';
import { notificationDuration } from '../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private duration: number = notificationDuration;
  private horizontalPosition: MatSnackBarHorizontalPosition = "right";
  private verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(private snackBar: MatSnackBar) { }

  showNotification(message: string, notificationType: "error" | "success", buttonText?: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message,
        buttonText,
        notificationType
      },
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: notificationType
    })
  }
}
