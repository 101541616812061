import { Bundle, Coding, Condition } from "fhir/r4";
import { Diagnosis } from "../models/diagnosis.model";

/**
 * Maps FHIR condition resource bundle to default diagnosis and 
 * returns list of default conditions corresponding to entries in 
 * FHIR condition resource bundle.
 * @param patientCondition FHIR Condition resource bundle
 * @param defaultDiagnosis List of default diagnosis 
 * @returns Array<Diagnosis> - array of default diagnosis corresponding to FHIR 
 * Condition resource bundle
 */
export function mapFhirConditionsToDefaultDiagnosis(
    patientCondition: Bundle,
    defaultDiagnosis: Array<Diagnosis>
): Array<Diagnosis> {
    if (!patientCondition.entry?.length) {
        return [];
    }

    const fhirDiagnosisList = patientCondition.entry
        .filter((conditionEntry: any) => conditionEntry.resource?.code?.coding)
        .map((conditionEntry: any) => {
            return conditionEntry.resource.code.coding
        });

    if (!fhirDiagnosisList.length) {
        return [];
    }

    /*
        R2-402 - reason: sometimes patient has one or more conditions 
        and the edited values are updated in last index of array, 
        so here picked last index -1
    */
    return fhirDiagnosisList.at(-1)?.map((condition: any) => {
        return defaultDiagnosis.find(diagnosis => diagnosis.code === condition.code) || condition // Jira #R2-390
    })
}

export function ExtractDiagnosisCodesFromConditonAsString(patientCondition: Condition): string[] {
    if (!patientCondition) {
        return [];
    }
    return patientCondition.code?.coding
        ?.map((value: Coding) => value.code || '')
        .filter((value: string) => value !== '')
        || []
}