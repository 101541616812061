import { Moment } from 'moment';
import moment from 'moment';
import { filter, head } from 'lodash';
import {
  Bundle,
  Patient,
  HumanName as FHIRName,
  ContactPoint as FHIRContactPoint,
} from 'fhir/r4';

/**
 * Custom models for powerbi reports
 */
export interface EmbedTokenResponse {
  // reportsDetail: {
  //   reportId: string;
  //   reportName: string;
  //   embedUrl: string;
  // }
  reports: Array<{
    datasetId: string;
    datasetWorkspaceId: string;
    embedUrl: string;
    id: string;
    isFromPbix: boolean;
    isOwnedByMe: boolean;
    name: string;
    reportType: string;
    webUrl: string;
  }>,
  embedToken: {
    token: string;
    tokenId: string;
    expiration: string;
    '@odata.context': string;
  }
}

// angular template
export type TimeSelectionTexts = 'D' | 'W' | 'M' | 'Y' | 'CUSTOM';

// powerbi x axis filter schema values
export type TimeSelectionValues = 'Hour' | 'Day' | 'Week' | 'Month' | 'CUSTOM';

export enum TimeSelectionTypes {
  day = 'Hour',
  week = 'Day',
  month = 'Week',
  year = 'Month',
  custom = 'CUSTOM'
}

export enum PowerbiXAxis {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface ITimeSelection {
  // name: used for powerbi filters
  name: TimeSelectionTexts;
  value: TimeSelectionValues;
  isSelected: boolean;
  startDate: string;
  endDate: string;
  // to set titles of previous/next buttons
  text: 'day' | 'week' | 'month' | 'year';
}

export interface IReport {
  name: string;
  // show as selected by default on load
  isSelected: boolean;
  title: string;
  // hide in chart selection dropdown
  isShownInDropdown: boolean;
}

/**
 * Code Values for the ContactPoint.system field
 * Note: Below enum is not available in fhir/r4
 */
enum ContactPointSystemCodes {
  PHONE = "phone",
  FAX = "fax",
  EMAIL = "email",
  PAGER = "pager",
  URL = "url",
  SMS = "sms",
  OTHER = "other",
}

export class Location {
  constructor(
    public id: string,
    public userCognitoId: string,
    public latitude: number,
    public longitude: number,
    public created: Moment,
    public received: Moment,
    public isAcknowledged: boolean,
    public hasAlert: boolean,
    public isUnknown: boolean = false
  ) { }

  public static fromApi(json: any): Location | undefined {
    if (!json) {
      return undefined;
    }

    return new Location(
      json.id,
      json.userCognitoId,
      json.latitude,
      json.longitude,
      moment(json.created),
      moment(json.received),
      json.isAcknowledged,
      json.hasAlert
    );
  }
};

export class User {
  constructor(
    public firstName: string,
    public lastName: string,
    public cognitoId: string
  ) { }

  public static fromApi(obj: any): User | undefined {
    if (!obj) {
      return undefined;
    }

    return new User(
      obj.firstName,
      obj.lastName,
      obj.cognitoId
    );
  }

  public static fromInternalApi(name: string, cognitoId: string): User {
    return new User(
      name || '',
      '',
      cognitoId || ''
    );
  }
};

export enum AlertStatusType {
  ACTIVE = 'active',
  ACKNOWLEDGED = 'acknowledged',
  UNACKNOWLEDGED = 'unacknowledged',
}

export enum FilterAlertStatusType {
  ACKNOWLEDGED = 'Acknowledged',
  UNACKNOWLEDGED = 'Unacknowledged',
}

export enum FilterAlertType {
  ALL = 'all',
  HEART_RATE = 'pulse',
  PulseOximeter = 'spo2',
  BLOOD_PRESSURE = 'blood_pressure',
  TEMPERATURE = 'body_temperature',
  GLUCOMETER = 'blood_glucose',
  WEIGHT = 'body_weight',
}

export class Alert {
  constructor(
    public id: string,
    public metricType: MetricType,
    public userCognitoId: string,
    public thresholdId: string,
    public metricId: string,
    public dismissedByUser: User | undefined,
    public alertValue: string,
    public created: moment.Moment,
    public received: moment.Moment,
    public updated: moment.Moment,
    public status: AlertStatusType
  ) { }

  public static fromApi(obj: any): Alert {
    return new Alert(
      obj.id,
      obj.metricType,
      obj.userCognitoId,
      obj.thresholdId,
      obj.metricId || '',
      User.fromApi(obj.dismissedByUser),
      obj.alertValue,
      moment(obj.created),
      moment(obj.received),
      moment(obj.updated),
      obj.status
    )
  }
};

export const MIN_DATE = moment([1900, 1, 1]);
export const MAX_OFFLINE_DURATION_MINUTES = 15;

export const defaultMapLatitude = 0;//43.522855;
export const defaultMapLongitude = 0;//-79.655424; //Bayshore NDC

export const REFRESH_INTERVAL = 1000 * 60 * 5; // 1 second x 60 (60 seconds) = 1 minute * 5 = 5 minutes

export const round = (value: any, precision: any) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export enum MetricType {
  ALL = 'all',
  HEART_RATE = 'heart-rate',
  LOCATION = 'location',
  STEP_COUNT = 'step-count',
  BATTERY_STATUS = 'battery-status',
  SPO2 = 'spo2',
  BLOOD_PRESSURE = 'blood-pressure',
  TEMPERATURE = 'temperature',
  GLUCOMETER = 'blood-glucose',
  WEIGHT = 'body-weight'
}

export const getMetricTypeFromString = (metricType: string) => {
  switch (metricType) {
    case 'Heart Rate':
      return MetricType.HEART_RATE;
    case 'Battery Status':
      return MetricType.BATTERY_STATUS;
    case 'Step Count':
      return MetricType.STEP_COUNT;
    case 'Location':
      return MetricType.LOCATION;
    case 'Pulse Oximeter':
      return MetricType.SPO2;
    case 'Blood Pressure':
      return MetricType.BLOOD_PRESSURE;
    case 'Temperature':
      return MetricType.TEMPERATURE;
    case 'Glucometer':
      return MetricType.GLUCOMETER;
    case 'Weight':
      return MetricType.WEIGHT;
    case 'All':
      return MetricType.ALL;
    default:
      return undefined;
  }
};

export const getMetricName = (metricType: MetricType) => {
  switch (metricType) {
    case MetricType.HEART_RATE:
      return 'Heart Rate';
    case MetricType.BATTERY_STATUS:
      return 'Battery Status';
    case MetricType.STEP_COUNT:
      return 'Step Count';
    case MetricType.LOCATION:
      return 'Location';
    case MetricType.SPO2:
      return 'Pulse Oximeter';
    case MetricType.BLOOD_PRESSURE:
      return 'Blood Pressure';
    case MetricType.TEMPERATURE:
      return 'Temperature';
    case MetricType.GLUCOMETER:
      return 'Glucometer';
    case MetricType.WEIGHT:
      return 'Weight';
    case MetricType.ALL:
      return 'All';
    default:
      return 'Not Set';
  }
};

export const getMetricUnit = (metricType: MetricType) => {
  switch (metricType) {
    case MetricType.HEART_RATE:
      return 'bpm';
    case MetricType.BATTERY_STATUS:
      return '%';
    case MetricType.STEP_COUNT:
      return 'steps';
    case MetricType.LOCATION:
      return '';
    case MetricType.SPO2:
      return '% spo2';
    case MetricType.BLOOD_PRESSURE:
      return 'mmhg';
    case MetricType.TEMPERATURE:
      return '°c';
    case MetricType.GLUCOMETER:
      return 'mmol/L';
    case MetricType.WEIGHT:
      return 'lbs';
    default:
      throw Error('No metric unit found');
  }
};

export const getMetricIcon = (metricType: MetricType) => {
  switch (metricType) {
    case MetricType.HEART_RATE:
      return 'heart-small.svg';
    case MetricType.BATTERY_STATUS:
      return 'battery-small.svg';
    case MetricType.STEP_COUNT:
      return 'stepcount-small.svg';
    case MetricType.LOCATION:
      return 'location24.svg';
    case MetricType.SPO2:
      return 'oximeter24.svg';
    case MetricType.BLOOD_PRESSURE:
      return 'bloodpressure24.svg';
    case MetricType.TEMPERATURE:
      return 'temperature24.svg';
    case MetricType.GLUCOMETER:
      return 'glucometer-icon.png';
    case MetricType.WEIGHT:
      return 'weight-icon.png';
    default:
      throw Error('No metric icon found');
  }
};

export const getMetricActualDbValue = (metricType: string) => {
  switch (metricType) {
    case MetricType.HEART_RATE:
      return 'pulse';
    case MetricType.SPO2:
      return 'spo2';
    case MetricType.BLOOD_PRESSURE:
      return 'blood_pressure';
    case MetricType.TEMPERATURE:
      return 'body_temperature';
    case MetricType.GLUCOMETER:
      return 'blood_glucose';
    case MetricType.WEIGHT:
      return 'body_weight';
    default:
      throw Error('No metric actual db value found');
  }
};

export class CustomThresholds {
  constructor(
    public uuid: string,
    public metricName: string,
    public min: number,
    public max: number,
    public unit: string,
    public isDefault?: boolean
  ) { }

  static fromApi(json: any) {
    if (!json) {
      return undefined;
    }
    return new CustomThresholds(
      json.uuid,
      json.metricName,
      json.min,
      json.max,
      json.unit
    );
  }
}

export enum PatientStatusType {
  UNKNOWN = 'unknown',
  NORMAL = 'normal',
  NEED_ACTION = 'need_action',
  ACKNOWLEDGED = 'acknowledged',
  ACKNOWLEDGE = 'acknowledge',
  UNACKNOWLEDGED = 'unacknowledged',
}

export const getPatientStatusFromString = (
  status: string
): PatientStatusType => {
  // let patientStatus: PatientStatusType = PatientStatusType.UNKNOWN;

  switch (status) {
    case PatientStatusType.NORMAL.toLowerCase():
      return PatientStatusType.NORMAL;
    case PatientStatusType.NEED_ACTION.toLowerCase():
      return PatientStatusType.NEED_ACTION;
    case PatientStatusType.ACKNOWLEDGED.toLowerCase():
      return PatientStatusType.ACKNOWLEDGED;
    default:
      return PatientStatusType.UNKNOWN;
  }
  // return patientStatus;
};

export const getStringFromPatientStatus = (
  patientStatus: PatientStatusType
): string => {
  let status: string = '';

  if (patientStatus === PatientStatusType.NORMAL) {
    status = 'Normal';
  } else if (patientStatus === PatientStatusType.NEED_ACTION) {
    status = 'Needs Action';
  } else if (patientStatus === PatientStatusType.ACKNOWLEDGED) {
    status = 'Acknowledged';
  } else if (patientStatus === PatientStatusType.UNKNOWN) {
    status = 'Unknown';
  }

  return status;
};

export class MetricStat {
  constructor(
    public id: string,
    public metricType: MetricType,
    public userCognitoId: string,
    public value: string,
    public unit: string,
    public created: Moment,
    public received: Moment,
    public isAcknowledged: boolean,
    public hasAlert: boolean,
    public upperValue?: string
  ) { }

  public static fromApi(json: any, metricType?: MetricType): MetricStat | undefined {
    if (!json || Number(json.value) === -1) {
      return undefined;
    }

    return new MetricStat(
      json.id,
      json.metricType || metricType,
      json.userCognitoId,
      round(Number(json.value), 1).toString(),
      json.unit,
      moment(json.created || json.startTime),
      moment(json.received),
      json.isAcknowledged,
      json.hasAlert,
      json.upperValue ? round(Number(json.upperValue), 1).toString() : undefined,
    );
  }
}

export class Statistics {
  constructor(
    public metrics: MetricStat[],
    public locations: Location[],
    public alerts: Alert[]
  ) { }

  public static fromApi(json: any): Statistics {
    let stats: Statistics;
    return new Statistics(
      json.metrics?.map((m: any) => MetricStat.fromApi(m)).filter((e: any) => e),
      json.locations?.map((l: any) => Location.fromApi(l)),
      []
    );
  }


};

export class Diagnosis {
  constructor(
    public type: string,
    public subtype: string
  ) { }
}

export class CustomPatient {
  public get diagnosesString(): string { return this.diagnoses?.map(d => d.type).join(', ') };
  public patientId!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public diagnoses!: Diagnosis[];
  public peopleSoftId!: string;
  public branchInfo!: any;
  public clientType!: string;
  public dateOfBirth!: string;
  public height!: number;
  public weight!: number;
  public marketplaceUrl!: string;
  public gender!: string;
  public status!: PatientStatusType;
  public program!: string;
  public latestStats?: Statistics;
  public customThresholds?: CustomThresholds[];
  public dateLastUpdated!: Date;
  public validicPatientId!: string;
  private static readonly VALIDIC_SYSTEM_NAME = 'https://api.v2.validic.com';

  public static empty() {
    return new CustomPatient();
  }

  static fromBundle(bundle: Bundle): Patient[] | undefined {
    if (!bundle) {
      return undefined;
    }

    if (!bundle?.entry?.length) {
      return [];
    }

    return bundle.entry.map((bundleEntry: any) => this.fromApi(bundleEntry.resource as Patient));
  }

  static fromApi(fhirPatient: Patient): Patient {
    const patient: any = new CustomPatient();
    patient.patientId = fhirPatient.id;

    const humanName: any = this.getPrimaryName(fhirPatient.name);
    patient.firstName = humanName.given?.join(" ");
    patient.lastName = humanName.family;
    patient.gender = fhirPatient.gender;
    patient.dateOfBirth = fhirPatient.birthDate;
    patient.email = this.getEmailAddress(fhirPatient.telecom);
    patient.dateLastUpdated = new Date((fhirPatient as any)?.meta?.lastUpdated);

    // These are N/A in the old system.
    patient.clientType = 'N/A';
    patient.branchInfo = 'N/A';
    patient.peopleSoftId = 'N/A';

    //TODO - These are coming from observations, which are coming from Synapse
    //json.diagnoses.map(d => new Diagnosis(d.type || "", d.subtype || "")) // TODO - will need to go fish for conditions
    //patient.height,
    //patient.weight,
    //patient.latestStats // These will come from observations

    //TODO - This value comes from Alerts, which are coming later
    patient.status = PatientStatusType.NORMAL; // TODO - Set the Patient Status
    //patient.customThresholds

    //TODO - Get the program setup in details
    patient.program = 'khsc_rpm';

    // TODO - This is related to the Validic Setup. Need to find a map for this field.
    patient.validicPatientId = this.getValidicPatientId(fhirPatient);
    //patient.marketplaceUrl,

    return patient;
  }

  private static getPrimaryName(names: FHIRName[] | undefined): FHIRName | undefined {
    if (!names) { return undefined; }
    if (names.length == 0) { return undefined; }

    // Grab the official name or the first name if that doesn't come back.
    return names.filter(x => x.use === "official")[0] ?? names[0];
  }

  private static getEmailAddress(contacts: FHIRContactPoint[] | undefined): string | undefined {
    if (!contacts) { return undefined; }

    var emails = contacts.filter(x => x.system === ContactPointSystemCodes.EMAIL);
    if (emails.length === 0) { return undefined; }
    return emails[0].value;
  }

  private static getValidicPatientId(fhirPatient: Patient): string | null {
    if (!fhirPatient?.identifier || !fhirPatient?.identifier.length) {
      return null;
    }
    return head(
      filter(fhirPatient.identifier, (identifier: any) => identifier.system === this.VALIDIC_SYSTEM_NAME && !!identifier.value)
    )?.value || null;
  }
}