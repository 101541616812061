import moment from 'moment';
import { Coding, Reference, Task } from "fhir/r4";

import { AcknowledgedTaskParams, AlertType } from "../models/task.model";
import { AuthoredOnFormat, AlertTimeFormat, AlertTypeIcons, RPMDateFormat, EstTimezone, GoalCodes, GoalTitles } from "../config/app.config";
import { assetUrl } from 'src/single-spa/asset-url';
import { AlertHistory } from '../models/patient.model';


export const MapTasksToAlertListing = (tasks: Task[], alertTypes: AlertType[]): AlertHistory[] => {
  if (!tasks.length) {
    return [];
  }
  // todo: remove filtering when tasks are migrated to new payload
  tasks = tasks.filter((task: Task) => !!task?.input);
  return tasks
    .map((task: Task) => {
      // tasks props
      const { id: taskId, basedOn, authoredOn, businessStatus, description: taskDescription, code, input = [], executionPeriod = {} } = task;
      // observation id
      const observationReference = basedOn?.find((ref: Reference) => ref.reference?.includes('Observation'));
      const noObservationError = new Error('Observation id not available in Task resource');
      if (!observationReference) {
        throw noObservationError;
      }
      const observationId = observationReference.reference?.split('/')[1];
      if (!observationId) {
        throw noObservationError;
      }
      // goal code
      const noGoalCodingError = new Error('Goal code not available in Task resource');
      const alertCoding = code?.coding && code?.coding[0];
      if (!alertCoding) {
        throw noGoalCodingError;
      }
      const { code: alertCode = '', display: alertDisplay } = alertCoding;
      if (!alertCode) {
        throw noGoalCodingError;
      }
      // #R2-647 - displaying alert type from alertType list
      let alertType = alertTypes.find(at => at.goalCode === alertCode)?.goalTitle ?? alertDisplay;
      const inputCoding = input?.[0].type.coding ?? null;
      const noInputCodingError = new Error('Input coding not available in the Task resource');
      if (!inputCoding) {
        throw noInputCodingError;
      }
      const inputSystem = inputCoding.find((systems: any) => systems.system === 'Reported Value');
      if (!inputSystem) {
        throw noInputCodingError;
      }
      // Split display string to extract reportedValue & alertUnit
      // Blood Pressure display string: "143 - 55 mmHg"
      // Weight display string: "170 lbs"
      // Heart Rate display string: "59 bpm"
      const { display }: Coding = inputSystem;
      const reportedValueWithAlertUnit = display?.split(' ');
      const [reportedValue, alertUnit] = reportedValueWithAlertUnit?.length === 2
        ? reportedValueWithAlertUnit
        : reportedValueWithAlertUnit?.length === 4 ? [reportedValueWithAlertUnit.at(0), reportedValueWithAlertUnit.slice(1).join(" ")] : ['', ''];
      if (!alertUnit || !reportedValue) {
        throw noInputCodingError;
      }
       // #R2-647 - handled blood pleasure alert combination for alertType
      if (alertCode === GoalCodes.BLOOD_PRESSURE) {
        alertType = GoalTitles.BLOOD_PRESSURE;
      }
      const alertDate = moment(authoredOn).format(RPMDateFormat);
      const alertTime = moment(authoredOn).tz(EstTimezone).format(AlertTimeFormat);
      const alertStatus = businessStatus?.text
        ? businessStatus.text
        : '';
      const today = moment();
      const daysPassed = moment.duration(today.diff(moment(authoredOn)));
      const days = Math.floor(daysPassed.asDays());
      const months = Math.floor(daysPassed.asMonths());
      const alertDuration = days > 31
        ? `${months} month${months > 1 ? 's' : ''}`
        : `${days} day${days > 1 ? 's' : ''}`;
      // dynamic path to alert icon in assets folder based on unique code
      const alertIcon = assetUrl(`${AlertTypeIcons[alertCode].icon}.jpg`);
      if (!input.length) {
        throw new Error('Input missing in new Task resource');
      }
      const dateAcknowledged = task?.note?.length
        ? moment(task.note[task.note.length - 1].time).tz(EstTimezone).format(RPMDateFormat)
        : '';
      return {
        // tasks props
        taskId,
        // time when clinician added acknowledged an alert for the first time
        authoredOn,
        alertDate,
        // #R2-526: alert time should be EST timezone
        alertTime,
        alertStatus,
        alertDuration,
        taskDescription,
        alertUnit,
        alertType,
        alertIcon,
        reportedValue: +reportedValue,
        note: task?.note ?? [],
        // code, input - used for filtering & acknowledged payload mapping
        code,
        input,
        dateAcknowledged,
        acknowledgedBy: task?.note?.length
          ? task.note[0].authorString
          : '',
        // observation id taken from new Task resource
        // used for mapping observation id in Task resource when updating acknowledgement status
        observationId,
        executionPeriod,
      }
    })
    .filter(Boolean) as AlertHistory[];
}

export const MapTaskResourceToAcknowledged = (
  { 
    taskId, 
    patientId,
    carePlanId,
    observationId,
    newComment,
    loggedInUsername,
    taskDescription,
    oldNote,
    code,
    input,
    authoredOn,
    isAddComment = false,
    executionPeriodEndDate = ''
  }: AcknowledgedTaskParams
): Task => {
  const currentDateTime = moment.utc().format(AuthoredOnFormat);
  if (isAddComment && !executionPeriodEndDate) {
    throw new Error('Execution end date not available for alert');
  }
  return {
    resourceType: "Task",
    id: taskId,
    basedOn: [
      {
        reference: `CarePlan/${carePlanId}`
      },
      {
        reference: `Observation/${observationId}`
      }
    ],
    status: "completed",
    statusReason: {
      text: "patients Observation Alert Acknowledged"
    },
    businessStatus: {
      text: "acknowledged"
    },
    intent: "order",
    executionPeriod: {
      start: authoredOn,
      end: isAddComment ? executionPeriodEndDate : currentDateTime
    },
    authoredOn,
    description: taskDescription,
    note: [
      ...oldNote ?? [],
      {
        text: newComment,
        authorString: loggedInUsername,
        time: currentDateTime,
      }
    ],
    lastModified: currentDateTime,
    for: {
      reference: `Patient/${patientId}`
    },
    // add code, input[] from new Task resource
    code,
    input,
  };
}