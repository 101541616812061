import moment from 'moment';
import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, debounceTime, filter, forkJoin, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { CommunicationMethods, MinQueryLengthToFireSearch, ProvincesInCanada, RPMDateFormat, SearchDebounceTime, spinnerProperties } from 'src/app/config/app.config';
import { Diagnosis } from 'src/app/models/diagnosis.model';
import { PatientInformationDialogData } from 'src/app/models/matDialogData.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { FormService } from 'src/app/services/form.service';
import { DropdownErrorStateMatcher } from 'src/app/shared/error-state-matchers/dropdown-error-state-matcher';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { uniqBy } from 'lodash';

@Component({
  selector: 'patient-app-patient-information-dialog',
  templateUrl: './patient-information-dialog.component.html',
  styleUrls: ['./patient-information-dialog.component.scss']
})
export class PatientInformationDialogComponent implements OnInit, OnDestroy {
  @ViewChild('picker1') picker1!: MatDatepicker<Date>;
  private unsubscribe$ = new Subject<void>();
  patientInformationFormGroup!: FormGroup;
  diagnosis!: Diagnosis[];
  communicationMethods = CommunicationMethods;
  provincesInCanada = ProvincesInCanada;
  maxDate: string;
  isDispatchingEditedData = false;// Jira #R2-389

  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;
  matcher = new DropdownErrorStateMatcher();
  // spinner Properties like diameter and stroke width
  defaultSpinnerProperties = { ...spinnerProperties, diameter: 15 };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: PatientInformationDialogData,
    private dialogRef: MatDialogRef<PatientInformationDialogComponent>,
    private diagnosisService: DiagnosisService,
    private formService: FormService,
    private breakpointService: BreakpointService
  ) {
    this.maxDate = moment().subtract(18, 'years').format(RPMDateFormat);
  }

  ngOnInit(): void {
    this.diagnosis = this.dialogData.diagnosis?.length ? this.filterUniqueDiagnosis(this.dialogData.diagnosis) : [];
    this.patientInformationFormGroup = this.formService.generatePatientProfileForm();
    // listen to diagnosis filtering
    this.patientInformationFormGroup.get('diagnosisFilterControl')?.valueChanges
      .pipe(
        // search.length === 0 for clearing search result and render default list
        // when search input query is cleared
        filter((search: string) => search.length === 0 || !!search && search.length > MinQueryLengthToFireSearch),
        debounceTime(SearchDebounceTime),
        switchMap((search: string) => {
          if (search.length) {
            return this.diagnosisService.searchDiagnosis(search);
          }
          // the search has been cleared reset to default value list
          // to keep the value selected from search previously before this search in the dropdown
          const previousValuesSelectedFromSearch = this.patientInformationFormGroup.get('diagnosis')?.value || [];
          const nonDefaultDiagnosis = previousValuesSelectedFromSearch.length
            ?
            this.diagnosisService.getNonDefaultDiagnosisByCodes(previousValuesSelectedFromSearch)
            : of([]);

          // the search has been cleared reset to default value list
          return forkJoin([
            this.diagnosisService.getDefaultDiagnosis(),
            of(this.dialogData.diagnosis),
            nonDefaultDiagnosis
          ])
            .pipe(
              map(
                (value: Array<any>) => this.filterUniqueDiagnosis([
                  // #R2-587 - Removed search result diagnosis and fetched selected & default diagnosis.
                  ...value?.[2] || [],
                  ...value?.[0] || [],
                  ...value?.[1] || []
                ])
              )
            )
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((diagnosis: Diagnosis[]) => {
        this.diagnosis = diagnosis;
      });

    // if modal opened cause of API error, reload previous form values
    if (this.dialogData?.previousFormValue) {
      this.patientInformationFormGroup.patchValue({ ...this.dialogData.previousFormValue });
    }

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  filterUniqueDiagnosis = (diagnosis: Array<Diagnosis>) => {
    return diagnosis.reduce(
      // filter unique diagnosis to avoid duplicate diagnosis in dropdown
      (prev, next) => {
        const codeExists = prev.find((item: any) => item?.code === next?.code);
        if (codeExists) {
          return prev;
        }
        return [...prev, next];
      }, [] as Diagnosis[]
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  closeDialogWithSave(): void {// Jira #R2-389
    const patientDetials: any = this.patientInformationFormGroup.value;
    var defaultMappedDiagnosis: Diagnosis[] = [], nonDefaultDiagnosis: string[] = [];
    if (patientDetials.diagnosis && patientDetials.diagnosis.length > 0) {
      defaultMappedDiagnosis = [...new Set(patientDetials.diagnosis as string[])].map(code => {
        const diagnosis = this.diagnosis.find(d => d.code === code);
        if (!diagnosis) nonDefaultDiagnosis.push(code);
        return diagnosis!;
      }).filter(d => d != undefined);
    }

    if (defaultMappedDiagnosis.length > 0) patientDetials.diagnosis = defaultMappedDiagnosis;
    if (nonDefaultDiagnosis.length > 0) {
      this.isDispatchingEditedData = true;
      this.diagnosisService.getNonDefaultDiagnosisByCodes(nonDefaultDiagnosis)
        .pipe(
          map(diagnosis => {
            return [...defaultMappedDiagnosis, ...diagnosis];
          }),
          catchError((err) => {
            this.isDispatchingEditedData = false;
            return [];
          })
        )
        .subscribe(diagnosis => {
          this.isDispatchingEditedData = false;
          if (diagnosis.length > 0) patientDetials.diagnosis = diagnosis;
          this.dialogRef.close({
            ...patientDetials,
            dateOfBirth: moment(this.patientInformationFormGroup.controls['dateOfBirth'].value).format(RPMDateFormat),
          });
        })
    } else {
      this.dialogRef.close({
        ...patientDetials,
        dateOfBirth: moment(this.patientInformationFormGroup.controls['dateOfBirth'].value).format(RPMDateFormat),
      });
    }
  }

  openDatePicker(): void {
    this.picker1.open();
  }
}
