import { NgModule } from '@angular/core';

import { PatientOverviewRoutingModule } from './patient-overview-routing.module';
import { SummaryComponent } from './components/summary/summary.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PatientOverviewComponent } from './patient-overview.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { SharedModule } from '../shared/shared.module';
import { AcknowledgeDialogComponent } from './components/acknowledge-dialog/acknowledge-dialog.component';
import { AddCommentsDialogComponent } from './components/add-comments-dialog/add-comments-dialog.component';


@NgModule({
  declarations: [
    SummaryComponent,
    ProfileComponent,
    PatientOverviewComponent,
    EditProfileComponent,
    AcknowledgeDialogComponent,
    AddCommentsDialogComponent
  ],
  imports: [
    PatientOverviewRoutingModule,
    SharedModule,
  ]
})
export class PatientOverviewModule { }
