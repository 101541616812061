import moment from 'moment';
import * as powerbiModels from 'powerbi-models';
import * as pbi from 'powerbi-client';
import { orderBy } from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Subject, combineLatest, interval, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PowerBIService } from 'src/app/services/power-bi.service';
import { IReport, ITimeSelection, TimeSelectionTypes, TimeSelectionValues, EmbedTokenResponse, PowerbiXAxis } from 'src/app/models/powerbi.model';
import { ActivatedRoute } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { ShowLoaderService } from 'src/app/services/show-loader.service';
import { FhirResourceType, RPMDateFormat } from 'src/app/config/app.config';
import { CarePlanService } from 'src/app/services/careplan.service';
import { Bundle, CarePlan, Goal } from 'fhir/r4';
import { MapBundleToResourceArrays } from 'src/app/mappers/shared.mapper';
import { showSidebar$ } from '@bayshoreHealthCare/store';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'patient-app-patient-charts',
  templateUrl: './patient-charts.component.html',
  styleUrls: ['./patient-charts.component.scss']
})
export class PatientChartsComponent implements OnInit, OnDestroy {
  @ViewChild('picker') dateRangePicker: any;
  @ViewChild('singleReportContainer') singleReportContainer!: ElementRef;
  @ViewChild('chartSelection') chartSelection!: ElementRef;
  @ViewChild('layoutSelection') layoutSelection!: ElementRef;
  timeSelections: ITimeSelection[] = [
    { name: 'D', isSelected: false, startDate: '', endDate: '', value: 'Hour', text: 'day' },
    { name: 'W', isSelected: true, startDate: '', endDate: '', value: 'Day', text: 'week' },
    { name: 'M', isSelected: false, startDate: '', endDate: '', value: 'Week', text: 'month' },
    { name: 'Y', isSelected: false, startDate: '', endDate: '', value: 'Month', text: 'year' },
    { name: 'CUSTOM', isSelected: false, startDate: '', endDate: '', value: 'CUSTOM', text: 'day' },
  ];
  chartLayouts = [
    { text: '1 report per row', value: 1 },
    { text: '2 reports per row', value: 2 },
    { text: '3 reports per row', value: 3 },
    { text: '4 reports per row', value: 4 },
  ];
  allReports: IReport[] = [];
  isChartSelectionDropdownVisible = false;
  isLayoutSelectionDropdownVisible = false;
  selectedLayout = 2;
  isHeartRateReportLoaded = false;
  isBloodPressureReportLoaded = false;
  isBloodSugarReportLoaded = false;
  isWeightReportLoaded = false;
  patientId!: string;
  patientStatus!: string;
  startDate!: string;
  endDate!: string;
  private unsubscribe$ = new Subject<void>();
  private updatePowerbiReports$ = new Subject<void>();
  // default filters to be applied on component load
  private patientFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Pbi DimPatient',
      column: 'id'
    },
    filterType: powerbiModels.FilterType.Basic,
    operator: 'Is',
    values: [] as any
  };
  private datesFilter: powerbiModels.IAdvancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "DimDate",
      column: "Date"
    },
    filterType: powerbiModels.FilterType.Advanced,
    logicalOperator: "And",
    conditions: [],
  };
  private xAxisFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'XAxis',
      column: 'XAxis'
    },
    filterType: powerbiModels.FilterType.Basic,
    operator: 'Is',
    values: [] as any
  };
  pageNameSalesBreakdown = 'ReportSection244e6dec56462c6e5b09';
  singleReportConfig = {
    tokenType: powerbiModels.TokenType.Embed,
    type: 'report',
    permissions: powerbiModels.Permissions.Read,
    embedUrl: null,
    accessToken: null,
    pageName: this.pageNameSalesBreakdown,
    settings: {
      panes: {
        filters: { visible: false, expanded: true },
        pageNavigation: { visible: false }
      }
    }
  };
  singleReport!: pbi.Report;
  displayModeVisible = { mode: powerbiModels.VisualContainerDisplayMode.Visible }
  displayModeHidden = { mode: powerbiModels.VisualContainerDisplayMode.Hidden }
  isChartError = false;
  activePatientGoals!: string[];
  visualsPerRowState: any = {
    one: {
      methodName: 'oneVisualPerRow',
      value: 'one',
      numeric: 1,
    },
    two: {
      methodName: 'twoVisualsPerRow',
      value: 'two',
      numeric: 2,
    },
    three: {
      methodName: 'threeVisualsPerRow',
      value: 'three',
      numeric: 3,
    },
    four: {
      methodName: 'fourVisualsPerRow',
      value: 'four',
      numeric: 4,
    }
  };
  isSidebarVisible = false;
  loggedInUsername!: string;
  selectedTimeline!: ITimeSelection;
  // set to true since the default timeline view is week
  isDisableNextButton = true;
  isDisablePreviousButton = false;
  today!: string;
  isCustomDatesApplied = false;
  // R2-1094: set start date for datepicker based on patient's careplan start date
  carePlanStartDate!: string;

  // resets powerbi iframe height on resize events
  @HostListener('window:resize')
  onResize() {
    this.setPowerbiIframeHeight();
  }

  // hide chart/layout selection dropdowns on clicking outside
  @HostListener('document:click', ['$event'])
  bodyClickEvents(event: Event) {
    if (this.isChartSelectionDropdownVisible) {
      if (!this.chartSelection.nativeElement.contains(event.target)) {
        this.isChartSelectionDropdownVisible = false;
      }
    }
    if (this.isLayoutSelectionDropdownVisible) {
      if (!this.layoutSelection.nativeElement.contains(event.target)) {
        this.isLayoutSelectionDropdownVisible = false;
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private powerBiService: PowerBIService,
    private showLoaderService: ShowLoaderService,
    private renderer: Renderer2,
    private carePlanService: CarePlanService,
    private authService: MsalService,
  ) { }

  ngOnInit(): void {
    // R2-1094
    this.carePlanService.patientCarePlans$
      .subscribe((carePlans: CarePlan[]) => {
        const startDates = carePlans.map((carePlan: CarePlan) => {
          if (!carePlan?.period?.start) {
            throw new Error(`${carePlan.title} CarePlan\'s start date is not accessible`);
          }
          return moment(carePlan.period.start);
        });
        this.carePlanStartDate = moment.min(startDates).format(RPMDateFormat);
      });
    
    // get logged in user's name
    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      const account = accounts[0];
      this.authService.instance.setActiveAccount(accounts[0]);
      const userProfile: any = account.idTokenClaims;
      this.loggedInUsername = `${userProfile?.given_name}${userProfile?.family_name}`.toLowerCase().trim();
    }

    // set start and end dates of timeSelections - day, week, month, year
    this.today = moment(new Date()).format(RPMDateFormat);
    this.timeSelections = this.timeSelections.map(selection => ({
      ...selection,
      startDate: this.getTimelineStartDate(selection.value, this.today),
      endDate: this.today,
    }));

    // set start date and end date for date range picker - by default show data between last sunday to today
    // R2-1089: week view's start & end dates should always be sunday & saturday respectively
    this.startDate = moment(this.timeSelections[1].startDate).format(RPMDateFormat);
    this.endDate = moment(this.today).format(RPMDateFormat);

    // set default week view for selected timeline
    this.selectedTimeline = this.timeSelections[1];

    this.xAxisFilter = {
      ...this.xAxisFilter,
      values: ['Day'],
    }
    this.datesFilter = {
      ...this.datesFilter,
      conditions: [
        {
          operator: "GreaterThanOrEqual",
          value: moment(this.startDate).startOf('day').toISOString(),
        },
        {
          operator: "LessThan",
          value: moment(this.endDate).endOf('day').toISOString(),
        }
      ]
    };

    this.showLoaderService.isShowLoadingSectionVisible$.next(true);
    this.route.params
      .pipe(
        switchMap((params: any) => {
          if (!params.id) {
            throw new Error('Invalid Patient Id');
          }
          this.patientId = params.id;
          // #R2-849 if the patient discharged at that time we have to use status completed
          // #R2-1116 - handled route without status if the user navigate from dashboard & email alert notication due to #R2-849
          this.patientStatus = (params.status && params.status === 'inactive') ? 'completed' : 'active';
          this.patientFilter = {
            ...this.patientFilter,
            values: [this.patientId],
          };
          return of(params.id);
        }),
        switchMap((patientId: string) => combineLatest([
          // #R2-849 fetch active goals associated with active careplan or completed careplan for selected patient
          this.carePlanService.getPatientCarePlanGoals(patientId, this.patientStatus),
          this.powerBiService.getPowerbiReportEmbedToken(),
        ])),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: ([bundle, embedTokenResponse]: [Bundle<CarePlan | Goal>, EmbedTokenResponse]) => {
          const { resource1: goals } = MapBundleToResourceArrays<Goal, CarePlan>(bundle, FhirResourceType.Goal);
          // active goals of selected patient: goal title in lowercase used to set active reports in chart selection dropdown
          // note: blood pressure systolic & blood pressure diastolic values are shown in the same report
          const activePatientGoals = goals
            .map((goal: Goal) => {
              const goalName = (goal?.description?.text ?? '').trim().toLowerCase();
              if (!goalName) {
                return '';
              }
              if (goalName.includes('systolic') || goalName.includes('diastolic')) {
                return 'blood pressure';
              }
              return goalName;
            })
            .filter(Boolean);
          this.activePatientGoals = [...new Set(activePatientGoals)];
          const { id = '', embedUrl = '' } = embedTokenResponse.reports[0];
          if (!id || !embedUrl) {
            this.isChartError = true;
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            throw new Error('Id/embedUrl not available in powerbi embed response');
          }
          const config = {
            ...this.singleReportConfig,
            id,
            embedUrl,
            accessToken: embedTokenResponse.embedToken.token,
            filters: [this.patientFilter, this.datesFilter, this.xAxisFilter],
          };
          const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
          this.singleReport = <pbi.Report>powerbi.embed(this.singleReportContainer.nativeElement, config);
          this.singleReport.on('loaded', async () => {
            const pages = await this.singleReport.getPages();
            const visuals = await pages[0].getVisuals();
            // chart selection dropdown will only have active goals for selected patient
            // active patient goals set on top for custom layout
            const visualsWithActiveGoalsOnTop = orderBy(visuals, (visual: any) => !this.activePatientGoals.includes(visual.title.trim().toLowerCase()), ['asc'])
            this.allReports = visualsWithActiveGoalsOnTop
              .map((visual: any) => ({
                title: visual.title,
                name: visual.name,
                // show as selected by default on load
                isSelected: this.activePatientGoals.includes(visual.title.trim().toLowerCase()),
                // hide in chart selection dropdown
                isShownInDropdown: this.activePatientGoals.includes(visual.title.trim().toLowerCase()),
              }));
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);

            showSidebar$
              .pipe(
                takeUntil(this.unsubscribe$)
              )
              .subscribe((sidebarVisible: boolean) => {
                this.isSidebarVisible = sidebarVisible;
                this.setPowerbiIframeHeight();
              })
            this.setPowerbiIframeHeight();
          });
        },
        error: () => {
          this.isChartError = true;
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        }
      });

    this.updatePowerbiReports$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(async () => {
        console.log({
          date: this.datesFilter,
          xAxis: this.xAxisFilter,
          patient: this.patientFilter,
        });
        await this.singleReport.updateFilters(powerbiModels.FiltersOperations.ReplaceAll, [
          this.patientFilter,
          this.datesFilter,
          this.xAxisFilter
        ]);
      });

    // refresh powerbi report in 2 minute intervals
    // refresh event only updates the data while preserving the existing visualizations and report layout
    interval(120000)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.singleReport.refresh());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setPowerbiIframeHeight(): void {
    // set visuals per row by default or from local storage
    const visualsPerRow = localStorage.getItem('visualsPerRow');
    if (!visualsPerRow) {
      // set 3 reports per row by default
      const persistDefaultUserSelection = {
        [this.loggedInUsername]: this.visualsPerRowState.three.value
      };
      localStorage.setItem('visualsPerRow', JSON.stringify(persistDefaultUserSelection));
      this.threeVisualsPerRow();
      return;
    }

    // set dropdown value from local storage & call appropriate method for visuals per row
    const userSelections = JSON.parse(visualsPerRow);
    const loggedInUserSelection = userSelections[this.loggedInUsername];
    const { methodName, numeric } = this.visualsPerRowState[loggedInUserSelection ?? 'three'];
    this.selectedLayout = numeric;
    (this as any)[methodName]();
  }

  private getTimelineStartDate(selectionName: string, today: string): string {
    switch (selectionName) {
      case TimeSelectionTypes.day:
        return this.getDayStartTime(today);
      case TimeSelectionTypes.week:
        return this.getWeekStartDate(today);
      case TimeSelectionTypes.month:
        return this.getMonthStartDate(today);
      case TimeSelectionTypes.year:
        return this.getYearStartDate(today);
      default:
        return '';
    }
  }

  private getDayStartTime(today: string): string {
    return moment(today).startOf('day').format(RPMDateFormat);
  }

  private getWeekStartDate(today: string): string {
    const momentToday = moment(today);
    const daysUntilLastSunday = (momentToday.day() + 7) % 7;
    return momentToday.subtract(daysUntilLastSunday, 'days').format(RPMDateFormat);
  }

  private getMonthStartDate(today: string): string {
    return moment(today).startOf('month').format(RPMDateFormat);
  }

  private getYearStartDate(today: string): string {
    return moment(today).startOf('year').format(RPMDateFormat);
  }

  updateSelectedReports(startDate: string, endDate: string, timeSelectionLiteral: TimeSelectionValues): void {
    this.xAxisFilter = {
      ...this.xAxisFilter,
      // for custom dates, calculate difference between start and end dates and set x axis value accordingly
      values: [
        timeSelectionLiteral === TimeSelectionTypes.custom
          ? PowerbiXAxis.DAY
          : timeSelectionLiteral
      ],
    };
    // build powerbi date filters
    this.datesFilter = {
      ...this.datesFilter,
      conditions: [
        {
          operator: "GreaterThanOrEqual",
          value: moment(startDate).toISOString(),
        },
        {
          operator: "LessThan",
          value: moment(endDate).add(1, 'day').toISOString(),
        }
      ]
    };
    this.updatePowerbiReports$.next();
  }

  onReportsApply(): void {
    this.isChartSelectionDropdownVisible = false;
    this.layoutSelectionChanged({ value: this.selectedLayout } as MatRadioChange);
    const selectedTimeline = this.timeSelections.find(selection => selection.isSelected);
    if (!selectedTimeline) {
      throw new Error('No timeline selected when report is applied');
    }
    this.updateSelectedReports(selectedTimeline.startDate, selectedTimeline.endDate, selectedTimeline.value);
  }

  onTimelineSelected(selectedType: TimeSelectionValues): void {
    this.timeSelections.forEach(selection => {
      if (selection.value === selectedType) selection.isSelected = true;
      else selection.isSelected = false;
    });
    const selectedTimeline = this.timeSelections.find(selection => selection.isSelected);
    if (!selectedTimeline) {
      throw new Error('No timeline selected when report is applied');
    }
    if (selectedTimeline) {
      this.selectedTimeline = selectedTimeline;
      const { startDate, endDate, value } = selectedTimeline;
      // for disabling previous/next button based on future date or custom dates being applied
      this.isDisableNextButton = true;
      this.isDisablePreviousButton = false;
      this.isCustomDatesApplied = value === TimeSelectionTypes.custom;
      if (!this.isCustomDatesApplied) {
        this.dateRangePicker.select(undefined);
      }
      this.startDate = moment(startDate).format(RPMDateFormat);
      this.endDate = moment(endDate).format(RPMDateFormat);
      this.updateSelectedReports(startDate, endDate, value);
    }
  }

  onDateRangePicked(): void {
    this.timeSelections.forEach(selection => {
      if (selection.value === TimeSelectionTypes.custom) {
        selection.startDate = moment(this.startDate).format(RPMDateFormat);
        selection.endDate = moment(this.endDate).format(RPMDateFormat);
      }
    });
    this.onTimelineSelected(TimeSelectionTypes.custom);
  }

  openDatePicker(): void {
    this.dateRangePicker.open();
  }

  onStartDateChanged(event: any): void {
    this.startDate = moment(event.value).format(RPMDateFormat);
  }

  onEndDateChanged(event: any): void {
    this.endDate = moment(event.value).format(RPMDateFormat);
  }

  layoutSelectionChanged({ value }: MatRadioChange): void {
    switch (value) {
      case 1:
        this.oneVisualPerRow();
        break;
      case 2:
        this.twoVisualsPerRow();
        break;
      case 3:
        this.threeVisualsPerRow();
        break;
      case 4:
        this.fourVisualsPerRow();
        break;
      default:
        throw Error('Layout value not compatible');
    }
  }

  private async fourVisualsPerRow() {
    const currentPage = await this.singleReport.getPageByName(this.pageNameSalesBreakdown);
    const pageWidth = currentPage.defaultSize.width!;
    const margin: number = 6;
    const width: number = ((pageWidth - (margin * 5)) / 4);
    const height: number = width * (4 / 5);
    const numberOfRows = this.getNumberOfRows();
    const pageHeight: number = Math.floor((height * numberOfRows) + 35);
    const pageSize: powerbiModels.ICustomPageSize = {
      type: powerbiModels.PageSizeType.Custom,
      width: pageWidth,
      height: pageHeight
    };
    const layoutConfig = [
      { x: margin, y: margin, width, height },
      { x: width + (margin * 2), y: margin, width, height },
      { x: (width * 2) + (margin * 3), y: margin, width, height },
      { x: (width * 3) + (margin * 4), y: margin, width, height },
      { x: margin, y: height + (margin * 2), width, height },
      { x: width + (margin * 2), y: height + (margin * 2), width, height },
    ];
    const settings: powerbiModels.ISettings = {
      layoutType: powerbiModels.LayoutType.Custom,
      customLayout: {
        displayOption: powerbiModels.DisplayOption.FitToWidth,
        pageSize,
        pagesLayout: {
          [this.pageNameSalesBreakdown]: {
            visualsLayout: this.setVisualsLayout(layoutConfig)
          }
        }
      }
    };
    this.singleReport.updateSettings(settings);
    this.resetSingleReportContainerHeight(pageHeight, numberOfRows);
    this.persistVisualsPerRow(this.loggedInUsername, this.visualsPerRowState.four.value);
  }

  private async threeVisualsPerRow() {
    const currentPage = await this.singleReport.getPageByName(this.pageNameSalesBreakdown);
    const pageWidth = currentPage.defaultSize.width!;
    const margin: number = 8;
    const width: number = ((pageWidth - (margin * 4)) / 3);
    const height: number = width * (2 / 3);
    const numberOfRows = this.getNumberOfRows();
    const pageHeight: number = Math.floor((height * numberOfRows) + 35);
    const pageSize: powerbiModels.ICustomPageSize = {
      type: powerbiModels.PageSizeType.Custom,
      width: pageWidth,
      height: pageHeight
    };
    const layoutConfig = [
      { x: margin, y: margin, width, height },
      { x: width + (margin * 2), y: margin, width, height },
      { x: (width * 2) + (margin * 3), y: margin, width, height },
      { x: margin, y: height + (margin * 2), width, height },
      { x: width + (margin * 2), y: height + (margin * 2), width, height },
      { x: (width * 2) + (margin * 3), y: height + (margin * 2), width, height },
    ];
    const settings: powerbiModels.ISettings = {
      layoutType: powerbiModels.LayoutType.Custom,
      customLayout: {
        displayOption: powerbiModels.DisplayOption.FitToWidth,
        pageSize,
        pagesLayout: {
          [this.pageNameSalesBreakdown]: {
            visualsLayout: this.setVisualsLayout(layoutConfig)
          }
        }
      }
    };
    this.singleReport.updateSettings(settings);
    this.resetSingleReportContainerHeight(pageHeight, numberOfRows);
    this.persistVisualsPerRow(this.loggedInUsername, this.visualsPerRowState.three.value);
  }

  private async twoVisualsPerRow() {
    const currentPage = await this.singleReport.getPageByName(this.pageNameSalesBreakdown);
    const pageWidth = currentPage.defaultSize.width!;
    const margin: number = 8;
    const width: number = ((pageWidth - (margin * 3)) / 2);
    const height: number = width * (9 / 16);
    const numberOfRows = this.getNumberOfRows();
    const pageHeight: number = Math.floor((height * numberOfRows) + 35);
    const pageSize: powerbiModels.ICustomPageSize = {
      type: powerbiModels.PageSizeType.Custom,
      width: pageWidth,
      height: pageHeight
    };
    const layoutConfig = [
      { x: margin, y: margin, width, height },
      { x: width + (margin * 2), y: margin, width, height },
      { x: margin, y: height + (margin * 2), width, height },
      { x: width + (margin * 2), y: height + (margin * 2), width, height },
      { x: margin, y: (height * 2) + (margin * 3), width, height },
      { x: width + (margin * 2), y: (height * 2) + (margin * 3), width, height }
    ];
    const settings: powerbiModels.ISettings = {
      layoutType: powerbiModels.LayoutType.Custom,
      customLayout: {
        displayOption: powerbiModels.DisplayOption.FitToWidth,
        pageSize,
        pagesLayout: {
          [this.pageNameSalesBreakdown]: {
            visualsLayout: this.setVisualsLayout(layoutConfig)
          }
        }
      }
    };
    this.singleReport.updateSettings(settings);
    this.resetSingleReportContainerHeight(pageHeight, numberOfRows);
    this.persistVisualsPerRow(this.loggedInUsername, this.visualsPerRowState.two.value);

  }

  private async oneVisualPerRow() {
    const currentPage = await this.singleReport.getPageByName(this.pageNameSalesBreakdown);
    const pageWidth = currentPage.defaultSize.width!;
    const margin: number = 12;
    const width: number = pageWidth - (margin * 2);
    const height: number = width * (6 / 16);
    const numberOfRows = this.getNumberOfRows();
    const pageHeight: number = Math.floor((height * numberOfRows) + 100);
    const pageSize: powerbiModels.ICustomPageSize = {
      type: powerbiModels.PageSizeType.Custom,
      width: pageWidth,
      height: pageHeight
    };
    const layoutConfig = [
      { x: margin, y: margin, width, height },
      { x: margin, y: height + (margin * 2), width, height },
      { x: margin, y: (height * 2) + (margin * 3), width, height },
      { x: margin, y: (height * 3) + (margin * 4), width, height },
      { x: margin, y: (height * 4) + (margin * 5), width, height },
      { x: margin, y: (height * 5) + (margin * 6), width, height }
    ];
    const settings: powerbiModels.ISettings = {
      layoutType: powerbiModels.LayoutType.Custom,
      customLayout: {
        displayOption: powerbiModels.DisplayOption.FitToWidth,
        pageSize,
        pagesLayout: {
          [this.pageNameSalesBreakdown]: {
            visualsLayout: this.setVisualsLayout(layoutConfig)
          }
        }
      }
    };
    this.singleReport.updateSettings(settings);
    this.resetSingleReportContainerHeight(pageHeight, numberOfRows);
    this.persistVisualsPerRow(this.loggedInUsername, this.visualsPerRowState.one.value);
  }

  private setVisualsLayout(layoutConfig: any[]): powerbiModels.VisualsLayout {
    const visualsOrderedBasedOnSelection = orderBy(this.allReports, (report: IReport) => !report.isSelected, ['asc']);
    return visualsOrderedBasedOnSelection.reduce((previous: powerbiModels.VisualsLayout, current: IReport, index: number) => {
      previous[current.name] = {
        ...layoutConfig[index],
        displayState: current.isSelected
          ? this.displayModeVisible
          : this.displayModeHidden
      };
      return previous;
    }, {});
  }

  private getNumberOfRows(): number {
    const visibleVisuals = this.allReports.filter((report: IReport) => report.isSelected);
    // calculate the actual height of the page based on the number of visible visuals
    return Math.ceil(visibleVisuals.length / this.selectedLayout);
  }

  private resetSingleReportContainerHeight(pageHeight: number, numberOfRows: number): void {
    const rowGap = this.isSidebarVisible ? numberOfRows * 20 : numberOfRows * 150;

    // Calculate the zoom factor
    const zoomFactor = window.innerWidth / window.outerWidth;

    // Apply a factor to highResolutionMonitor based on the zoom factor
    const highResolutionMonitor = window.innerWidth > 1600 ? 300 * zoomFactor : 0;

    // Calculate the adjusted height considering zoom
    const adjustedHeight = Math.floor(pageHeight + rowGap + highResolutionMonitor) * zoomFactor;

    // Set the height of the container dynamically
    this.renderer.setStyle(this.singleReportContainer.nativeElement, 'height', `${adjustedHeight}px`);
  }

  private persistVisualsPerRow(loggedInUser: string, value: string): void {
    const visualsPerRow = localStorage.getItem('visualsPerRow');
    if (!visualsPerRow) {
      const persistDefaultUserSelection = {
        [this.loggedInUsername]: this.visualsPerRowState.three.value
      };
      localStorage.setItem('visualsPerRow', JSON.stringify(persistDefaultUserSelection));
    }
    const updatedVisualsPerRow = {
      ...JSON.parse(visualsPerRow || ''),
      [loggedInUser]: value,
    }
    localStorage.setItem('visualsPerRow', JSON.stringify(updatedVisualsPerRow));
  }

  adjustTimeline(offset: number): void {
    const { name, value, text } = this.selectedTimeline;
    const momentStartDate = moment(this.startDate);
    const calculatedStartDate = moment(this.startDate).add(offset, text).format(RPMDateFormat);
    
    switch (name) {
      case 'D': {
        this.startDate = calculatedStartDate;
        this.endDate = calculatedStartDate;
        break;
      }
      // R2-1089: for week show sunday to saturday
      case 'W': {
        if (momentStartDate.day() !== 0) {
          // set start date to next sunday if start date is mid week on next button click
          const daysUntilSunday = 7 - momentStartDate.day();
          this.startDate = momentStartDate.add(daysUntilSunday, 'days').format(RPMDateFormat);
        } else {
          this.startDate = calculatedStartDate;
        }
        if (moment(this.endDate).day() !== 6) {
          // set end date to previous saturday if end date is mid week on previous button click
          const inputDate = moment(this.endDate);
          const daysUntilPreviousSaturday = (inputDate.day() + 1) % 7;
          this.endDate = inputDate.subtract(daysUntilPreviousSaturday, 'days').format(RPMDateFormat);
        } else {
          this.endDate = moment(this.endDate).add(offset, text).format(RPMDateFormat);
        }
        break;
      }
      // R2-1090: for month show full month
      case 'M': {
        const isStartDateStartOfMonth = momentStartDate.isSame(momentStartDate.clone().startOf('month'), 'day');
        this.startDate = isStartDateStartOfMonth
          ? calculatedStartDate
          : momentStartDate.add(1, 'month').startOf('month').format(RPMDateFormat);
        this.endDate = moment(this.startDate).endOf('month').format(RPMDateFormat);
        break;
      }
      // for year timeline a full year should be shown
      case 'Y': {
        const isStartDateStartOfYear = momentStartDate.isSame(momentStartDate.clone().startOf('year'), 'day');
        this.startDate = isStartDateStartOfYear
          ? calculatedStartDate
          : momentStartDate.add(1, 'year').startOf('year').format(RPMDateFormat);
        this.endDate = moment(this.startDate).endOf('year').format(RPMDateFormat);
        break;
      }
      default: {
        this.endDate = this.endDate;
        break;
      }
    }

    // R2-1094: set start date to earliest CarePlan start date if calculated start date is before CarePlan start date
    const isStartDateBeforeCarePlanStartDate = moment(this.startDate).isSameOrBefore(this.carePlanStartDate);
    if (isStartDateBeforeCarePlanStartDate) {
      this.startDate = moment(this.carePlanStartDate).format(RPMDateFormat);
    }
    // enable/disable previous button
    this.isDisablePreviousButton = isStartDateBeforeCarePlanStartDate;

    // set end date to today if calculated end date is after today
    const isEndDateAfterToday = moment(this.endDate).isSameOrAfter(this.today);
    if (isEndDateAfterToday) {
      this.endDate = moment(this.today).format(RPMDateFormat);
    }
    // enable/disable next button if end date is after today
    this.isDisableNextButton = isEndDateAfterToday;

    this.updateSelectedReports(this.startDate, this.endDate, value);
  }
}
