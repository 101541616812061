import { Bundle, BundleEntry, FhirResource, Resource } from "fhir/r4";

/**
 * Extracting FHIR Bundle to specified resource type array
 * @param bundle Bundle containing resource type T
 * @returns Array containing resource objects of type T
 */
export const MapBundleToResourceArray = <T>(bundle: Bundle<T>): T[] => {
  return bundle?.entry?.length
    ? bundle.entry
      .filter((bundleEntry: any) => !bundleEntry.resource?.issue)
      .map((bundleEntry: BundleEntry<T>) => bundleEntry.resource!)
    : [];
}

/**
 * To be used when a Bundle contains n types of resources
 * @param bundle Bundle including types T and U
 * @param resource1Type resourceType of FhirResource
 * @param @optional resource2Type resourceType of FhirResource
 * @param @optional resource3Type resourceType of FhirResource
 * @returns 3 arrays each containing one resource type items
 */
export const MapBundleToResourceArrays = <T extends Resource, U extends Resource, V extends Resource = any>(
  bundle: Bundle<T | U | V>,
  resource1Type: string,
  resource2Type?: string,
  resource3Type?: string
): { resource1: T[], resource2: U[], resource3: V[] } => {
  const resource1: T[] = [];
  const resource2: U[] = [];
  const resource3: V[] = [];

  if (!bundle?.entry?.length) {
    return {
      resource1,
      resource2,
      resource3
    };
  }
  bundle.entry.forEach((bundleEntry: BundleEntry<T | U | V>) => {
    if (bundleEntry?.resource?.resourceType === resource1Type) {
      resource1.push(bundleEntry.resource as T);
    }
    if (bundleEntry?.resource?.resourceType === resource2Type) {
      resource2.push(bundleEntry.resource as U);
    }
    if (!!resource3Type && bundleEntry?.resource?.resourceType === resource3Type) {
      resource3.push(bundleEntry.resource as V);
    }
  });
  return {
    resource1,
    resource2,
    resource3
  };
}

/**
 * To be used when a Bundle contains another Bundle which has 2 types of resources
 * 
 * Assumption #1: bundled request in getPatientConditionsServiceRequests() returns
 * Conditions (diagnosis) in bundle.entry[0].resource.entry &
 * ServiceRequests (referredFrom) in bundle.entry[1].resource.entry
 * 
 * Assumption #2: bundled request in getPatientCarePlanPlanDefinitions() returns
 * CarePlan (maximum of one CarePlan for a patient in phase 1) 
 * and associated goals in bundle.entry[0].resource.entry &
 * PlanDefinitions (programs) in bundle.entry[1].resource.entry
 * 
 * @param bundle Bundle which contains another bundle with multiple resource types
 * @returns 2 arrays each containing one resource type items
 */
export const MapMultiBundlesToResourceArrays = <T, U, V>(
  bundle: Bundle<Bundle<T | U | V>>
): { resource1: T[], resource2: U[], resource3: V[] } => {
  const resource1: T[] = [];
  const resource2: U[] = [];
  const resource3: V[] = [];

  if (!bundle?.entry?.length) {
    return {
      resource1,
      resource2,
      resource3
    };
  }
  const resource1Entries = bundle.entry[0]?.resource?.entry as BundleEntry<T>[];
  const resource2Entries = bundle.entry[1]?.resource?.entry as BundleEntry<U>[];
  const resource3Entries = bundle.entry[2]?.resource?.entry as BundleEntry<V>[];
  return {
    resource1: resource1Entries?.length
      ? resource1Entries.map(({ resource }: BundleEntry<T>) => resource as T)
      : [],
    resource2: resource2Entries?.length
      ? resource2Entries.map(({ resource }: BundleEntry<U>) => resource as U)
      : [],
    resource3: resource3Entries?.length
      ? resource3Entries.map(({ resource }: BundleEntry<V>) => resource as V)
      : [],
  };
}

/**
 * To be used when an array contains multiple resource types
 * @param resourceArray 
 * @param resource1Type 
 * @param resource2Type 
 * @returns 2 arrays each containing one resource type items
 */
export const MapMultipleResourceTypeArrayIntoArrays = <T extends Resource, U extends Resource>(
  resourceArray: Array<T | U>,
  resource1Type: string,
  resource2Type: string
): { resource1: T[], resource2: U[] } => {
  return resourceArray.reduce((
      prev: { resource1: T[], resource2: U[] },
      curr: T | U,
    ) => {
      if (curr.resourceType === resource1Type) {
        prev.resource1.push(curr as T);
      }
      if (curr.resourceType === resource2Type) {
        prev.resource2.push(curr as U);
      }
      return prev;
    },
    { resource1: [], resource2: [] }
  );
}

/**
 * Extract a certain type of resource from bundle where bundle contains multiple resources
 * @param bundle 
 * @param resourceType 
 * @returns 
 */
export const MapBundleToSingleResourceArray = <T extends any>(bundle: Bundle, resourceType: string): T[] => { 
  if (!bundle?.entry?.length) {
    return [];
  }
  return bundle.entry
    .map((bundleEntry) => {
      if (bundleEntry?.resource?.resourceType === resourceType) {
        return bundleEntry.resource as T;
      }
      return null;
    })
    .filter(Boolean) as T[];
}

/**
 * R2-877: Move patientIds appended in the URL to body cause of length restriction for FHIR endpoints
 * @param url 
 * @returns 
 */
export const BundleGetRequest = (url: string): Bundle => { 
  return {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: [
      {
        request:
        {
          method: 'GET',
          url
        }
      }
    ]
  };
}

/**
 * Multi bundle get request
 * @param url 
 * @returns 
 */
export const MultiBundleGetRequest = (urlList: string[]): Bundle => { 
  return {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: urlList.map(url => ({
      request:
      {
        method: 'GET',
        url
      }
    }))
  };
}

/**
 * FHIR resource Bundle in BundleGETRequest's response is in bundle.entry[0].resource
 * @param bundle 
 * @returns 
 */
export const MapBundledRequestResponseToBundle = <T extends FhirResource>(bundle: Bundle): Bundle<T> => {
  if (!bundle?.entry?.length) {
    return {
      resourceType: 'Bundle',
      type: 'collection',
      entry: [],
    }
  }
  return bundle.entry[0].resource as Bundle<T>;
}