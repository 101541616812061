<div class="patients-title-row">
  <h2 mat-dialog-title class="patients-custom-dialog-title">Acknowledge</h2>
  <mat-icon style="cursor: pointer;" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content>
  <div class="acknowledge-row">
    <div>
      <mat-icon>info</mat-icon>
      <strong>{{ alertType }} - {{ reportedValue | number:'1.0-0' }} {{ alertUnit }}</strong>
      <span>{{ alertTime }} | {{ alertDate }}</span>
    </div>
    <div>
      <span>Not Acknowledged</span>
      <!-- <mat-icon *ngIf="isDevEnvironment" [attr.data-cal-link]="dataCalLinkQuery" title="Schedule Meeting">calendar_today</mat-icon>
      <a *ngIf="isDevEnvironment" title="Join Call" target="_blank" href="http://poc-acs-asn.azurewebsites.net/"><mat-icon>call</mat-icon></a> -->
    </div>
  </div>
  <form [formGroup]="formGroup">
    <!-- comment -->
    <span class="mat-h4 patients-input-label">Comment</span>
    <mat-form-field>
      <textarea matInput rows="8" formControlName="commentControl"></textarea>
      <mat-error
        *ngIf="formGroup.get('commentControl')?.touched && formGroup.get('commentControl')?.hasError('required')">Comment is
        required</mat-error>
    </mat-form-field>
    
    <mat-dialog-actions [align]="'end'">
      <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
      <button mat-raised-button color="primary" class="patients-basic-button filled patients-auto-width"
        (click)="closeDialog()" [disabled]="formGroup.invalid">Acknowledge</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>