<section class="patient-charts-component">

  <!-- charts header section -->
  <section class="charts-header">
    <!-- left section -->
    <section>
      <!-- reports apply section -->
      <div class="chart-selection" #chartSelection>
        <mat-icon>keyboard_arrow_down</mat-icon>
        <button mat-stroked-button (click)="isChartSelectionDropdownVisible = !isChartSelectionDropdownVisible">Select
          Chart(s)</button>
        <div class="chart-dropdown" [hidden]="!isChartSelectionDropdownVisible">
          <div *ngFor="let report of allReports">
            <mat-checkbox *ngIf="report.isShownInDropdown" color="primary" [(ngModel)]="report.isSelected">{{report.title}}</mat-checkbox>
          </div>
          <div style="margin-bottom: 0;">
            <button mat-stroked-button (click)="onReportsApply()" class="apply">Apply</button>
          </div>
        </div>
      </div>
      <!-- layout section -->
      <div class="chart-selection" #layoutSelection>
        <mat-icon>keyboard_arrow_down</mat-icon>
        <button mat-stroked-button (click)="isLayoutSelectionDropdownVisible = !isLayoutSelectionDropdownVisible">Reports per row - {{selectedLayout}}</button>
        <div class="chart-dropdown layout-selection-dropdown" [hidden]="!isLayoutSelectionDropdownVisible">
          <mat-radio-group [(ngModel)]="selectedLayout" 
            (change)="layoutSelectionChanged($event); isLayoutSelectionDropdownVisible = !isLayoutSelectionDropdownVisible">
            <div *ngFor="let chartLayout of chartLayouts">
              <mat-radio-button [value]="chartLayout.value" color="primary">{{
                chartLayout.text }}</mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <!-- day, week, month, year, custom selection -->
      <div class="time-selection">
        <ng-container *ngFor="let selection of timeSelections">
          <ng-container *ngIf="selection.name !== 'CUSTOM'">
            <button mat-stroked-button (click)="onTimelineSelected(selection.value)" [class.is-selected]="selection.isSelected">{{selection.name}}</button>
          </ng-container>
        </ng-container>
      </div>
      <!-- timeline previous/next --> 
    </section>
    <!-- right section -->
    <section>
      <!-- date range picker wrapper -->
      <div class="timeline-change">
        <button [disabled]="isCustomDatesApplied || isDisablePreviousButton" (click)="adjustTimeline(-1)" [title]="'Previous ' + selectedTimeline.text">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <div class="date-range-picker">
        <!-- date range text info -->
        <div class="date-range-info">
          <mat-icon style="color: #2797b6; transform: scale(0.75);">date_range</mat-icon>
          <span>{{startDate.split(',')[0]}}</span>
          <mat-icon style="color: #4a4a4a; transform: scale(0.75);">arrow_forward</mat-icon>
          <span>{{endDate}}</span>
        </div>
        <!-- date range picker -->
        <mat-form-field>
          <mat-date-range-input [rangePicker]="picker" [min]="carePlanStartDate" [max]="today">
            <input matStartDate (dateChange)="onStartDateChanged($event)" placeholder="Start date" >
            <input matEndDate (dateChange)="onEndDateChanged($event)" placeholder="End date">
          </mat-date-range-input>
          <mat-date-range-picker (closed)="onDateRangePicked()" #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>
      <div class="timeline-change">
        <button [disabled]="isDisableNextButton || isCustomDatesApplied" (click)="adjustTimeline(1)" [title]="'Next ' + selectedTimeline.text">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </section>
  </section>

  <!-- single report -->
  <section>
    <div class="single-report-container" #singleReportContainer></div>
    <section *ngIf="isChartError" class="no-data">Error while loading charts!</section>
  </section>
</section>