import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle, Goal } from 'fhir/r4';
import { Observable } from 'rxjs';
import { DefaultResourceQueryCount } from '../config/app.config';
import { BaseService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class GoalService extends BaseService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  getGoals(): Observable<Bundle<Goal>> {
    return this.http.get<Bundle<Goal>>(`${this.FHIR_BASE}/Goal?lifecycle-status=on-hold&_total=accurate&_count=${DefaultResourceQueryCount}`);
  }

  getGoalById(goalId: string): Observable<Goal> {
    return this.http.get<Goal>(`${this.FHIR_BASE}/Goal/${goalId}`);
  }
}
