import moment from 'moment';
import { Component, OnDestroy, OnInit, Inject, HostBinding, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, debounceTime, filter, forkJoin, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { CommunicationMethods, MinQueryLengthToFireSearch, ProvincesInCanada, RPMDateFormat, SearchDebounceTime } from 'src/app/config/app.config';
import { Diagnosis } from 'src/app/models/diagnosis.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { FormService } from 'src/app/services/form.service';
import { DropdownErrorStateMatcher } from 'src/app/shared/error-state-matchers/dropdown-error-state-matcher';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';

@Component({
  selector: 'patient-app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  @ViewChild('picker1') picker1!: MatDatepicker<Date>;
  private unsubscribe$ = new Subject<void>();
  patientInformationFormGroup!: FormGroup;
  diagnosis!: Diagnosis[];
  communicationMethods = CommunicationMethods;
  provincesInCanada = ProvincesInCanada;
  matcher = new DropdownErrorStateMatcher();
  maxDate: string;
  isDispatchingEditedData = false

  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<EditProfileComponent>,
    private formServcie: FormService,
    private diagnosisService: DiagnosisService,
    private breakpointService: BreakpointService
  ) {
    this.maxDate = moment().subtract(18, 'years').format(RPMDateFormat);
  }

  ngOnInit(): void {
    this.diagnosis = this.dialogData.diagnosis?.length ? this.filterUniqueDiagnosis(this.dialogData.diagnosis) : [];
    this.patientInformationFormGroup = this.formServcie.generatePatientProfileForm(this.dialogData.patient);

    this.patientInformationFormGroup.get("email")?.disable()
    // listen to diagnosis filtering
    this.patientInformationFormGroup.get('diagnosisFilterControl')?.valueChanges
      .pipe(
        // search.length === 0 for clearing search result and render default list
        // when search input query is cleared
        filter((search: string) => search.length === 0 || !!search && search.length > MinQueryLengthToFireSearch),
        debounceTime(SearchDebounceTime),
        switchMap((search: string) => {
          if (search.length) {
            return this.diagnosisService.searchDiagnosis(search)
          }

          // to keep the value selected from search previously before this search in the dropdown
          const previousValuesSelectedFromSearch = this.patientInformationFormGroup.get('diagnosis')?.value || [];
          const nonDefaultDiagnosis = previousValuesSelectedFromSearch.length
            ?
            this.diagnosisService.getNonDefaultDiagnosisByCodes(previousValuesSelectedFromSearch)
            : of([]);

          // the search has been cleared reset to default value list
          return forkJoin([
            this.diagnosisService.getDefaultDiagnosis(),
            of(this.dialogData.diagnosis),
            nonDefaultDiagnosis
          ])
            .pipe(
              map(
                (value: Array<any>) => this.filterUniqueDiagnosis([
                  // #R2-587 - Removed search result diagnosis and fetched selected & default diagnosis.
                  ...value?.[2] || [],
                  ...value?.[0] || [],
                  ...value?.[1] || []
                ])
              )
            )
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((diagnosis: Diagnosis[]) => this.diagnosis = diagnosis);

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  filterUniqueDiagnosis = (diagnosis: Array<Diagnosis>) => {
    return diagnosis.reduce(
      // filter unique diagnosis to avoid duplicate diagnosis in dropdown
      (prev, next) => {
        const codeExists = prev.find((item: any) => item?.code === next?.code);
        if (codeExists) {
          return prev;
        }
        return [...prev, next];
      }, [] as Diagnosis[]
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog() {

    const editedPatient: any = {};
    var defaultMappedDiagnosis: Diagnosis[] = [], nonDefaultDiagnosis: string[] = [];
    // filter out the changed values and set them in editedPatient, so that it can be 
    // used for patch payload generation.
    Object.keys(this.patientInformationFormGroup.controls).forEach(control => {
      if (this.patientInformationFormGroup.controls[control].dirty) {
        editedPatient[control] = this.patientInformationFormGroup.controls[control].value;
        if (control === "diagnosis") {//Jira #R2-389 & #R2-391
          defaultMappedDiagnosis = [...new Set(editedPatient[control] as string[])].map(code => {
            const diagnosis = this.diagnosis.find(d => d.code === code)
            if (!diagnosis) nonDefaultDiagnosis.push(code)
            return diagnosis!
          }).filter(d => d != undefined)
        }
      }
    });

    if (defaultMappedDiagnosis.length > 0) editedPatient.diagnosis = defaultMappedDiagnosis
    if (nonDefaultDiagnosis.length > 0) {
      // Jira #R2-390 & Jira #R2-392
      this.isDispatchingEditedData = true
      this.diagnosisService.getNonDefaultDiagnosisByCodes(nonDefaultDiagnosis)
        .pipe(
          map(diagnosis => {
            return [...defaultMappedDiagnosis, ...diagnosis]
          }),
          catchError((err) => {
            this.isDispatchingEditedData = false
            return []
          })
        )
        .subscribe(diagnosis => {
          this.isDispatchingEditedData = false
          if (diagnosis.length > 0) editedPatient.diagnosis = diagnosis
          this.dialogRef.close({
            ...editedPatient,
            dateOfBirth: moment(this.patientInformationFormGroup.controls['dateOfBirth'].value).format(RPMDateFormat)
          });
        })
    } else {
      this.dialogRef.close({
        ...editedPatient,
        dateOfBirth: moment(this.patientInformationFormGroup.controls['dateOfBirth'].value).format(RPMDateFormat)
      });
    }

  }

  openDatePicker(): void {
    this.picker1.open();
  }
}
