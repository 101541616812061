<div class="patients-title-row">
    <h2 mat-dialog-title class="patients-custom-dialog-title">Patient Information</h2>
    <mat-icon class="patients-pointer" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content>
    <form [formGroup]="patientInformationFormGroup" autocomplete="off">
        <!-- email -->
        <span class="mat-h4 patients-input-label">Email *</span>
        <mat-form-field>
            <input matInput formControlName="email" required>
            <mat-error
                *ngIf="patientInformationFormGroup.get('email')?.touched && patientInformationFormGroup.get('email')?.hasError('required')">
                Email is required.
            </mat-error>
            <mat-error
                *ngIf="patientInformationFormGroup.get('email')?.dirty && patientInformationFormGroup.get('email')?.hasError('invalidEmail')">
                Valid email is required.
            </mat-error>
        </mat-form-field>

        <div class="patients-flex2">
            <!-- first name -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">First Name *</span>
                <mat-form-field>                    
                    <input matInput formControlName="firstName" required>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('firstName')?.touched && patientInformationFormGroup.get('firstName')?.hasError('required')">
                        First Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- last name -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">Last Name *</span>
                <mat-form-field>                    
                    <input matInput formControlName="lastName" required>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('lastName')?.touched && patientInformationFormGroup.get('lastName')?.hasError('required')">
                        Last Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- gender -->
        <span class="mat-h4 patients-input-label">Sex *</span>
        <div class="patients-gender">
            <mat-radio-group formControlName="gender">
                <mat-radio-button value="male">Male</mat-radio-button>
                <mat-radio-button value="female">Female</mat-radio-button>
                <mat-radio-button value="unknown">Prefer not to say</mat-radio-button>
            </mat-radio-group>
            <mat-error class="patients-gender-error"
                *ngIf="patientInformationFormGroup?.touched && patientInformationFormGroup.get('gender')?.hasError('required')">
                Gender is required.
            </mat-error>
        </div>

        <!-- patient address line #1 -->
        <span class="mat-h4 patients-input-label">Patient Address Line #1 *</span>
        <mat-form-field>
            <input matInput formControlName="address1" required>
            <mat-error
                *ngIf="patientInformationFormGroup.get('address1')?.touched && patientInformationFormGroup.get('address1')?.hasError('required')">
                Address #1 is required.
            </mat-error>
        </mat-form-field>

        <!-- patient address line #2 -->
        <span class="mat-h4 patients-input-label">Patient Address Line #2</span>
        <mat-form-field>
            <input matInput formControlName="address2">
        </mat-form-field>

        <div class="patients-flex2">
            <div class="patients-flex2-col">
                <!-- apt, suit etc -->
                <span class="mat-h4 patients-input-label">Apt/Suit/Unit etc</span>
                <mat-form-field>                    
                    <input matInput formControlName="apt">
                </mat-form-field>
            </div>
            <!-- zip/postal code -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">ZIP/Postal Code *</span>
                <mat-form-field floatLabel="never" hideRequiredMarker="true">
                    <input matInput style="text-transform: uppercase;" formControlName="zip" required
                        patientAppZipCodeFormatter maxlength="7">
                    <mat-placeholder>A1A 1B1</mat-placeholder>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('zip')?.touched && patientInformationFormGroup.get('zip')?.hasError('required')">
                        Zip is required.
                    </mat-error>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('zip')?.touched && patientInformationFormGroup.get('zip')?.hasError('pattern')">
                        Please enter the postal code in the correct format. e.g. : A1A 1A1
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="patients-flex2">
            <!-- city -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">City *</span>
                <mat-form-field>
                    <input matInput formControlName="city" required>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('city')?.touched && patientInformationFormGroup.get('city')?.hasError('required')">
                        City is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- province -->
            <div class="patients-flex2-col">
                <div class="patients-custom-select-container">
                    <span class="mat-h4 patients-input-label">Province *</span>
                    <mat-form-field class="patients-select-form-field">
                        <mat-select formControlName="province" required>
                            <mat-option *ngFor="let province of provincesInCanada"
                                [value]="province.text">{{province.text}}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="patientInformationFormGroup.get('province')?.touched && patientInformationFormGroup.get('province')?.hasError('required')">
                            Province is required.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- mrn -->
        <span class="mat-h4 patients-input-label">MRN</span>
        <mat-form-field>
            <input matInput formControlName="mrn">
        </mat-form-field>

        <div class="patients-flex2">
            <!-- ihealth email -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">iHealth Email *</span>
                <mat-form-field floatLabel="never" hideRequiredMarker="true">
                    <input matInput formControlName="iHealthEmail" required>
                    <mat-placeholder>abc@email.com</mat-placeholder>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('iHealthEmail')?.touched && patientInformationFormGroup.get('iHealthEmail')?.hasError('required')">
                        iHealth Email is required.
                    </mat-error>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('iHealthEmail')?.dirty && patientInformationFormGroup.get('iHealthEmail')?.hasError('invalidEmail')">
                        Please enter valid email.
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- phone -->
            <div class="patients-flex2-col">
                <span class="mat-h4 patients-input-label">Phone *</span>
                <mat-form-field floatLabel="never" hideRequiredMarker="true">
                    <input matInput formControlName="phone" required patientAppMaskPhoneNumber>
                    <mat-placeholder>(123) 456-7890</mat-placeholder>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('phone')?.touched && patientInformationFormGroup.get('phone')?.hasError('required')">
                        Phone is required.
                    </mat-error>
                    <mat-error
                        *ngIf="patientInformationFormGroup.get('phone')?.touched && patientInformationFormGroup.get('phone')?.hasError('pattern')">
                        Phone number format (XXX) XXX-XXXX is incorrect
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- dob -->
        <span class="mat-h4 patients-input-label">Date of Birth *</span>
        <mat-form-field floatLabel="never" hideRequiredMarker="true">
            <input (click)="openDatePicker()" readonly class="patients-dob" matInput formControlName="dateOfBirth"
                [matDatepicker]="picker1" [max]="maxDate">
            <mat-placeholder>yyyy-mm-dd</mat-placeholder>
            <mat-error
                *ngIf="patientInformationFormGroup.get('dateOfBirth')?.touched && patientInformationFormGroup.get('dateOfBirth')?.hasError('required')">
                Date of Birth is required.
            </mat-error>
            <div class="patients-position-datepicker">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </div>
        </mat-form-field>

        <!-- communication method -->
        <span class="mat-h4 patients-input-label">Communication Method</span>
        <div class="patients-custom-select-container single">
            <mat-form-field class="patients-select-form-field">
                <mat-select formControlName="communicationMethod" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let method of communicationMethods"
                        [value]="method.value">{{method.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- diagnosis -->
        <span class="mat-h4 patients-input-label">Diagnosis</span>
        <div class="patients-custom-select-container single">
            <mat-form-field class="patients-select-form-field">
                <mat-select formControlName="diagnosis" [multiple]="true" #multiSelect>
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Type & Search'"
                            [noEntriesFoundLabel]="'No diagnosis found'" [disableScrollToActiveOnOptionsChanged]="true"
                            [alwaysRestoreSelectedOptionsMulti]="true"
                            formControlName="diagnosisFilterControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of diagnosis" [value]="item.code"><!-- Jira #R2-389 -->
                        {{item.code}} - {{item.display}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

    <mat-dialog-actions [align]="'end'" class="patients-action-row">
        <button mat-button [mat-dialog-close]="false" class="patients-basic-button empty patients-auto-width"
            color="primary">Cancel</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-raised-button [disabled]="patientInformationFormGroup.invalid || isDispatchingEditedData"
            class="patients-basic-button filled patients-auto-width" (click)="closeDialog()"
            color="primary">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>