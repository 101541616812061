export class CreateValidicUser {
    constructor(
        public uid: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public iHealthEmail: string
    ) { }

    public static makeModel(data: any) {
        const obj = CreateValidicUser.validate(data);
        return new CreateValidicUser(
            obj.uid,
            obj.email,
            obj.firstName,
            obj.lastName,
            obj.iHealthEmail
        )
    }

    public static validate(obj: any) {
        if (obj === null || !Object.keys(obj).length) {
            throw new Error("CreateValidicUser empty payload");
        }

        if (!obj.uid) {
            throw new Error("CreateValidicUser: No uid in payload");
        }
        if (!obj.email) {
            throw new Error("CreateValidicUser: No email in payload");
        }
        if (!obj.firstName) {
            throw new Error("CreateValidicUser: No firstName in payload");
        }
        if (!obj.lastName) {
            throw new Error("CreateValidicUser: No lastName in payload");
        }
        if (!obj.iHealthEmail) {
            throw new Error("CreateValidicUser: No iHealthEmail in payload");
        }
        return obj
    }

}

export class ValidicUserResponse {
    constructor(
        public id: string,
        public uid: string,
        public marketplace: {
            token: string,
            url: string
        },
        public mobile: {
            token: string
        },
        public location: {
            timezone: string,
            countryCode: string
        },
        public sources: Array<{
            type: string;
            connectedAt: string;
            lastProcessedAt: string;

        }>,
        public status: string,
        public createdAt: string,
        public updatedAt: string
    ) { }

    public static makeModel(obj: any): ValidicUserResponse {
        if (obj === null || !Object.keys(obj).length) {
            throw new Error("Invalid validic user response");
        }

        return new ValidicUserResponse(
            obj.id,
            obj.uid,
            obj.marketplace,
            obj.mobile,
            {
                timezone: obj.location.timezone,
                countryCode: obj.location.country_code
            },
            obj.sources?.map((source: any) => ({
                type: source.type,
                connectedAt: source.connected_at,
                lastProcessedAt: source.last_processed_at
            })) ?? [],
            obj.status,
            obj.created_at,
            obj.updated_at
        )
    }
}