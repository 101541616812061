
<div class="card-container">
  <section *ngIf="isCardsLoading" style="width: 100%; padding: 40px 0; display: grid; place-items: center;">
    <mat-spinner class="patients-rpm-spinner" [strokeWidth]="defaultSpinnerProperties.strokeWidth"
      [diameter]="defaultSpinnerProperties.diameter"></mat-spinner>
  </section>
  <ng-container *ngIf="!isCardsLoading">
    <!-- note: alert information on the back side of the card held for phase 1 -->
    <!-- todo: add rotate class to card-inner to show the back side of the card -->
    <ng-container *ngFor="let card of cardListing">
      <!-- single card for alert -->
      <a href="javascript:void(0)" class="card-wrapper" [ngClass]="{'add-bottom-margin': isBloodPressureCardShown}" (click)="filterAlertListingBasedOnGoalCode(card.goalCode)">
        <div class="card-inner">
          <!-- card front side -->
          <div class="card-front">
            <!-- add class red to hightlight card -->
            <div class="card green" [ngClass]="{'red': card.hasAlert}">
              <!-- updated, menu -->
              <div class="card-flex">
                <div class="updated">
                  <b>Last Updated</b>
                  <span>{{card.lastUpdated | localTime:patientSummaryDateFormat}}</span>
                </div>
              </div>
              <!-- latest observation (non blood pressure cards)-->
              <div class="reading" *ngIf="!card?.systolicValue" [ngClass]="{'add-height': isBloodPressureCardShown}">
                <ng-container>{{card.latestObservationValue | number: '1.2-2'}}</ng-container>
                <span>{{card.unit}}</span>
              </div>
              <!-- latest observation for blood pressure -->
              <!-- show systolic/diastolic observation values in the same card if systolicValue prop is present -->
              <div class="reading" *ngIf="card?.systolicValue">
                <!-- systolic observation value -->
                {{card.systolicValue | number: '1.0-0'}}
                <span style="font-size: 2rem;">-</span>
                <!-- diastolic observation value -->
                {{card.diastolic?.diastolicObservationValue | number: '1.0-0'}}
                <span>{{card.unit}}</span>
              </div>
              <!-- goal -->
              <div class="goal">
                <img [src]="card.alertIcon" alt="">
                {{card.goalName}} - <span>{{card.lowGoalValue}} - {{card.highGoalValue}} {{card.unit}}</span>
              </div>
              <!-- show diastolic threshold values if systolicValue prop is present -->
              <div *ngIf="card?.systolicValue" class="goal">
                <img [src]="card.alertIcon" alt="">
                {{card.diastolic?.diastolicGoalName}} - <span>{{card.diastolic?.diastolicLowValue}} - {{card.diastolic?.diastolicHighValue}} {{card.unit}}</span>
              </div>
              <!-- alerts -->
              <div class="alerts">
                <div [title]="card?.alertMessage"
                  [ngClass]="{'no-alert': !card.hasAlert, 'has-alert': card.hasAlert}">
                  {{card?.hasAlert ? 'Alert' : 'No New Alerts'}}
                </div>
              </div>
            </div>
          </div>
          <!-- card back side -->
          <div class="card-back">
            <div class="card green">
            </div>
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
  <!-- no data -->
  <ng-container *ngIf="!isCardsLoading && !cardListing?.length">
    <section class="no-data">
      No alerts!
    </section>
  </ng-container>
</div>

<section class="filters-flex">
  <div style="margin: 30px 0 20px;" class="patients-module-title">Alert History</div>
  <!-- filters -->
  <section>
    <div [matMenuTriggerFor]="alertFilterDropdownMenu" class="filter-button" [ngClass]="{'disabled-filter': isAlertListLoading || isCardsLoading}">
      <mat-icon class="filter-icon-color">filter_list</mat-icon>
      <span>Filter</span>
      <mat-icon class="down-arrow">keyboard_arrow_down</mat-icon>
    </div>
  </section>
</section>

<!-- filter dropdown -->
<mat-menu class="alert-filter-container" #alertFilterDropdownMenu="matMenu" xPosition="before" yPosition="below">
  <section class="alert-filter-dropdown" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <form #myForm="ngForm" (ngSubmit)="onFilterApply(myForm)">
      <!-- datepicker -->
      <mat-label class="alert-filter-title">Alert Date</mat-label>
      <mat-form-field class="alert-date-field">
        <input (click)="openDatePicker()" readonly matInput [matDatepicker]="picker" name="authoredOn" [(ngModel)]="alertDate">
        <mat-icon *ngIf="alertDate" 
          class="down-arrow clear-date" title="Clear Date Filter"
          (click)="clearDateAlert(myForm)">close</mat-icon>
        <mat-datepicker-toggle class="alert-date-button" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <!-- alert type dropdown -->
      <mat-label class="alert-filter-title">Alert Type</mat-label>
      <mat-form-field class="alert-select">
        <mat-select name="code" [(ngModel)]="alertType">
          <mat-option value="all">All</mat-option>
          <ng-container *ngFor="let alertType of updatedAlertTypes">
            <mat-option [value]="alertType.goalCode">{{ alertType.goalTitle }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <!-- alert status dropdown -->
      <mat-label class="alert-filter-title">Status</mat-label>
      <mat-form-field class="alert-select">
        <mat-select name="status" [(ngModel)]="alertStatus">
          <mat-option value="all">All</mat-option>
          <ng-container *ngFor="let alertStatus of alertStatuses">
            <mat-option [value]="alertStatus.value">{{ alertStatus.text }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    
      <button class="patients-basic-button filled" type="submit">Apply Filter</button>
    </form>
  </section>

</mat-menu>

<!-- alerts table -->
<patient-app-custom-mat-table class="alert-listing-table" id="alertListingTable" [tableData]="alertListing" [displayedColumns]="displayedColumns" 
  [setNotAcknowledgedButtonTemplate]="alertStatusTemplate" [setActionTemplate]="actionTemplate" 
  [isExpandableRow]="false"[isPageAble]="true" [showSpinner]="isAlertListLoading" [isAlertListingTable]="true"></patient-app-custom-mat-table>

<!-- alert status template in patient alert history passed to reusable mat table component -->
<ng-template #alertStatusTemplate let-item="item">
  <button class="not-acknowledged-button" 
    [ngClass]="{'disabled': item.alertStatus.toLowerCase() === 'acknowledged'}"
    mat-button (click)="onNotAcknowledgedClicked($event, item)">
    {{ item.alertStatus | titlecase }}
  </button>
</ng-template>

<!-- action button template in patient alert history passed to reusable mat table component -->
<ng-template #actionTemplate let-item="item">
  <button *ngIf="item.alertStatus === 'acknowledged'" class="alerts-action-button" 
  [ngClass]="{'show-note-length': !!item?.note?.length}" 
  [attr.data-number]="item?.note?.length ?? 0" 
  mat-button (click)="onAddAlertNote($event, item)">
    <mat-icon class="alerts-action-icon">chat_bubble_outline</mat-icon>
  </button>
</ng-template>