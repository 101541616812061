import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDefaultPageSizes } from 'src/app/config/app.config';
import { ActivatedRoute, Router } from '@angular/router';
import { spinnerProperties } from 'src/app/config/app.config';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { NotificationService } from '../../../services/notification.service';
import { onOrganizationChange$ } from '@bayshoreHealthCare/store';

@Component({
  selector: 'patient-app-custom-mat-table',
  templateUrl: './custom-mat-table.component.html',
  styleUrls: ['./custom-mat-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CustomMatTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;

  @Input() displayedColumns: Array<{ column: string, title: string }> = [];
  @Input() isPageAble = false;
  @Input() isExpandableRow = true;
  @Input() showSpinner = true;
  @Input() isAlertListingTable = false;
  organizations: Array<Array<string>> = [];
  tableDataSource = new MatTableDataSource<any>([]);
  columnIds: string[] = [];
  path: any;
  paginationSizes: number[] = MatTableDefaultPageSizes;
  defaultPageSize = this.paginationSizes[0];

  // organization context in the format ["orgId", "orgName", "role"]
  activeOrganization!: Array<string>;

  // passed from parent template - listing action buttons
  actionTemplate!: TemplateRef<any>;
  @Input() set setActionTemplate(template: TemplateRef<any>) {
    this.actionTemplate = template;
  }

  alertStatusTemplate!: TemplateRef<any>;
  @Input() set setNotAcknowledgedButtonTemplate(template: TemplateRef<any>) {
    this.alertStatusTemplate = template;
  }

  // dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  // spinner Properties like diameter and stroke width
  defaultSpinnerProperties = spinnerProperties;

  unsubscribe$: Subject<void> = new Subject()

  @Input() tabName: 'active' | 'pending' | 'inactive' = 'active';

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointService: BreakpointService,
    private notificationService: NotificationService
  ) {
    this.route.url.subscribe(segments => {
      const path = segments.map(segment => segment.path);
      this.path = path[0]
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['displayedColumns']?.currentValue && changes['displayedColumns']?.previousValue) {
      if (changes['displayedColumns']?.currentValue.length !== changes['displayedColumns']?.previousValue?.length) {
        this.columnIds = changes['displayedColumns']?.currentValue?.map((c: any) => c.column);
      }
    }
  }

  ngOnInit(): void {

    /** Listen for organization switch from sidebar */
    onOrganizationChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (organization: Array<string>) => {
          this.activeOrganization = organization;
        }
      });

    this.columnIds = this.displayedColumns.map(c => c.column);
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.matPaginator;
  }

  setTableDataSource(data: any): void {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.sort;
  }

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  expandedElement: any;

  redirectToProfile(event: Event, data: any) {
    event.stopPropagation();
    if (this.tabName === 'inactive' && !this.checkClinician()) {
      this.notificationService.showNotification('Access Denied for discharged patient history', 'error', 'Ok');
      return;
    }
    if (this.tabName === 'active' || this.tabName === 'inactive') {
      this.router.navigate([`../${data.id}/profile/${this.path}`], { relativeTo: this.route, replaceUrl: false });
    }
  }

  /**
   * #R2-584 Firefox main element scroll height issue fix
   */
  onChangeItemPerPage() {
    if (navigator.userAgent.indexOf("Firefox") === -1) return;
    setTimeout(() => {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.scrollTop = (document.querySelector("main > div:last-child") as HTMLDivElement).scrollHeight - 900;
      }
    }, 100)
  }

  // #R2-849 View discharged patient's vitals from inactive tab. checking clinician roles

  checkClinician(): boolean {
    return this.activeOrganization[2].toLowerCase().includes('clinician')
            || this.activeOrganization[2].toLowerCase().includes('admin');
  }
}
