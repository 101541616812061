import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalcomService extends BaseService {

  constructor(
    private http: HttpClient,
  ) { 
    super();
  }

  getCalcomTeamEvents(teamId: string): Observable<any> {
    return this.http.get(`${this.VIRTUAL_EVENTS}/team-events?teamId=${teamId}`);
  }
}
