import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Patient } from 'fhir/r4';
import { EMPTY, Subject, takeUntil, tap } from 'rxjs';
import { inActivePatientTableColumns } from 'src/app/config/app.config';
import { MapPatientResourceToViewModel } from 'src/app/mappers/patient.mapper';
import { InActivePatientListing } from 'src/app/models/patient.model';
import { SearchCategory } from 'src/app/models/shared.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CarePlanService } from 'src/app/services/careplan.service';
import { PatientService } from 'src/app/services/patient.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { SearchPatients } from 'src/app/utils/shared.util';

@Component({
  selector: 'patient-app-inactive-patients',
  templateUrl: './inactive-patients.component.html',
  styleUrls: ['./inactive-patients.component.scss']
})
export class InactivePatientsComponent implements OnInit, OnDestroy {
  patients: InActivePatientListing[] = [];
  patientsCopy: Patient[] = [];
  displayedColumns = [
    { column: 'patientName', title: 'Patient Name' },
    { column: 'dob', title: 'DOB' },
    { column: 'email', title: 'Email' },
    { column: 'dischargeDate', title: 'Discharge Date' },
    { column: 'status', title: 'Status' },
    // need to show program name after discharge a patient
    { column: 'programName', title: 'Program' },
    { column: 'action', title: 'Action' },
  ];
  unsubscribe = new Subject<void>();
  showSpinner: boolean = true;
  systemColumns = inActivePatientTableColumns;
  systemColumnsForm!: FormGroup;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private patientService: PatientService,
    private carePlanService: CarePlanService,
    private breakpointService: BreakpointService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getPatients();

    this.patientService.refreshInactivePatientListing$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.getPatients());
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
    
    /** Create Form group for showing check boxes in system column filter
     *  form group with controls with name same as column name from `systemFilters`
     */
    this.systemColumnsForm = this.formBuilder.group(
      inActivePatientTableColumns.reduce((obj: any, item) => {
        obj[item.column] = true;
        return obj;
      }, {})
    );

    /**
     * For column filter changes
     */
    this.systemColumnsForm.valueChanges
      .pipe(
        tap((values: any) => {
          const activeColumns = Object.keys(values)
            .filter((item: any) => values[item]);
          const updatedColumns = inActivePatientTableColumns
            .filter((item: any) => activeColumns.find((key: any) => key === item.column));
          this.displayedColumns = [...updatedColumns];
          return EMPTY;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onEditCarePlan(event: Event, patient: any) {
    event.stopPropagation();
    this.carePlanService.editCarePlanForInactivePatient$.next(patient);
  }

  // todo: update search feature for inactive tab
  emitSearchQuery(searchParams: { category: SearchCategory, query: string }): void {
    this.patients = SearchPatients({
      ...searchParams,
      patientListing: this.patientsCopy
        .map(MapPatientResourceToViewModel) as InActivePatientListing[],
    });
  }

  clearSearch(): void {
    this.patients = this.patientsCopy
      .map(MapPatientResourceToViewModel) as InActivePatientListing[];
  }

  private getPatients(): void {
    // reset inactive patients & show the loader
    this.patients = [];
    this.patientsCopy = [];
    this.showSpinner = true;

    this.patientService.getInactivePatientListing()
      .subscribe((patients: Patient[]) => {
        // to stop showing spinner if there are no patients
        if (patients !== null) {
          this.showSpinner = false;
        }

        this.patientsCopy = patients ?? [];
        this.patients = patients?.map(MapPatientResourceToViewModel) ?? [];
      });
  }
}
