import moment from 'moment';
import 'moment-timezone';
import { partition } from 'lodash';
import { Bundle, CarePlan, Condition, ContactPoint, Goal, Observation, Patient, PlanDefinition, Reference, ServiceRequest, Task } from "fhir/r4";
import ShortUniqueId from 'short-unique-id';

import { ActivePatientsComponent } from "../components/active-patients/active-patients.component";
import { InactivePatientsComponent } from "../components/inactive-patients/inactive-patients.component";
import { PendingPatientsComponent } from "../components/pending-patients/pending-patients.component";
import { ActivePatientListing, AddPatientModalFormValue, DiastolicBloodPressure, ExtendedPatient, InActivePatientListing, SummaryCard } from "../models/patient.model";
import { Diagnosis } from "../models/diagnosis.model";
import { FhirPatientResponse } from "../shared/models/fhir/patient/response/fhir-patient-response";
import { AlertTypeIcons, BloodPressureTexts, EstTimezone, PatientSummaryDateFormat, RPMDateFormat } from '../config/app.config';
import { assetUrl } from 'src/single-spa/asset-url';

const uid = new ShortUniqueId({ length: 12 });

// standalone task payload for creating patients
const PatientProgramEnrollmentTask = {
  "fullUrl": " ",
  "resource": {
    "resourceType": "Task",
    "status": "draft",
    "businessStatus": {
      "text": "awaiting enrollment"
    },
    "statusReason": {
      "text": "Patient's program enrollemnt pending from Clinician"
    },
    "intent": "order",
    "code": {
      "text": "Patient program enrollment" //todo: need to change text "Patient program enrollment"
    },
    "description": "task to track the patient program enrollment ",
    "for": {
      "type": "Patient",
      "reference": "urn:uuid:patient"
    }
  },
  "request": {
    "method": "POST",
    "url": "Task"
  }
};

// diagnosis template - update code.coding[] with system, display, code properties
const CreatePatientConditionPayload = {
  "fullUrl": " ",
  "resource": {
    "resourceType": "Condition",
    "clinicalStatus": {
      "coding": [
        {
          "system": "http://hl7.org/fhir/ValueSet/condition-clinical",
          "code": "active",
          "version": "4.0.1",
          "display": "Active"
        }
      ]
    },
    "category": [
      {
        "coding": [
          {
            "system": "http://hl7.org/fhir/ValueSet/condition-clinical",
            "code": "problem-list-item",
            "version": "4.0.1",
            "display": "Problem List Item"
          }
        ]
      }
    ],
    "code": {
      "coding": []
    },
    "subject": {
      "type": "Patient",
      "reference": "urn:uuid:patient"
    }
  },
  "request": {
    "method": "POST",
    "url": "Condition"
  }
};

const PatientProgramConsentTask = {
  "fullUrl": " ",
  "resource": {
    "resourceType": "Task",
    "status": "draft",
    "businessStatus": {
      "text": "consent requested"
    },
    "statusReason": {
      "text": "Consent requested for patients program enrollment"
    },
    "intent": "order",
    "code": {
      "text": "Patient program consent"
    },
    "description": "task to track the patient consent to the program ",
    "for": {
      // "reference": "Patient/4993de56-02c8-4552-ab3c-22e949b08de9"
      "reference": "",
      "type": "Patient"
    }
  },
  "request": {
    "method": "POST",
    "url": "Task"
  }
};

/**
 * If patient is of type ExtendedPatient return type is ActivePatientListing
 * If patient is of type Patient return type is InactivePatientListing
 * @param patient 
 * @returns 
 */
export const MapPatientResourceToViewModel = (patient: ExtendedPatient | Patient): ActivePatientListing | InActivePatientListing => {
  const id = patient?.id;
  if (!id) {
    throw new Error('Patient Id not available');
  }
  const patientName = `${patient?.name?.[0]?.given?.join(' ')} ${patient?.name?.[0]?.family}`;
  const dob = patient?.birthDate ?? '';
  const email = patient?.telecom?.find((t: ContactPoint) => t.system === 'email')?.value ?? '';
  const phone = patient?.telecom?.find((t: ContactPoint) => t.system === 'phone')?.value;
  // add extended patient properties
  // Note: status not present in type FHIR/R4 Patient resource; status column shown in pending/inactive listing
  const programName = (patient as ExtendedPatient)?.programName ?? '';
  const status = (patient as ExtendedPatient)?.status ?? '';
  const diagnosis = (patient as ExtendedPatient)?.diagnosis ?? '';
  const referredFrom = (patient as ExtendedPatient)?.referredFrom ?? '';
  const dischargeDate = (patient as ExtendedPatient)?.dischargeDate ?? '';
  return {
    id,
    patientName,
    dob,
    email,
    dischargeDate,
    action: '',
    // extended patient properties
    programName,
    // #R2-384
    status: status === "completed" ? "Discharged" : status,
    diagnosis,
    referredFrom,
  };
}

/**
 * Set component's mat table data source - get/search patient events
 * Maps patients property of provided component, Array<ExtendedPatient|Patient> to ActivePatientListing|InactivePatientListing
 */
export const SetPatientTableData = (
  componentRef: ActivePatientsComponent | PendingPatientsComponent | InactivePatientsComponent,
  patients: ExtendedPatient[] | Patient[],
): void => {
  componentRef.patients = patients?.length
    ? patients.map(MapPatientResourceToViewModel)
    : [];
}

export const MapViewModelToAddPatientApi = (patientInformation: AddPatientModalFormValue) => {
  const mappedPayload: any = {
    "resourceType": "Bundle",
    "type": "transaction",
    "entry": [
      {
        "fullUrl": "urn:uuid:patient",
        "resource": {
          "resourceType": "Patient",
          "identifier": [
            {
              "type": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
                    "code": "MR",
                    "version": "4.0.1",
                    "display": "Medical Record Number"
                  }
                ],
                "text": "Medical Record Number"
              },
              "system": "https://bayshore.ca/terminologies/vantage/medical-record-number",
              "value": patientInformation?.mrn ?? ''
            }
          ],
          "name": [
            {
              "family": patientInformation.lastName,
              "given": [
                patientInformation.firstName
              ]
            }
          ],
          "telecom": [
            {
              "system": "email",
              "value": patientInformation.email,
              "use": "home",
              "rank": 1
            },
            {
              "system": "sms",
              "value": patientInformation.phone,
              "use": "home",
              "rank": 2
            },
            {
              "system": "email",
              "value": patientInformation.iHealthEmail,
              "use": "temp",
              "rank": 3
            }
          ],
          "gender": patientInformation.gender,
          "address": [
            {
              "line": [
                patientInformation.address1,
                patientInformation.address2,
                patientInformation.apt,
              ],
              "postalCode": patientInformation.zip.toUpperCase(),
              "city": patientInformation.city,
              "state": patientInformation.province,
            }
          ],
          "communication": [
            {
              "language": {
                "coding": [
                  {
                    "system": "http://hl7.org/fhir/ValueSet/languages",
                    "code": "en-CA",
                    "version": "4.0.1",
                    "display": "English (Canada)"
                  }
                ]
              }
            }
          ],
          "birthDate": patientInformation.dateOfBirth
        },
        "request": {
          "method": "POST",
          "url": "Patient"
        }
      },
      PatientProgramEnrollmentTask
    ]
  };
  // update communication method - set preferred communication method with rank as 1
  if (patientInformation?.communicationMethod === 'sms') {
    const telecomTemplate = mappedPayload.entry[0].resource.telecom;
    let [email, phone, ...rest] = telecomTemplate;
    phone = {
      ...phone,
      rank: 1
    };
    email = {
      ...email,
      rank: 2
    };
    const updatedTelecom = [email, phone, ...rest];
    mappedPayload.entry[0].resource.telecom = updatedTelecom;
  }
  // update diagnosis payload code & display if it exists in the form value
  const updatedDiagnosis = patientInformation?.diagnosis?.length
    ? patientInformation.diagnosis
      .map((diagnosis: Diagnosis) => ({
        "system": "http://hl7.org/fhir/sid/icd-10-cm",
        "code": diagnosis.code,
        // #R2-561 need to longDisplay to show in diagnosis information
        "display": diagnosis.longDisplay
      }))
    : [];
  // add diagnosis information to payload
  if (updatedDiagnosis?.length) {
    const conditionTemplate: any = CreatePatientConditionPayload;
    conditionTemplate.resource.code.coding = updatedDiagnosis;
    mappedPayload.entry.push(conditionTemplate);
  }
  return mappedPayload;
}

export const MapViewModelToEnrollPatientToProgramApi = (
  {
    allGoals,
    formValue,
    patientId,
    chosenProgram,
    patientProgramEnrollmentTask = {} as Task,
    isInactivePatient = false,
  }: {
    allGoals: any,
    formValue: any,
    patientId: string,
    chosenProgram: PlanDefinition,
    patientProgramEnrollmentTask: Task,
    isInactivePatient: boolean,
  }
): any => {
  const { id: taskId = '' } = patientProgramEnrollmentTask;
  if (!taskId && !isInactivePatient) {
    throw new Error('Task id not available when enrolling patient to program');
  }
  const programEnrollmentTaskPayload = {
    fullUrl: " ",
    // for inactive patients create Task resource else use program enrollment Task already created for pending patient
    resource: {
      ...(
        isInactivePatient
          ? TaskResourceForInactivePatient(patientId)
          : patientProgramEnrollmentTask
      ),
      // when enrolling a patient to a program
      status: "in-progress",
      businessStatus: {
        text: "pending datasource linking"
      },
      statusReason: {
        text: "Patient program enrollment with a draft careplan"
      },
    },
    request: {
      method: isInactivePatient
        ? 'POST'
        : 'PUT',
      url: isInactivePatient
        ? 'Task'
        : `Task/${taskId}`
    }
  };

  const patientProgramConsentTask = {
    ...PatientProgramConsentTask,
    resource: {
      ...PatientProgramConsentTask.resource,
      for: {
        type: 'Patient',
        reference: `Patient/${patientId}`
      }
    }
  };

  const carePlan = {
    "fullUrl": "urn:uuid:careplan ",
    "resource": {
      "resourceType": "CarePlan",
      "status": "draft",
      "intent": "order",
      "title": chosenProgram.title,
      "description": formValue.programDescription,
      "subject": {
        "reference": `Patient/${patientId}`,
        "type": "Patient"
      },
      "period": {
        "start": new Date().toISOString()              // https://dxpbhc.atlassian.net/browse/R2-568
      },
      "created": chosenProgram.date,
      "goal": formValue.addMetrics.map((goal: Goal, index: number) => ({
        "type": "Goal",
        "reference": `urn:uuid:goal${index + 1}`
      })),
      "activity": [
        {
          "detail": {
            "status": "not-started",
            "instantiatesCanonical": [
              `https://baydevhdsws-bay-dev-fhir-01.fhir.azurehealthcareapis.com/PlanDefinition/${chosenProgram.id}`
            ],
            "description": chosenProgram.description
          }
        }
      ]
    },
    "request": {
      "method": "POST",
      "url": "CarePlan"
    }
  };

  const updatedGoals = formValue.tableForm.map((metric: any) => {
    const editedGoal = allGoals.find((m: any) => m.id === metric.goalId);
    // editedGoal.id = uid();
    editedGoal.target[0]['detailRange'].low.value = metric?.low || '';
    editedGoal.target[0]['detailRange'].high.value = metric?.high || '';
    const { meta, ...rest } = editedGoal;
    return rest;
  });
  const mappedGoals = updatedGoals.map((goal: any, index: number) => {
    return {
      fullUrl: `urn:uuid:goal${index + 1}`,
      resource: {
        resourceType: "Goal",
        // Note: lifecycleStatus should be active by default
        // for all the goals of the program patient is being enrolled to
        lifecycleStatus: 'active',
        description: {
          text: goal.description.text
        },
        target: goal.target,
        subject: {
          reference: `Patient/${patientId}`,
          type: 'Patient'
        }
      },
      request: {
        method: "POST",
        url: "Goal"
      }
    }
  });
  const mappedPayload: any = {
    "resourceType": "Bundle",
    "type": "transaction",
    "entry": []
  };
  mappedPayload.entry.push(programEnrollmentTaskPayload, carePlan, ...mappedGoals, patientProgramConsentTask);
  return mappedPayload;
}

export const TaskResourceForInactivePatient = (patientId: string) => ({
  resourceType: 'Task',
  intent: "order",
  code: {
    text: "Patient program enrolment"
  },
  description: "task to track the patient program enrollment ",
  for: {
    reference: `Patient/${patientId}`,
    type: "Patient"
  }
});

/**
 * Comma separated patient ids used in POST payload of second API call in patient listing
 * @returns Comma separated patient ids
 */
export const GetPatientIdsAsString = (patients: Patient[]): string => {
  if (!patients?.length) {
    return '';
  }
  return patients
    .map((patient: Patient) => patient?.id)
    .filter(Boolean)
    .join(',');
}

export const GetPatientIdsAsArray = (patients: Patient[]): string[] => {
  if (!patients?.length) {
    return [];
  }
  return patients
    .map(({ id = '' }: Patient) => id)
    .filter(Boolean);
}

/**
 * Payload for making a bundled request to retrieve conditions & serviceRequests
 * @param patientIds Comma separated patient ids
 * @returns Bundle with n items entry[]  
 * Conditions (diagnosis) in bundle.entry[0].resource.entry &
 * ServiceRequests (referredFrom) in bundle.entry[1].resource.entry
 */
export const MapPatientIdsToConditionServiceRequestPayload = (patientIds: string): Bundle => ({
  "resourceType": "Bundle",
  "type": "transaction",
  "entry": [
    {
      "request":
      {
        "method": "GET",
        "url": `/Condition?patient:Patient._id=${patientIds}&_count=${patientIds.split(",").length}`// #R2-476 - bug -fix
      }
    },
    {
      "request":
      {
        "method": "GET",
        "url": `/ServiceRequest?patient:Patient._id=${patientIds}&_sort=-_lastUpdated`
      }
    },
    {
      "request": {
        "method": "GET",
        "url": `/Task?subject=${patientIds}&code:text=Patient program enrolment&status=completed&_count=1000`
      }
    }
  ]
});

/**
 * Checks whether the resource is associated with a patient
 */
export const IsResourceOfPatient = ({ subject = {} }: CarePlan | Condition | ServiceRequest, patientId: string): boolean => {
  if (!subject?.reference) {
    return false;
  }
  return subject.reference.includes(patientId);
}

/**
 * Checks whether patient program enrollment task is associated with a patient
 * @param task Patient program enrollment task
 * @param patientId 
 */
export const IsTaskOfPatient = (task: Task, patientId: string): boolean => {
  if (!task?.for?.reference) {
    return false;
  }
  return task.for.reference.includes(patientId);
}

/**
 * Mapping a patient with extended properties - programName, referredFrom, diagnosis, status
 */
export const MapPatientToExtendedPatient = (
  patients: Patient[],
  carePlans: CarePlan[],
  conditions: Condition[],
  serviceRequests: ServiceRequest[],
  tasks?: Task[]
): ExtendedPatient[] => {
  if (!patients?.length) {
    return [];
  }
  return patients.map((patient: Patient) => {
    const patientId = patient?.id;
    if (!patientId) {
      throw new Error('Patient Id not available');
    }
    // set careplan or program name
    // Note: patient will have only one associated carePlan in phase 1
    const selectedCarePlan = carePlans.find(carePlan => IsResourceOfPatient(carePlan, patientId));

    // set diagnosis or conditions
    const diagnosis: string = conditions
      .filter(condition => IsResourceOfPatient(condition, patientId))
      .map((condition: Condition) => condition.code?.coding?.map(coding => coding.display))
      .join(', ');

    // set referredFrom or service requests
    const _serviceRequests = serviceRequests
      .find(serviceRequest => IsResourceOfPatient(serviceRequest, patientId));

    const status = selectedCarePlan?.status;

    const dischargeDate = selectedCarePlan?.meta?.lastUpdated ? moment(selectedCarePlan?.meta?.lastUpdated).format('YYYY-MM-DD') : null;

    // active/pending patients will have task resources to identify referrals
    // for pending patients - status is extracted from businessStatus of patient program enrollment task
    const patientTask = tasks?.length
      ? tasks.find((task: Task) => IsTaskOfPatient(task, patientId))
      : null;
    const taskInput = patientTask?.input?.length
      ? patientTask?.input[0].type as any
      : null;

    return {
      ...patient,
      programName: selectedCarePlan?.title ?? '',
      referredFrom: !!taskInput
        ? `${taskInput?.text}: ${taskInput.coding[0].display}`
        : '-',
      diagnosis,
      status: !!patientTask
        ? patientTask?.businessStatus?.text ?? ''
        : status,
      dischargeDate
    };
  });
}

/**
 * Map Edit patient data along with patient's diagnosis to FHIR Bundle payload 
 * for PATCH request to update the patient details in patient service.
 * @param patientId - patient id 
 * @param existingPatient - existing patient 
 * @param data - updated patient details
 * @param conditionId - FHIR condition resource id (for diagnosis)
 * @returns Bundle
 */
export const MapUpdatePatientDataToPatchModel = (
  patientId: string,
  existingPatient: FhirPatientResponse,
  data: Partial<AddPatientModalFormValue>,
  conditionId: string | null,
): Bundle => {
  const patchPayload: Array<{ op: string, path: string, value: any }> = [];
  let telecom = [
    {
      "system": "email",
      "value": data.email ? data.email : existingPatient.email,
      "use": "home",
      "rank": 1
    },
    {
      "system": "sms",
      "value": data.phone ? data.phone : existingPatient.phone,
      "use": "home",
      "rank": 2
    },
    {
      "system": "email",
      "value": data.iHealthEmail ? data.iHealthEmail : existingPatient.iHealthEmail,
      "use": "temp",
      "rank": 3
    }
  ];
  telecom = telecom.filter((item) => item.value);

  // set the preferred communication method
  const communicationMethod = data?.communicationMethod ?? existingPatient.communicationMethod;
  if (communicationMethod === 'sms') {
    let [email, phone, ...rest] = telecom;
    phone = {
      ...phone,
      rank: 1
    };
    email = {
      ...email,
      rank: 2
    };
    telecom = [email, phone, ...rest];
  }

  patchPayload.push({
    op: 'add',
    path: "/telecom",
    value: telecom
  });

  if (data.firstName || data.firstName === '') {
    patchPayload.push({
      op: existingPatient.firstName ? 'replace' : 'add',
      path: "/name/0/given",
      value: data.firstName
    });
  }

  if (data.lastName || data.lastName === '') {
    patchPayload.push({
      op: existingPatient.lastName ? 'replace' : 'add',
      path: "/name/0/family",
      value: data.lastName
    });
  }

  if (data.gender) {
    patchPayload.push({
      op: existingPatient.gender ? 'replace' : 'add',
      path: "/gender",
      value: data.gender
    });
  }

  if (data.dateOfBirth || data.dateOfBirth === '') {
    patchPayload.push({
      op: existingPatient.dateOfBirth ? 'replace' : 'add',
      path: "/birthDate",
      value: data.dateOfBirth
    });
  }

  if (data.address1 || data.address1 === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.addressLine1 ? 'replace' : 'add',
      path: "/address/0/line/0",
      value: data.address1
    });
  }

  if (data.address2 || data.address2 === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.addressLine2 ? 'replace' : 'add',
      path: "/address/0/line/1",
      value: data.address2
    });
  }

  if (data.apt || data.apt === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.apt ? 'replace' : 'add',
      path: "/address/0/line/2",
      value: data.apt
    });
  }

  if (data.zip || data.zip === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.postalCode ? 'replace' : 'add',
      path: "/address/0/postalCode",
      value: data.zip
    });
  }

  if (data.city || data.city === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.city ? 'replace' : 'add',
      path: "/address/0/city",
      value: data.city
    });
  }

  if (data.province || data.province === '') {
    patchPayload.push({
      op: existingPatient.address?.[0]?.state ? 'replace' : 'add',
      path: "/address/0/state",
      value: data.province
    });
  }

  if (data.mrn || data.mrn === '') {
    patchPayload.push({
      op: existingPatient.mrn ? 'replace' : 'add',
      path: "/identifier/0/", // changed index from 1 to 0, because the MR value placed at 0th index in add patient payload
      value: {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
              "code": "MR",
              "display": "Medical Record Number"
            }
          ],
          "text": "Medical Record Number"
        },
        "system": "http://hospital.smarthealthit.org",
        "value": data.mrn
      }
    });
  }

  // todo : Change btoa usage, btoa is depricated 
  const encodedPatientPatch = btoa(JSON.stringify(patchPayload));

  const bundleEntries = [];
  bundleEntries.push(
    {
      "fullUrl": `Patient/${patientId}`,
      "resource": {
        "resourceType": "Binary",
        "contentType": "application/json-patch+json",
        "data": encodedPatientPatch
      },
      "request": {
        "method": "PATCH",
        "url": `Patient/${patientId}`
      }
    }
  );

  if (data.diagnosis?.length) {
    if (!conditionId) {
      const diagnosisPatch: any = { ...CreatePatientConditionPayload };
      diagnosisPatch.resource.code = {
        coding: data.diagnosis?.map((diagnosis) => {
          return {
            "system": "http://hl7.org/fhir/sid/icd-10-cm",
            "code": diagnosis.code,
            // #R2-561 need to longDisplay to show in diagnosis information
            "display": diagnosis.longDisplay
          }
        })
      };
      diagnosisPatch.resource.subject.reference = `Patient/${patientId}`;
      bundleEntries.push(
        {
          "fullUrl": ``,
          "resource": diagnosisPatch.resource,
          "request": {
            "method": "PUT",
            "url": `Condition?subject:Patient=${patientId}&clinical-status=active&category=problem-list-item`
          }
        }
      );
    } else {
      const diagnosisPatch = [{
        op: 'add',
        path: "/code",
        value: {
          "coding": data.diagnosis?.map((diagnosis) => {
            return {
              "system": "http://hl7.org/fhir/sid/icd-10-cm",
              "code": diagnosis.code,
              // #R2-561 need to longDisplay to show in diagnosis information
              "display": diagnosis.longDisplay
            }
          })
        }
      }];
      const encodedDiagnosisPatch = btoa(JSON.stringify(diagnosisPatch));

      bundleEntries.push(
        {
          "fullUrl": `Condition/${conditionId}`,
          "resource": {
            "resourceType": "Binary",
            "contentType": "application/json-patch+json",
            "data": encodedDiagnosisPatch
          },
          "request": {
            "method": "PATCH",
            "url": `Condition/${conditionId}`
          }
        }
      );
    }
  } else if (data.diagnosis) {
    const diagnosisPatch = [{
      op: 'add',
      path: "/code",
      value: {
        "coding": []
      }
    }];
    const encodedDiagnosisPatch = btoa(JSON.stringify(diagnosisPatch));

    bundleEntries.push(
      {
        "fullUrl": `Condition/${conditionId}`,
        "resource": {
          "resourceType": "Binary",
          "contentType": "application/json-patch+json",
          "data": encodedDiagnosisPatch
        },
        "request": {
          "method": "PATCH",
          "url": `Condition/${conditionId}`
        }
      }
    );
  }

  const bundlePayload = {
    "resourceType": "Bundle",
    "id": "bundle-batch",
    "type": "transaction",
    "entry": bundleEntries
  };

  return bundlePayload as Bundle;
}

/**
 * Extract patient id and patientProgramEnrollmentTask from response returned from addPatient API 
 * @param fhirResponse FHIR response after creating a new patient
 */
export const GetPatientIdTaskId = (fhirResponse: Bundle): { patientId: string, taskId: string } => {
  let patientId!: string, patientProgramEnrollmentTask!: Task;

  if (!fhirResponse?.entry?.length) {
    throw new Error('Add Patient API failed');
  }

  fhirResponse.entry.forEach((entry: any) => {
    if (entry.resource.resourceType === 'Patient') {
      patientId = entry.resource.id;
    }
    if (entry.resource.resourceType === 'Task') {
      patientProgramEnrollmentTask = entry.resource;
    }
  });
  if (!patientId || !patientProgramEnrollmentTask?.id) {
    throw new Error('Newly created patient id/task id is not available');
  }
  return {
    patientId,
    taskId: patientProgramEnrollmentTask.id
  };
}

/**
 * After creating patient FHIR resource, update the status of patient program enrollment task from draft to ready
 */
export const MapPatientProgramEnrollmentTaskToReady = (patientId: string, taskId: string): Task => {
  if (!taskId) {
    throw new Error('Task id of patient program enrollment task not available');
  }
  return {
    resourceType: "Task",
    id: taskId,
    status: "ready",
    statusReason: {
      text: "Patient's program enrollement pending"
    },
    businessStatus: {
      text: "awaiting enrollment"
    },
    intent: "order",
    code: {
      text: "Patient program enrolment"
    },
    description: "task to track the patient enrollment to the RPM program",
    for: {
      reference: `Patient/${patientId}`,
      type: "Patient"
    }
  };
}


export const MapPatientProgramEnrollmentTaskToCompleted = (patientId: string, task: Task): Task => ({
  id: task.id,
  input: task.input,// #R2-527 - need to keep input source & destination of patient
  resourceType: 'Task',
  status: 'completed',
  businessStatus: {
    text: 'Device Account synced'
  },
  statusReason: {
    text: 'data source linkage for patient vital\'s streaming & monitoring'
  },
  intent: 'order',
  code: {
    text: 'Patient program enrolment'
  },
  description: 'task to track the patient program enrollment ',
  for: {
    reference: `Patient/${patientId}`,
    type: 'Patient'
  }
});

/**
 * Patient summary page card mapping
 * @param goals 
 * @param observations 
 * @param tasks
 */
export const MapGoalsToSummaryCards = (
  goals: Goal[],
  observations: Array<Observation[]>,
  tasks: Task[]
): SummaryCard[] => {
  if (!goals?.length || !observations?.length) {
    return [];
  }
  return goals.map((goal: any, index: number) => {
    if (!observations[index][0]) {
      return null;
    }
    // observation props
    const { id: observationId = '', effectiveDateTime = '', valueQuantity: { value: latestObservationValue = 0, unit = '' } = {}, code, component = [] }
      = observations[index][0];
    if (!observationId) {
      throw new Error('No observation id available');
    }
    const alertCode = code.coding![0].code;
    if (!alertCode) {
      throw new Error('No alert code available');
    }
    const goalCode = code?.coding
      ? code.coding[0].code
      : '';
    if (!goalCode) {
      throw new Error('Goal\'s unique code not available');
    }
    // dynamic path to alert icon in assets folder based on unique code
    const alertIcon = assetUrl(`${AlertTypeIcons[alertCode].icon}.jpg`);
    // goal props
    const goalName = goal.description.text;
    const detailRange = goal.target[0].detailRange;
    const { low: { value: lowGoalValue }, high: { value: highGoalValue } } = detailRange;
    // task props
    const relevantGoalTask = tasks
      ?.filter(
        (task: Task) => task.basedOn?.length &&
          !!(task.basedOn.filter((ref: Reference) => ref?.reference!.includes(observationId))?.length)
          // should be check new alerts
          && task.status === 'ready'
      );
    const { description: alertMessage = '' } = relevantGoalTask?.length
      ? relevantGoalTask[0]
      : {};

    // get systolic/diastolic blood pressure values if the observation has component[]
    let systolicValue = null, diastolicValue = null;
    if (component?.length) {
      const systolic = component[0];
      const diastolic = component[1];
      if (!Object.keys(systolic)?.length || !Object.keys(diastolic)?.length) {
        throw new Error('Systolic/diastolic values not available in observation');
      }
      systolicValue = systolic.valueQuantity?.value;
      diastolicValue = diastolic.valueQuantity?.value;
    }
    return {
      // goal props
      goalName,
      lowGoalValue,
      highGoalValue,
      goalCode,
      // observation props (from latest observation)
      lastUpdated: effectiveDateTime,
      latestObservationValue,
      // if observation has component[], it is for blood pressure
      unit: component?.length
        ? 'mmHg'
        : unit,
      alertIcon,
      // task props
      alertMessage,
      // ui prop to highlight alert
      hasAlert: !!relevantGoalTask?.length,
      ...(component?.length && { systolicValue, diastolicValue }),
    };
  })
    .filter(Boolean) as SummaryCard[];
}

/**
 * Consolidate systolic & diastolic cards into one
 * Note: Goal codes of both systolic and diastolic are the same: 85354-9
 * @param summaryCards 
 * @param bloodPressureGoalCode 
 * @returns 
 */
export const ConsolidateBloodPressureGoals = (
  summaryCards: SummaryCard[],
  bloodPressureGoalCode: string
): { cards: SummaryCard[], hasBloodPressureCard: boolean } => {
  const [bloodPressureCards, otherCards]: [SummaryCard[], SummaryCard[]] = partition(
    summaryCards,
    (summaryCard: SummaryCard) => summaryCard.goalCode === bloodPressureGoalCode
  );
  // bloodPressureCards[] should have length of either 0 if there's no active blood pressure goal for the patient
  // or 2 since systolic & diastolic are entered together
  if (bloodPressureCards.length === 0) {
    return {
      cards: summaryCards,
      hasBloodPressureCard: false,
    };
  }
  if (bloodPressureCards.length === 1) {
    throw new Error('Only one blood pressure goal available');
  }
  const [[systolicCard], [diastolicCard]] = partition(
    bloodPressureCards,
    (bloodPressureCard: SummaryCard) => bloodPressureCard.goalName.toLowerCase() === BloodPressureTexts.systolic
  );
  // diastolic observation value is already inside systolic card since it is a pair
  // add diastolic observation value, diastolic threshold values to diastolic property in systolic card
  const { diastolicValue: diastolicObservationValue, hasAlert: hasSystolicAlert, ...systolicRest } = systolicCard;
  const { lowGoalValue: diastolicLowValue, highGoalValue: diastolicHighValue, goalName: diastolicGoalName, hasAlert: hasDiastolicAlert } = diastolicCard;
  if (!diastolicObservationValue || !diastolicLowValue || !diastolicHighValue) {
    throw new Error('Diastolic observation value or diastolic goal\'s threshold values not found');
  }
  const bloodPressureCard: SummaryCard = {
    hasAlert: hasSystolicAlert && hasDiastolicAlert,
    ...systolicRest,
    diastolic: {
      diastolicGoalName,
      diastolicObservationValue,
      diastolicLowValue,
      diastolicHighValue
    }
  };
  return {
    cards: [
      ...otherCards,
      {
        ...bloodPressureCard,
        hasAlert: isBloodPressureAlerted(bloodPressureCard)
      }
    ],
    hasBloodPressureCard: true,
  };
}

export const isBloodPressureAlerted = (bloodPressureReadings: SummaryCard): boolean => {
  const {
    lowGoalValue: systolicLowValue,
    highGoalValue: systolicHighValue,
    systolicValue: systolicObservationValue,
    hasAlert,
    diastolic = {} as DiastolicBloodPressure,
  } = bloodPressureReadings;
  if (!systolicObservationValue) {
    throw new Error('No systolic observation value found');
  }
  if (systolicObservationValue < systolicLowValue || systolicObservationValue > systolicHighValue) {
    return true && hasAlert;
  }
  if (!Object.keys(diastolic).length) {
    throw new Error('Diastolic readings are not found');
  }
  const {
    diastolicObservationValue,
    diastolicLowValue,
    diastolicHighValue,
  } = diastolic;
  if (diastolicObservationValue < diastolicLowValue || diastolicObservationValue > diastolicHighValue) {
    return true && hasAlert;
  }
  return false;
}

export const MapCarePlansToPatientIds = (carePlans: CarePlan[]): string[] => {
  return carePlans
    .map(({ subject = {} }: CarePlan) => {
      if (!Object.getOwnPropertyNames(subject).length) {
        return;
      }
      const { reference = '' } = subject;
      if (!reference?.startsWith('Patient/')) {
        return;
      }
      return reference.split('/').at(1);
    })
    .filter(Boolean) as string[];
}