import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcknowledgeDialogData } from 'src/app/models/matDialogData.model';
import { PatientService } from 'src/app/services/patient.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'patient-app-acknowledge-dialog',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.scss']
})
export class AcknowledgeDialogComponent implements OnInit {
  formGroup!: FormGroup;
  alertType!: string;
  reportedValue!: number;
  alertUnit!: string;
  alertTime!: string;
  alertDate!: string;
  observationId!: string;

  // todo remove dev flag
  isDevEnvironment = environment?.name === 'dev';

  // todo fetch calcom event type's data-cal-link value from API
  dataCalLink = 'team/programa/virtual-visits-rooms';
  dataCalLinkQuery!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: AcknowledgeDialogData,
    private dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
  ) { 
    this.formGroup = this.formBuilder.group({
      'commentControl': ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const { alertType, reportedValue, alertUnit, alertTime, alertDate, observationId } = this.dialogData;
    this.alertType = alertType;
    this.reportedValue = reportedValue;
    this.alertUnit = alertUnit;
    this.alertTime = alertTime;
    this.alertDate = alertDate;
    this.observationId = observationId;

    this.patientService.currentPatient
      .subscribe({
        next: (patient) => {
          if (!patient) {
            throw new Error('Patient details not found for calcom embed popup');
          }
          const { firstName, lastName, email } = patient;
          this.dataCalLinkQuery = `${this.dataCalLink}?name=${firstName} ${lastName}&email=${email}&Observation=${this.observationId}`;
          console.log({ dataCalLinkQuery: this.dataCalLinkQuery });
        },
        error: () => { 
          throw new Error('Patient details not found for calcom embed popup');
        }
      });
  }

  closeDialog(): void {
    this.dialogRef.close({
      newComment: this.formGroup.value.commentControl,
    });
  }
}
