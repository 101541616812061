import moment from 'moment';
import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { Bundle, CarePlan, Goal, Observation, Task } from 'fhir/r4';
import { Observable, EMPTY, forkJoin, of, Subject, switchMap, takeUntil, catchError, combineLatest, filter, tap, timer, retry } from 'rxjs';
import { API_POLLING_DURATION, DefaultDialogProperties, FhirResourceType, GoalCodes, GoalTitles, PatientSummaryDateFormat, RPMDateFormat, spinnerProperties } from 'src/app/config/app.config';
import { ConsolidateBloodPressureGoals, MapGoalsToSummaryCards } from 'src/app/mappers/patient.mapper';
import { MapBundleToResourceArray, MapBundleToResourceArrays } from 'src/app/mappers/shared.mapper';
import { MapTasksToAlertListing } from 'src/app/mappers/task.mapper';
import { AcknowledgeDialogData, AddCommentDialogData } from 'src/app/models/matDialogData.model';
import { AlertHistory, SummaryCard } from 'src/app/models/patient.model';
import { AcknowledgeModalFormValue, AddCommentModalFormValue, AlertFilters, AlertStatuses, AlertType, ObservationsTasks } from 'src/app/models/task.model';
import { CarePlanService } from 'src/app/services/careplan.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ObservationService } from 'src/app/services/observation.service';
import { TaskService } from 'src/app/services/task.service';
import { AcknowledgeDialogComponent } from '../acknowledge-dialog/acknowledge-dialog.component';
import { AddCommentsDialogComponent } from '../add-comments-dialog/add-comments-dialog.component';
import { ShowLoaderService } from 'src/app/services/show-loader.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { PatientService } from 'src/app/services/patient.service';
import { FilterAlertType } from 'src/app/models/powerbi.model';

@Component({
  selector: 'patient-app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  @ViewChild(MatMenuTrigger) alertFilterDropdownMenu!: MatMenuTrigger;
  @ViewChild('alertListingTable') alertListingTable!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  private initiateAddAcknowledgement$ = new Subject<AlertHistory>();
  private initiateAddComment$ = new Subject<AlertHistory>();
  private reloadObservationsAlertListing$ = new Subject<void>();
  private observationsTasks$ = new Subject<ObservationsTasks>();
  private alertFilters$ = new Subject<Partial<AlertFilters>>();
  private patientId!: string;
  private patientStatus!: string;
  private carePlanId!: string;
  private loggedInUsername!: string;
  private selectedAlert!: AlertHistory;
  private bloodPressureGoalCode = GoalCodes.BLOOD_PRESSURE;
  isCardsLoading!: boolean;
  defaultSpinnerProperties = spinnerProperties;
  cardListing: SummaryCard[] = [];
  // alerts history props
  isAlertListLoading!: boolean;
  alertListing: AlertHistory[] = [];
  displayedColumns = [
    { column: 'alertDate', title: 'Date' },
    { column: 'alertType', title: 'Alert Type' },
    { column: 'reportedValue', title: 'Reported Value' },
    { column: 'alertDuration', title: 'Alert Duration' },
    { column: 'alertStatus', title: 'Alert Status' },
    { column: 'acknowledgedBy', title: 'Acknowledged By' },
    { column: 'dateAcknowledged', title: 'Date Acknowledged' },
    { column: 'action', title: 'Comments' },
  ];
  alertStatuses: Array<{ value: string, text: string }> = AlertStatuses;
  alertDate!: string;
  alertType!: string;
  alertStatus!: string;
  alertTypes!: AlertType[];
  // to set dynamic height of cards
  isBloodPressureCardShown = false;
  patientSummaryDateFormat = PatientSummaryDateFormat;
  updatedAlertTypes: AlertType[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private carePlanService: CarePlanService,
    private observationService: ObservationService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private authService: MsalService,
    private notificationService: NotificationService,
    private showLoaderService: ShowLoaderService,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    // get logged in user's name
    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      const account = accounts[0];
      this.authService.instance.setActiveAccount(accounts[0]);
      const userProfile: any = account.idTokenClaims;
      this.loggedInUsername = `${userProfile?.given_name ?? ''} ${userProfile?.family_name ?? ''}`;
    }

    // load patient summary cards
    // get patient id, get patient's active carePlans/goals
    // get observations/tasks using unique goal code & map them to summary cards data
    combineLatest([
      this.route.params,
      this.reloadObservationsAlertListing$,
    ])
      .pipe(
        tap(() => {
          this.isCardsLoading = true;
          this.isAlertListLoading = true;
          this.cardListing = [];
          this.alertListing = [];
        }),
        switchMap(([params]: any) => {
          if (!params.id) {
            throw new Error('Invalid Patient Id');
          }
          this.patientId = params.id;
          return of(this.patientId);
        }),
        switchMap((patientId: string) => this.patientService.isPatientActive(patientId)),
        switchMap((patientStatus: boolean) => {
          // R2-849 - if the patient discharged at that time we have to use status completed
          // R2-1116 - handled route without status if the user navigate from dashboard & email alert notication due to #R2-849

          /**
           * R2-1146
           * params.status could be 'active' or 'inactive' depending on whether the clinician is routed to patient summary page from active/inactive patient tabs
           * When onboarding already discharged patient (Patient A), params.status will be 'inactive',
           * but since Patient A is enrolled into another program, patient status will be 'active' which is causing R2-1146 bug.
           * Inorder to decouple patient's status from param.status, API call is made to fetch patient's latest status.
           */
          this.patientStatus = patientStatus ? 'active' : 'completed';
          return this.carePlanService.getPatientCarePlanGoals(this.patientId, this.patientStatus);
        }),
        switchMap((bundle: Bundle<CarePlan | Goal>) => {
          // note: only one carePlan for a patient in phase one
          const { resource1: carePlans, resource2: goals } = MapBundleToResourceArrays<CarePlan, Goal>(bundle, FhirResourceType.CarePlan, FhirResourceType.Goal);
          this.carePlanId = carePlans[0].id ?? '';
          if (!this.carePlanId) {
            throw new Error('Careplan id undefined');
          }
          this.alertTypes = goals
            .map((goal: any) => {
              const goalCode = goal?.target[0].measure?.coding[0].code;
              if (!goalCode) {
                return null;
              }
              return {
                goalTitle: goal?.description?.text ?? '',
                goalCode,
              };
            })
            .filter(Boolean) as AlertType[];
          // R2-702: Combine systolic and diastolic into Blood Pressure
          this.updatedAlertTypes = this.combineBloodPressureAlerts(this.alertTypes);

          // https://dxpbhc.atlassian.net/browse/R2-568
          // if (!carePlans[0]?.period?.start) {
          //   throw new Error('Careplan does not have start date');
          // }
          // https://dxpbhc.atlassian.net/browse/R2-568
          const observationsRequests = !this.alertTypes.length
            ? []
            : this.alertTypes.map((alertType: AlertType) => this.observationService.getGoalObservations(this.patientId, alertType.goalCode, carePlans[0]));
          return timer(0, API_POLLING_DURATION).pipe(
            switchMap(() => {
              // #R2-1117 - Handled alert date picker value
              let authoredOn = this.alertDate;
              if (authoredOn) {
                authoredOn = `ap${moment(authoredOn).format(RPMDateFormat)}`;
              }
              else {
                if (carePlans[0]?.period?.start) {
                  authoredOn = `ge${carePlans[0]?.period?.start}`;
                }
              }
              return forkJoin([
                of(goals),
                // #R2-1117 - Handled alert filters
                this.taskService.getFilteredPatientTasks(this.patientId, {authoredOn, code: FilterAlertType.ALL, status: this.alertStatus}, this.carePlanId),
                ...observationsRequests,
              ]);
            }),
            retry({
              count: 2,
              delay: 1500
            })
          )
        }),
        switchMap(([goals, tasksBundle, ...observationsBundle]) => {
          const tasks: Task[] = MapBundleToResourceArray<Task>(tasksBundle);
          const observations: Array<Observation[]> = observationsBundle
            .map((bundle: any) => MapBundleToResourceArray<Observation>(bundle));
          this.observationsTasks$.next({
            observations: observations.flatMap(v => v),
            tasks
          });
          const summaryCards = MapGoalsToSummaryCards(goals, observations, tasks);
          const { cards, hasBloodPressureCard } = ConsolidateBloodPressureGoals(summaryCards, this.bloodPressureGoalCode);
          this.cardListing = cards;
          this.isBloodPressureCardShown = hasBloodPressureCard;
          this.isCardsLoading = false;
          return EMPTY;
        }),
        retry({
          count: 3,
          delay: 5000
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // emit reload to invoke initial API calls
    this.reloadObservationsAlertListing$.next();

    // load alert history listing table
    this.observationsTasks$
      .pipe(
        tap(() => {
          this.isAlertListLoading = true;
          this.alertListing = [];
        }),
        switchMap(({ observations, tasks }: { observations: Observation[], tasks: Task[] }) => {
          // R2-1358: filter out the tasks based on current alert type filter applied
          const filteredTasks = this.alertType !== FilterAlertType.ALL
            ? tasks.filter((task: Task) => task.code?.coding?.[0].code === this.alertType)
            : tasks;
          if (!filteredTasks?.length) {
            this.isAlertListLoading = false;
            return of([]);
          }
          this.alertListing = MapTasksToAlertListing(filteredTasks, this.alertTypes);
          this.isAlertListLoading = false;
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    // initiate add acknowledgement process
    this.initiateAddAcknowledgement$
      .pipe(
        switchMap((modalInput: AlertHistory) => {
          this.selectedAlert = modalInput;
          return combineLatest([
            of(modalInput),
            this.openAddAcknowledgementModal(modalInput),
          ]);
        }),
        filter(([modalInput, { newComment }]: [AlertHistory, AcknowledgeModalFormValue]) => !!newComment),
        switchMap(([modalInput, { newComment }]: [AlertHistory, AcknowledgeModalFormValue]) => {
          const { taskId, observationId, taskDescription, code, input, authoredOn } = modalInput;
          this.showLoaderService.isShowLoadingSectionVisible$.next(true);
          return this.taskService.updateTaskResourceToAcknowledged({
            taskId,
            newComment,
            observationId,
            taskDescription,
            code,
            input,
            patientId: this.patientId,
            carePlanId: this.carePlanId,
            loggedInUsername: this.loggedInUsername,
            authoredOn,
          });
        }),
        switchMap(() => {
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          this.reloadObservationsAlertListing$.next();
          this.notificationService.showNotification('Alert acknowledged', 'success', 'Ok');
          this.setDefaultValueForFilters();
          return EMPTY;
        }),
        catchError(() => {
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          // re open acknowledge modal with previously entered new comment
          this.initiateAddAcknowledgement$.next(this.selectedAlert);
          this.notificationService.showNotification('Alert acknowledgement failed', 'error', 'Ok');
          this.setDefaultValueForFilters();
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // initiate add comment process
    this.initiateAddComment$
      .pipe(
        switchMap((modalInput: AlertHistory) => {
          this.selectedAlert = modalInput;
          return combineLatest([
            of(modalInput),
            this.openAddCommentModal(modalInput),
          ]);
        }),
        filter(([modalInput, { newComment }]: [AlertHistory, AddCommentModalFormValue]) => !!newComment),
        switchMap(([modalInput, { newComment }]: [AlertHistory, AcknowledgeModalFormValue]) => {
          const { taskId, observationId, taskDescription, note: oldNote = [],
            code, input, authoredOn, executionPeriod } = modalInput;
          this.showLoaderService.isShowLoadingSectionVisible$.next(true);
          return this.taskService.updateTaskResourceToAcknowledged({
            taskId,
            newComment,
            observationId,
            taskDescription,
            oldNote,
            code,
            input,
            patientId: this.patientId,
            carePlanId: this.carePlanId,
            loggedInUsername: this.loggedInUsername,
            authoredOn,
            isAddComment: true,
            executionPeriodEndDate: executionPeriod?.end,
          });
        }),
        switchMap(() => {
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          this.reloadObservationsAlertListing$.next();
          this.notificationService.showNotification('Alert acknowledged', 'success', 'Ok');
          this.setDefaultValueForFilters();
          return EMPTY;
        }),
        catchError(() => {
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          // re open add comment modal with previously entered new comment
          this.initiateAddAcknowledgement$.next(this.selectedAlert);
          this.notificationService.showNotification('Alert acknowledgement failed', 'error', 'Ok');
          this.setDefaultValueForFilters();
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // apply filters to alert history listing
    this.alertFilters$
      .pipe(
        tap(() => {
          this.isAlertListLoading = true;
          this.alertListing = [];
        }),
        switchMap((alertFilters: Partial<AlertFilters>) => this.taskService.getFilteredPatientTasks(this.patientId, alertFilters, this.carePlanId)),
        switchMap((tasksBundle: Bundle<Task>) => {
          const tasks: Task[] = MapBundleToResourceArray<Task>(tasksBundle);
          if (!tasks?.length) {
            this.isAlertListLoading = false;
            return of([]);
          }
          this.alertListing = MapTasksToAlertListing(tasks, this.alertTypes);
          this.isAlertListLoading = false;
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.setDefaultValueForFilters();
  }

  /**
   * Set default values of alert filter
   */
  setDefaultValueForFilters(): void {
    this.alertDate = '';
    this.alertType = FilterAlertType.ALL;
    // #R2-1115, R2-764 & R2-849 handled task resrc status for both active & inactive tab as it previously works only for inactive tab
    this.alertStatus = this.patientStatus && this.patientStatus !== 'active' ? 'completed' : 'ready';
  }

  onNotAcknowledgedClicked(event: Event, alertDetails: AlertHistory): void {
    event.stopPropagation();
    this.initiateAddAcknowledgement$.next(alertDetails);
  }

  /**
   * Show add acknowledge modal
   */
  private openAddAcknowledgementModal(alertDetails: AlertHistory): Observable<any> {
    return this.dialog.open<AcknowledgeDialogComponent, AcknowledgeDialogData>(
      AcknowledgeDialogComponent,
      {
        ...DefaultDialogProperties,
        data: {
          ...alertDetails,
        }
      }
    )
      .afterClosed();
  }

  /**
   * Show add comment modal
   */
  private openAddCommentModal(alertDetails: AlertHistory): Observable<any> {
    const { note: oldNotes = [] } = alertDetails;
    return this.dialog.open<AddCommentsDialogComponent, AddCommentDialogData>(
      AddCommentsDialogComponent,
      {
        ...DefaultDialogProperties,
        data: {
          oldNote: oldNotes,
        }
      }
    )
      .afterClosed();
  }

  onFilterApply({ value: formValue }: { value: AlertFilters }): void {
    const { authoredOn } = formValue;
    if (authoredOn) {
      formValue.authoredOn = `ap${moment(authoredOn).format(RPMDateFormat)}`;
    }
    this.alertFilters$.next(formValue);
    this.alertFilterDropdownMenu.closeMenu();
  }

  onAddAlertNote(event: Event, alertHistory: AlertHistory): void {
    event.stopPropagation();
    this.initiateAddComment$.next(alertHistory);
  }

  filterAlertListingBasedOnGoalCode(goalCode: string): void {
    this.alertFilters$.next({
      code: goalCode
    });
    this.scrollToAlertListingTable();
  }

  clearDateAlert(form: NgForm): void {
    this.alertDate = '';
    form.controls['authoredOn'].setValue('');
  }

  private scrollToAlertListingTable() {
    const alertListingTable = this.document?.getElementById('alertListingTable');
    if (alertListingTable) {
      alertListingTable.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  openDatePicker(): void {
    this.picker.open();
  }

  private combineBloodPressureAlerts(alertTypes: AlertType[]): AlertType[] {
    if (alertTypes.every((alertType) => alertType.goalCode !== GoalCodes.BLOOD_PRESSURE)) {
      return alertTypes;
    }
    const nonBloodPressureAlerts = alertTypes.filter((alertType) => alertType.goalCode !== GoalCodes.BLOOD_PRESSURE);
    return [
      ...nonBloodPressureAlerts,
      {
        goalTitle: GoalTitles.BLOOD_PRESSURE,
        goalCode: GoalCodes.BLOOD_PRESSURE
      }
    ];
  }
}
