import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientChartsComponent } from './components/patient-charts/patient-charts.component';
import { EmptyRouteComponent } from '../empty-route/empty-route.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SummaryComponent } from './components/summary/summary.component';

const routes: Routes = [
  { path: ":id/profile/:status", component: ProfileComponent },
  { path: ":id/summary/:status", component: SummaryComponent },
  { path: ":id/charts/:status", component: PatientChartsComponent },
  // #R2-1116 - handled route without status if the user navigate from dashboard & email alert notication due to #R2-849
  { path: ":id/profile", component: ProfileComponent },
  { path: ":id/summary", component: SummaryComponent },
  { path: ":id/charts", component: PatientChartsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientOverviewRoutingModule { }
