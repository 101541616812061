import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalcomMember, SelectCalcomTeamMemberModalData } from "src/app/models/calcom.model";

@Component({
  selector: 'patient-app-select-calcom-team-member',
  templateUrl: './select-calcom-team-member.component.html',
  styleUrls: ['./select-calcom-team-member.component.scss']
})
export class SelectCalcomTeamMemberComponent implements OnInit {
  members!: CalcomMember[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SelectCalcomTeamMemberModalData,
  ) { }

  ngOnInit(): void {
    this.loadModalData();
  }

  private loadModalData(): void {
    const { firstName, lastName, email } = this.dialogData.patient;
    this.members = this.dialogData.members
      .map((member: CalcomMember) => {
        return {
          ...member,
          embeddedView: `${member.embeddedView}?name=${firstName} ${lastName}&email=${email}`
        };
      })
      .filter((member) => !!member?.name);
  }
}
