import { ICreateSidecarUserPayload, ICreateSidecarUserResponse } from "src/app/models/create-sidecar-user.model";

/**
 * Generates the sidecar user's post payload.
 * @param fhirPatientId newly created patient's fhir id
 * @param organizationId organization id to which the patient belongs
 * @param data patient details, refer AddPatientModalFormValue
 * @returns payload for sidecar user
 */
export const MapPatientModelToSideCarUser = (fhirPatientId: string, organizationId: string, data: any): ICreateSidecarUserPayload => {
    if (!fhirPatientId) {
        throw new Error('mapPatientModelToSideCarUser: fhirPatientId is required');
    }

    if (!organizationId) {
        throw new Error('mapPatientModelToSideCarUser: organizationId is required');
    }

    if (!data.firstName) {
        throw new Error('mapPatientModelToSideCarUser: firstName is required');
    }

    if (!data.lastName) {
        throw new Error('mapPatientModelToSideCarUser: lastName is required');
    }

    if (!data.email) {
        throw new Error('mapPatientModelToSideCarUser: email is required');
    }

    if (!data.phone) {
        throw new Error('mapPatientModelToSideCarUser: phone is required');
    }

    if (!data.iHealthEmail) {
        throw new Error('mapPatientModelToSideCarUser: iHealthEmail is required');
    }

    return {
        email: data.email,
        firstName: data.firstName,
        iHealthEmail: data.iHealthEmail,
        lastName: data.lastName,
        organizations: [{
            orgId: organizationId,
            externalIdentifier: [{
                value: fhirPatientId,
                system: "Fhir"
            }]
        }],
        phone: data.phone,
    };

}

/**
 * Takes response from sidecar api and maps it to 
 * ICreateSidecarUserResponse
 * @param obj response from sidecar api
 * @return sidecarUserResponse: ICreateSidecarUserResponse
 */
export const MapSidecarUserResponse = ({ user: { active, adb2cId, cognitoId, email, firstName, id, lastName, organizations, phone } }: any): ICreateSidecarUserResponse => {
    if (!adb2cId && !cognitoId) {
        throw new Error('Recevied invalid value for adb2cId or cognitoId');
    }

    if (!email) {
        throw new Error('Received an invalid value for email');
    }

    if (!firstName) {
        throw new Error('Received invalid value for firstName');
    }

    if (!lastName) {
        throw new Error('Received invalid value for lastName');
    }

    if (!id) {
        throw new Error('Received invalid value for id');
    }

    if (!organizations) {
        throw new Error('Found no organizations');
    }

    if (!phone) {
        throw new Error('Received invalid value for phone');
    }

    return {
        active,
        adb2cId,
        cognitoId,
        email,
        firstName,
        id,
        lastName,
        organizations,
        phone,
    }
}

/**
 * Takes response from sidecar api and maps it to 
 * ICreateSidecarUserResponse
 * @param obj response from sidecar api
 * @return sidecarUserResponse: ICreateSidecarUserResponse
 */
export const MapSidecarUserQueryResponse = (obj: any): ICreateSidecarUserResponse => {
    if (!obj.adb2cId && !obj.cognitoId) {
        throw new Error('Recevied invalid value for adb2cId or cognitoId');
    }

    if (!obj.email) {
        throw new Error('Received an invalid value for email');
    }

    if (!obj.firstName) {
        throw new Error('Received invalid value for firstName');
    }

    if (!obj.lastName) {
        throw new Error('Received invalid value for lastName');
    }

    if (!obj.id) {
        throw new Error('Received invalid value for id');
    }

    if (!obj.organizations) {
        throw new Error('Found no organizations');
    }

    if (!obj.phone) {
        throw new Error('Received invalid value for phone');
    }

    return {
        active: obj.active,
        adb2cId: obj.adb2cId,
        cognitoId: obj.cognitoId,
        email: obj.email,
        firstName: obj.firstName,
        id: obj.id,
        lastName: obj.lastName,
        organizations: obj.organizations,
        phone: obj.phone
    }
}