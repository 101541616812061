import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-alerts-app-dashboard-button',
  templateUrl: './dashboard-button.component.html',
  styleUrls: ['./dashboard-button.component.scss']
})
export class DashboardButtonComponent implements OnInit {
  /** Title or Text to show in button */
  @Input() title!: string;

  /** Url for image to show as prefix (optional) */
  @Input() prefixImageUrl: string = '';

  /** mat-icon name if need to use mat-icon as prefix (optional) */
  @Input() prefixIconName: string = '';

  /** Url for image to show as suffix (optional) */
  @Input() suffixImageUrl: string = '';

  /** mat-icon name if need to use mat-icon as suffix (optional) */
  @Input() suffixIconName: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
