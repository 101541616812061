import { FhirPatientResponse } from "../shared/models/fhir/patient/response/fhir-patient-response";

interface Code {
  coding: Coding[];
}

interface Coding {
  system?: string;
  code: string;
  display?: string;
}

interface DynamicValue {
  path: string;
  expression: Expression;
}

interface Expression {
  language: string;
  expression: string;
}

export interface ActionForProgram {
  title: string;
  description: string;
  code: Code[];
  dynamicValue: DynamicValue[];
}

export enum CalcomEventTypes {
  MANAGED = 'MANAGED',
  ROUND_ROBIN = 'ROUND_ROBIN'
}

export interface CalcomMember {
  embeddedView: string;
  name: string;
  email: string;
  id: number;
  link: string;
}

export interface CalcomEvent {
  id: number;
  slug: string;
  event_type: string;
  title: string;
  link?: string;
  providerMembers?: CalcomMember[];
}

export interface SelectCalcomTeamMemberModalData {
  members: CalcomMember[];
  patient: FhirPatientResponse;
}
