import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ActivePatientsComponent } from './components/active-patients/active-patients.component';
import { InactivePatientsComponent } from './components/inactive-patients/inactive-patients.component';
import { PendingPatientsComponent } from './components/pending-patients/pending-patients.component';
import { PatientOverviewComponent } from './patient-overview/patient-overview.component';

const routes: Routes = [
  { path: '', redirectTo: 'active', pathMatch: 'full' },
  { path: 'active', component: ActivePatientsComponent },
  { path: 'pending', component: PendingPatientsComponent },
  { path: 'inactive', component: InactivePatientsComponent },
  {
    path: '',
    component: PatientOverviewComponent,
    // canActivateChild: [ActivePatientGuard],
    loadChildren: () => import('./patient-overview/patient-overview-routing.module')
      .then(module => module.PatientOverviewRoutingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/patient' },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
