<div class="patients-module-title">Patient Overview</div>
<div class="patients-patient-program-row">
    <ng-container *ngIf="patientDetails">
        <span class="patients-patient-name">{{patientDetails.firstName | titlecase}} {{patientDetails.lastName | titlecase}}</span>
        <span class="patients-program-name">|</span>
        <span class="patients-program-name">{{ patientCarePlanName }}</span>
    </ng-container>
</div>
<nav mat-tab-nav-bar class="mat-elevation-z8 patients-white-navbar patients-my-tab-nav" [ngClass]="{'tablet':isTablet}"
    [tabPanel]="tabPanel">
    <a mat-tab-link class="patients-custom-tab" 
      *ngFor="let link of navLinks; trackBy: navLinkTrackBy" 
      [ngClass]="{'tablet':isTablet}"
      [routerLink]="link.label === 'Schedule' ? null : link.location" 
      routerLinkActive="active-tab"
      #rla="routerLinkActive" [active]="rla.isActive" 
      (click)="onSwitchTab(link.label)">
        <span>{{ link.label | uppercase }}</span>
    </a>
</nav>
<button #calButton *ngIf="dataCalLink" [attr.data-cal-link]="dataCalLink" [hidden]="true"></button>
<mat-tab-nav-panel class="patients-custom-tabpanel" #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>