import { Observation, Task } from "fhir/r4";


export interface AcknowledgedTaskParams {
  taskId: string;
  patientId: string;
  carePlanId: string;
  observationId: string;
  newComment: string;
  loggedInUsername: string;
  taskDescription: string;
  oldNote?: any[];
  code: any;
  input: any[];
  // time when clinician added acknowledged an alert for the first time
  authoredOn: string;
  // isAddComment prop used to set the end date in executionPeriod
  // end date should only be edited when task resource 
  // moves from not acknowledged to acknowledged state
  isAddComment?: boolean;
  executionPeriodEndDate?: string;
}

export interface AcknowledgeModalFormValue {
  newComment: string;
}

export interface AddCommentModalFormValue extends AcknowledgeModalFormValue {};

export interface AlertType {
  goalCode: string;
  goalTitle: string;
}

export const AlertStatuses = [
  { value: 'ready', text: 'Not Acknowledged' },
  { value: 'completed', text: 'Acknowledged' },
];

export interface Note {
  text: string;
  authorString: string;
  time: string;
}

export interface AlertFilters {
  authoredOn: string;
  code: string;
  status: string;
}

export interface ObservationsTasks { 
  observations: Observation[];
  tasks: Task[];
}