<button mat-raised-button>
    <div class="prefix-image-container" *ngIf="prefixImageUrl">
        <img src="{{prefixImageUrl | assetUrl}}" class="prefix-image">
    </div>
    <div class="prefix-icon-container" *ngIf="prefixIconName">
        <mat-icon class="prefix-icon">{{prefixIconName}}</mat-icon>
    </div>
    <span class="title" *ngIf="title">{{title}}</span>
    <div class="suffix-image-container" *ngIf="suffixImageUrl">
        <img src="{{suffixImageUrl | assetUrl}}" class="suffix-image">
    </div>
    <div class="suffix-icon-container" *ngIf="suffixIconName">
        <mat-icon class="suffix-icon">{{suffixIconName}}</mat-icon>
    </div>
</button>