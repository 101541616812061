<div class="patients-title-row">
  <h2 mat-dialog-title class="patients-custom-dialog-title">Add Data Sources</h2>
  <mat-icon class="patients-pointer" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content>
  <div class="patients-spinner-grid" *ngIf="!deviceConnectInfo && !apiError">
    <mat-spinner class="patients-rpm-spinner patients-device-spinner"
      [strokeWidth]="defaultSpinnerProperties.strokeWidth" [diameter]="defaultSpinnerProperties.diameter"></mat-spinner>
  </div>
  <div class="patients-spinner-grid" *ngIf="apiError">
    <span>{{apiError}}</span>
  </div>
  <div class="patients-device-grid" *ngIf="deviceConnectInfo && !apiError">
    <div class="patients-device-box" *ngFor="let device of deviceConnectInfo;trackBy:deviceTrackBy">
      <img [src]="device.logo_url" alt="device logo" class="patients-device-logo mat-elevation-z4">
      <span class="patients-device_name">{{device.display_name}}</span>
      <div class="patients-status-container" *ngIf="device.connected">
        <mat-icon class="patients-status-icon">check</mat-icon>
        <span class="patients-device_status">Connected</span>
      </div>
      <div class="patients-status-container-connect" *ngIf="!device.connected" (click)="handleConnectButton(device)">
        <span class="patients-device_status">Connect</span>
      </div>
      <!-- <span class="patients-device_status" *ngIf="device.connected ===false">Not Connected</span> -->
    </div>
  </div>
  <mat-dialog-actions [align]="'end'">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
    <!-- <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="closeDialog()">Import Data</button> -->
    <button mat-raised-button color="primary" [disabled]="!!isImportDataButtonDisabled" (click)="closeDialog()">Onboard Patient</button>
  </mat-dialog-actions>
</mat-dialog-content>