<patient-app-patient-navigation-header></patient-app-patient-navigation-header>
<div class="patients-search-add-wrapper">
  <patient-app-search-patients (emitSearchQuery)="emitSearchQuery($event)"
    (clearSearch)="clearSearch()" [disabled]="showSpinner" ></patient-app-search-patients>

  <section class="buttons-flex">
    <button mat-raised-button class="patients-basic-button filled" (click)="addPatientButtonClick()">Add Patient</button>
    
    <!-- column filter -->
    <div class="dashboard-summary-heading-column">
      <dashboard-alerts-app-dashboard-button title="Columns" [prefixImageUrl]="'images/column.png'"
        [suffixIconName]="'expand_more'" [matMenuTriggerFor]="systemColumnFilter"></dashboard-alerts-app-dashboard-button>
    
      <mat-menu #systemColumnFilter="matMenu" [formGroup]="systemColumnsForm" xPosition="before" yPosition="below">
        <ng-container *ngFor="let system of systemColumns">
          <button mat-menu-item (click)="$event.stopPropagation()">
            <mat-checkbox class="dashboard-checkbox" formControlName="{{system.column}}">{{system.title}}</mat-checkbox>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </section>
</div>

<patient-app-custom-mat-table [tableData]="patients" [displayedColumns]="displayedColumns" [isExpandableRow]="true"
  [setActionTemplate]="actionTemplate" [isPageAble]="true" [showSpinner]="showSpinner"></patient-app-custom-mat-table>

<!-- action button template in active patient listing passed to reusable mat table component -->
<ng-template #actionTemplate let-item="item">
  <button class="patients-action-button" mat-button (click)="onEditCarePlan($event, item)">
    <mat-icon class="patients-icon-display">description</mat-icon>
  </button>
</ng-template>